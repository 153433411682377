<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Term & Conditions</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Term & Conditions</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h2 style="font-size:xx-large; font-weight: bolder; color: #333;">Welcome To oceansofttech.net (the "Site").</h2>
                            <p class="text-light-white">1. ACCEPTANCE OF TERMS OF USE</p>
                            <p class="text-light-white"> Please read the following terms and conditions as these terms of use ("Terms")
                                constitute a legally binding agreement between you and the “Partnership Firm”
                                regarding your use of the Site and/or the services provided by the “Partnership Firm”
                                which include but not limited to aggregation of important news, newsletters, analysis
                                focusing on RTDResearch industry or availability of various types of content through
                                the Site or any mobile or internet connected device or otherwise (the Services).<br>
                                                    You also represent that you are an individual and not a corporation.<br>
                                                    The “Partnership Firm” reserves the right, at its discretion, to change, modify, add, or
                                remove portions of these Terms at any time by posting the amended Terms. Please
                                check these Terms periodically for changes. Your continued use of the site or Services
                                after the posting of changes constitutes your binding acceptance of such changes. In
                                addition, when using any particular services, you may be subject to any posted
                                guidelines, rules, product requirements or sometimes additional terms applicable to
                                such services. All such guidelines, rules, product requirements or sometimes additional
                                terms are hereby incorporated by reference into the Terms.<br>
                                                    <b>YOUR ACCESS OR USE OF THE SITE OR SERVICE SHALL MEAN THAT YOU HAVE READ,
                                UNDERSTAND AND AGREE TO BE BOUND BY THE TERMS.</b><br>
                                                    By accessing or using any
                                Website or Services you also represent that you have the legal authority as per
                                applicable law (including but not limited to age requirement) to accept the Terms on
                                behalf of yourself and/or any other person you represent in connection with your use of
                                the Site or Services. If you do not agree to the Terms, you are not authorized to use the
                                Site or Services.</p>
                            <p class="text-light-white">We always hone our skills for making digital experiences and meaningful client engagements. Our vast industry and technology proficiency let us to team-up with customers for delivering refined solutions on budget. For our company Ocean Software Technologies, it’s not just about delivering sophisticated IT business solutions, but also about playing role in industry effort to shape up a smarter and brighter future.</p>
<hr>
                            <h5 class="text-custom-black fw-600">Our Vision</h5>
                            <p class="text-light-white">To be one of the most valued Software Development Company in India giving state of the art center of excellence, ROI-Oriented solutions and services with the limelight of industry experts and promising technologies.</p>
                            <!--<blockquote class="mb-xl-20">
                                <p class="text-custom-black fw-600">@Sometimes by accident, sometimes on purpose with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page maker including versions of lorem ipsum. Sometimes by accident, sometimes on purpose."</p>
                            </blockquote>-->
                            <h5 class="text-custom-black fw-600">Our Mission</h5>
                            <p class="text-light-white" style="text-align: justify;">The mission of Ocean Software Technologies - Software Design and Development Company is to create innovative products and deliver unique services which process quality and customer satisfaction to add value to your business. Our dedicated team especially focus on actual needs and requirements of the client to create strong and long-term relations. With the aim to meet the clients demands, our teamwork to bring successful growth for various businesses.<br><strong>We focus on your needs and plan and develop your digital bank with you to position you as a key player in the marketplace.
                            </strong></p>
                        </div>
                    </div>
                </article>
                <!-- article -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <app-footer></app-footer>