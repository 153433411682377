<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-server p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 class="text-custom-white lh-default fw-600">WINDOWS & LINUX VPS PRICING PLAN</h2>
                        <h4>India's Most Trusted Datacenter</h4>
                        <h4>High Performance, Low Cost Machines, Starting @ ₹999/month</h4>
                        <h6 style="color: red;">VPS of 4 GB RAM & 50GB M.2 SSD Free for 12 months* New Startups</h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">VPS server provider with 99.90% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Upgradation within minutes for all processors & servers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Fastest Connectivity</h6>
                                            <p class="text-custom-white">100 GBPS connectivity with fast and Premium bandwidth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">VPS Server</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<section class="section-padding our_articles">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">Standard Plans</h3>
                <div class="section-description">
                    <p class="text-light-white" style="text-align: justify;">Our Server Hosting located at Nagpur & Bengaluru in Indian Datacenter is built for those who require complete control, superpower, and high-end security. Virtual private servers or Virtual dedicated servers are a form of virtualization that split a single physical server into multiple virtual servers.<br><b>OceanSoftTech</b> has all high-end <b>M.2 SSD</b> VPS servers hosting that are your ready solutions to any business critical challenge. our low cost VPS servers always come with Tireless Support.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 no-padding">
                <div class="blog-slider" style="text-align: center;">
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-V2</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">999/- ₹ Per Month</b><br>
                                    <small>10,790/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>21,580/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    1 Core<br><br>
                                    <b>2 GB DDR4</b><br><br>
                                    <b>50 GB M.2 SSD / 200 GB HDD</b><br><br>
                                    1 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-V4</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">1299/- ₹ Per Month</b><br>
                                    <small>14,000/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>28,000/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    1 Core<br><br>
                                    <b>4 GB DDR4</b><br><br>
                                    <b>75 GB M.2 SSD / 300 GB HDD</b><br><br>
                                    1 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-V8</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">5000/- ₹ Per Month</b><br>
                                    <small>54,000/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>1,08,000/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    4 Core<br><br>
                                    <b>8 GB DDR4</b><br><br>
                                    <b>128 GB M.2 SSD / 400 GB HDD</b><br><br>
                                    2 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-V16</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">7999/- ₹ Per Month</b><br>
                                    <small>86,389/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>1,72,779/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    4 Core<br><br>
                                    <b>16 GB DDR4</b><br><br>
                                    <b>256 GB M.2 SSD / 512 GB HDD</b><br><br>
                                    2 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <!--<article class="post col-12">
                        <div class="single-blog">
                            <div class="post-meta">
                                <div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 13, Nov</a>
                                </div>
                            </div>
                            <div class="blog-photo">
                                <img src="assets/images/blog/blog3.jpg" class="full-width" alt="#">

                            </div>
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">How Is Mediation Useful in Home Loan Cases?</h2>
                                <p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
                                <div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Jhone Deo</a></span>
                                </div>
                            </div>
                            <div class="blog-overlay">
                                <div class="right-text-effect post-content">
                                    <h2 class="post-title"><a routerLink="/blog-detail" class="text-white">How Is Mediation Useful in Home Loan Cases?</a></h2>
                                    <p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
                                </div>
                                <div class="blog-read-more-icon">
                                    <div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Jhone Deo</a></span>
                                    </div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
                                </div>
                            </div>
                        </div>
                    </article>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->
<app-footer></app-footer>