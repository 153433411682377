<div class="animated-banner">
	<app-navbar></app-navbar>
	<!-- Start Slider -->
		<div class="slider parallax overlay-bg" id="banner-animation">
			<div class="side-lines"> <span class="box"></span>
				<span class="text">Ocean</span>
				<span class="line"></span>
			</div>
			<div class="transform-center">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="banner-slider">
								<div class="slide-item">
									<div class="banner-text">
										<h1 class="text-custom-white fw-700">I-Bank 4.0<br> Core Banking System (CBS) Software</h1>
										<p class="text-custom-white">For Multi-State Society, Credit Cooperative Society SACCOS, Employees Cooperative Society, SACCOS, Nidhi, NBFC & Finance Company.
																	<br><b>I-Bank 4.0 CBS @ 12000/- Rs Per Year Per Branch for India for 500 Members</b>
																	<br><b>I-Bank 4.0 CBS SACCOS @ 25 $ Per Month Per Branch for African countries</b></p>
										<a routerLink="/i-bank-price" class="btn-first btn-submit-fill">I-Bank 4.0 Price Plan</a>
									</div>
								</div>
								<div class="slide-item">
									<div class="banner-text">
										<h2 class="text-custom-white fw-700">Your Digital Partner</h2>
										<p class="text-custom-white"><br>Top Web Consultants in Nagpur, India <br> Get the best Web Designing At Nagpur</p> 
										<a routerLink="/website-design" class="btn-first btn-submit-fill">Explore</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End Slider -->
		<!-- Start Intro -->
		<section class="genmed-intro">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Banking & Finance</h6>
								<p class="text-custom-white">
									"I-Bank 4.0" Core Banking System for Urban Bank, Credit Cooperative Society, Multi State Society, Nidhi Company Software, NBFC, SACCOS & Micro Finance.
									</p>
									<a routerLink="/i-bank-info" class="btn-first btn-submit fw-600">Read more</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-xs-20 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Website Development</h6>
								<p class="text-custom-white">
									We have equally focused in an area of Website Design & Website Development over these years we delivering cost effective solutions to our clients globally.
								</p>
								<a routerLink="/website-design" class="btn-first btn-submit fw-600">Read more</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="intro-box bg-dark-brown wow fadeInUp" data-wow-duration="2.5s" data-wow-delay="1.3s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Ionic Mobile App</h6>
								<p class="text-custom-white">
									Ocean offers a range of Android/iOS compatible mobile applications that not only streamline lifestyle but also boost the business prolifically.
								</p>
								<a routerLink="/mobile-app" class="btn-first btn-submit fw-600">Read more</a>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.6s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Ocean Datacenter</h6>
								<p class="text-custom-white">
									Windows & Linux Unmanaged<br> DEDICATED & VPS SERVERS<br>High Performance<br>Low Cost Machines<br>Designed for your business.
								</p>
								<a routerLink="/server-vps" class="btn-first btn-submit fw-600">Read more</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Intro -->
		<!-- Start Main Services -->
		<section class="main-services section-padding p-relative">
			<div class="side-lines"> <span class="box"></span>
				<span class="text">Services</span>
				<span class="line"></span>
			</div>
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-black fw-700">Banking And Finance Application By Ocean Software Technologies</h3>
						<div class="section-description">
							<p style="text-align: justify;" class="text-light-white">Ocean "I-Bank 4.0" Core Banking System (CBS) offers tools that help gain more control over every critical area of Banking & Finance (Cooperative Society, Multistate Cooparetive Society Pvt. Ltd., Employees Cooperative Society, Nidhi Company, Urban Cooperative Banks, SACCOS, Micro Finance Company & NBFC).</p>
						</div>
					</div>
					<div class="section-btn"> <a routerLink="/i-bank-info" class="btn-first btn-submit text-light-blue">View More</a>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-xl-30">
							<div class="main-service-wrapper padding-20" style="text-align: justify;">
								<div class="icon-box"> <i class="bi bi-bank"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/i-bank-info" class="text-custom-black">Core Banking System</a></h5>
								<p class="text-light-white no-margin">"I-Bank 4.0 CBS" is fully web based core banking software in which Bank, Society, Finance Company is centrally connected with single database.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-xl-30">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"> <i class="bi bi-bank2"></i>
								</div>
								<h5 class="fw-700">
									<a routerLink="/credit-cooperative-society" class="text-custom-black">Cooperative Banking</a></h5>
								<p style="text-align: justify;" class="text-light-white no-margin">"I-Bank 4.0 CBS" complete solution for Urban Cooperative Bank all operations like Acc. Opening, Transactions & Reports are available on single click.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-xl-30">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"> <i class="bi bi-coin"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/micro-finance-nidhi" class="text-custom-black">Finance Company</a></h5>
								<p class="text-light-white no-margin" style="text-align: justify;">"I-Bank 4.0 CBS" is most popular in Small & Medium size finance companies by using "I-Bank 4.0 CBS" finance company cut there software cost by 80% by Using SaaS.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-md-30">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"> <i class="bi bi-currency-rupee"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/micro-finance-nidhi" class="text-custom-black">NBFC</a></h5>
								<p class="text-light-white no-margin" style="text-align: justify;">
									"I-Bank 4.0 CBS" is specialized in Non Banking Finance Company like Nidhi Company. I-Bank 4.0 India’s No 1 Nidhi Company Software it is modular and easy to used.
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"> <i class="bi bi-bank2"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Cooperative Society & SACCOS</a></h5>
								<p class="text-light-white no-margin" style="text-align: justify;">
									"I-Bank 4.0 CBS" Web Based Core Banking System is best software for Credit Cooperative Society to manage there accounting. Software handled all there Accounting, Transactions & Reports.
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-md-30">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"><i class="bi bi-bank"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/multistate-society" class="text-custom-black">MultiState Cooperative</a></h5>
								<p class="text-light-white no-margin" style="text-align: justify;">
								"I-Bank 4.0 CBS" Core Banking System is firsty develop by OceanSoftTech for Yalsco MultiState Cooparetive Society which having more then 1 branche. All Accounts, Transactions & Reports are available to all branches.
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-xs-30">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"><i class="bi bi-currency-rupee"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Credit Unions & SACCOS</a></h5>
								<p class="text-light-white no-margin" style="text-align: justify;">
									Credit unions are cooperative financial institutions that are controlled by their members. Financial services For SACCOS for African countries I-Bank 4.0 <b> SACCOS 50$ Per Month Per Branch.</b>
								</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="main-services-box p-relative mb-xl-30">
							<div class="main-service-wrapper padding-20">
								<div class="icon-box"><i class="bi bi-coin"></i>
								</div>
								<h5 class="fw-700"><a routerLink="/micro-finance-nidhi" class="text-custom-black">Micro Finance</a></h5>
								<p class="text-light-white no-margin" style="text-align: justify;">
									With new business designs, Micro-Finance bring impetus to the financial services and compete strongly with traditional banks. I-Bank 4.0 software to generate and manage A/C, Transaction and report for managing Finance Company.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Main Services -->
		<!-- Start Type of payment -->
		<section>
			<div class="container-fluid">
				<div class="row">
					<div class="col-12 no-padding">
						<div class="ln-type-payment-tabs">
							<ul class="custom nav nav-tabs">
								<li class="nav-item">
									<a href="#home-loan" class="nav-link active" data-toggle="tab">
										SACCOS in (Africa)
									</a>
								</li>
								<li class="nav-item">
									<a href="#Website-Development" class="nav-link" data-toggle="tab">
										Website Development
									</a>
								</li>
								<li class="nav-item">
									<a href="#Server-Hosting" class="nav-link" data-toggle="tab">
										Server & Hosting
									</a>
								</li>
								<li class="nav-item">
									<a href="#property-loan" class="nav-link" data-toggle="tab">
										.NET MAUI Mobile App
									</a>
								</li>
								<li class="nav-item">
									<a href="#personal-loan" class="nav-link" data-toggle="tab">
										Software Consultation
									</a>
								</li>
							</ul>
							<div class="tab-content section-padding ln-type-payment">
								<div class="tab-pane container fade show active" id="home-loan">
									<div class="row">
										<div class="col-12">
											<div class="tab-inner">
												<div class="tab-heading section-padding-bottom">
													<h3 class="text-custom-white">Overview - SACCOS For African countries</h3>
													<p class="text-custom-white no-margin">SACCOS solution will help small Savings & Credit Cooperative Society in Africa for there accounting activity.  Our SACCOS Solution empowers society and financial institutes to proactively manage financial risks and compliance.
														<br><b> I-Bank 4.0 CBS SACCOS @ 25 $ Per Month Per Branch For African Countries.</b><br>
														<br><b> We provide SACCOS in Nigeria, South Africa, Tanzania, Kenya, Sudan, Rwanda, Uganda, Ghana, Zimbabwe, Botswana & Mauritius</b><br><br>
														"SMEAZ Savings & Credit Cooperative Society Limited” SACCOS in Harare Zimbabwe, Africa.<br>
														"ADM Financal Freedom” SACCOS in Dar es salaam Tanzania.<br>
														Above SACCOS use our I-Bank 4.0 CBS in there SACCOS for daily financial activites.<br>
														The cost of our credit co-operative software depends on several factors such as the number of members, the features required, and the customization needed. The base cost for the software starts from INR 12,000 and can go up to INR 2,50,000 depending on the requirements.
													</p>
												</div>
												<div class="row">
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-search-dollar fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Cloud based solution</h4>
																<p class="text-custom-white">
																							 Simplified and Seamless Integration<br>
																							 Robust Data Analysis<br>
																							 Up-To-Date Customer Information</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-cogs fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">User Friendly Mobile App</h4>
																<p class="text-custom-white">	Agent Collection Mobile App<br>
																								Customer Mobile App<br>
																								Management Mobile APP</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-tasks fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Data Management</h4>
																<p class="text-custom-white">User-Friendly System that Reduces Costs<br>
																	Efficiency and Faster Processing of Cases</p>
															</div>
														</div>
													</div>
													<div class="col-12 text-center">
														<a routerLink="/Conatctocean" class="btn-first btn-submit-white fw-600 mb-xl-20">Contact Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="Website-Development">
									<div class="row">
										<div class="col-12">
											<div class="tab-inner">
												<div class="tab-heading section-padding-bottom">
													<h3 class="text-custom-white">Web Design, Development & Maintenance</h3>
													<p class="text-custom-white no-margin" style="text-align: justify;">
														Web Development has always been our key strength. We have equally focused in the areas of Website Design, Website Development, Web Promotion, and Software Development over these years delivering professional and cost effective solutions to our clients globally. Ocean Software Technologies is a web design company based in India providing website design services and custom website designing.
													</p>
												</div>
												<div class="row">
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fab fa-angular fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Angular 11</h4>
																<p class="text-custom-white">
																	Angular 11 with Typescript 4.0<br> HTML5 & CCS 3
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fab fa-node fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">API & Webservices</h4>
																<p class="text-custom-white">
																	Node.js<br> .Net Core
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-database fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Backend / Database</h4>
																<p class="text-custom-white">
																	Sql Server, Oracle, Altibase & MySql<br>
																	Firebase, MongoDB & Cassandra

																</p>
															</div>
														</div>
													</div>
													<div class="col-12 text-center">
														<a routerLink="/website-design" class="btn-first btn-submit-white fw-600 mb-xl-20">Contact Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="Server-Hosting">
									<div class="row">
										<div class="col-12">
											<div class="tab-inner">
												<div class="tab-heading section-padding-bottom">
													<h3 class="text-custom-white">Affordable VPS & Dedicated Servers</h3>
													<p class="text-custom-white no-margin">
														Ocean is Fastest Growing Premium Data Center in India providing high performance SSD Dedicated Servers, DRaaS & Colocation services with 24/7 support.
													</p>
												</div>
												<div class="row">
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-hdd fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Next-Gen VPS Hosting</h4>
																<p class="text-custom-white" style="text-align: justify;">
																	VPS is the new way to ensure that your website runs at very high performance, including speed, privacy, hosting control, management, the ability to upgrade at will, and several other amazing benefits for your domain.
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-server fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">SSD Dedicated Hosting</h4>
																<p class="text-custom-white" style="text-align: justify;">
																	Ocean has high-end Dedicated servers hosting that are your ready solutions to any business critical challenge. Ocean Data Center has the expertise and verbatim-delivered SLAs that make our solutions rock-solid for your business performance and peace of mind.
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-digital-tachograph fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Data Center</h4>
																<p class="text-custom-white" style="text-align: justify;">Ocean data centers are built with best infrastructure, best server hardware and redudant network and power availability delivering you better performance, scalability and reliability.</p>
															</div>
														</div>
													</div>
													<div class="col-12 text-center">
														<a routerLink="/server-vps" class="btn-first btn-submit-white fw-600 mb-xl-20">Contact Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="property-loan">
									<div class="row">
										<div class="col-12">
											<div class="tab-inner">
												<div class="tab-heading section-padding-bottom">
													<h3 class="text-custom-white">Developing Andriod and IOS based Mobile Apps In .NET MAUI</h3>
													<p class="text-custom-white no-margin">
														Crafting Gorgeous Mobile App to Help Businesses Establish Promising Identity in The Digital World
													</p>
												</div>
												<div class="row">
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fab fa-apple fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">App For I-Bank 4.0</h4>
																<p class="text-custom-white" style="text-align: justify;">App develop for Agents & Customers of <br>I-Bank 4.0 for financial activity. For Agents (Daily Collection App) For Customers (Acc. Statement, Acc. Opening, New Loan Enquiry)</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fab fa-android fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Mobile Apps Development</h4>
																<p class="text-custom-white">
																	Business Mobile Apps Development<br>
																	Education Mobile Apps Development<br>
																	Travel Mobile Apps Development<br>
																	Finance Mobile Apps Development<br>
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-mobile-alt fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Clients</h4>
																<p class="text-custom-white">
																	Maharashtra Bamboo Development Board<br>
																	Halba Shaadi Nagpur<br>
																	Forest Foot-Patrol Tracking App<br>
																</p>
															</div>
														</div>
													</div>
													<div class="col-12 text-center">
														<a routerLink="/Conatctocean" class="btn-first btn-submit-white fw-600 mb-xl-20">Contact Now</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="personal-loan">
									<div class="row">
										<div class="col-12">
											<div class="tab-inner">
												<div class="tab-heading section-padding-bottom">
													<h3 class="text-custom-white">Software Consultation Services</h3>
													<p class="text-custom-white no-margin">
														Ocean Software Technologies provide software consultation services for New Startups, Software Migration Services.
													</p>
												</div>
												<div class="row">
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-desktop fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Digital Transformation Offline Software To Online</h4>
																<p class="text-custom-white" style="text-align: justify;">
																	We’ve been successfully helping our clients digitally transform their businesses for over a decade. Making it easier for their customers to buy and use services, updating and simplifying internal software for business use and implementing new technologies for cost savings. Many Cooperative Urban Bank, Society, Multi-State Society, Employee Society user old offline software Ocean Soft Tech help them in software upgrading.
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="fas fa-laptop-medical fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">Convert Offline Software Company To Online</h4>
																<p class="text-custom-white" style="text-align: justify;">
																	We’re in the business of transformation. Working alongside you to create, plan and deliver your transformation roadmap. Helping you adopt waterfall & Agile ways of working in order to deliver change faster to your customers. We have a long history of consulting with some of the many financial institutions like <b>TechnoSys, Global Infoway, Gargi Compucare, Innovation Solution</b> in India.
																</p>
															</div>
														</div>
													</div>
													<div class="col-lg-4 col-md-6">
														<div class="ln-type-payment-box mb-xl-30">
															<div class="payment-icon mb-xl-20">
																<i class="far fa-handshake fa-5x" style="color: white;"></i>
															</div>
															<div class="content-wrap">
																<h4 class="text-custom-white fw-600">IT Consulting Since 2014 Project Management</h4>
																<p class="text-custom-white" style="text-align: justify;">
																	We ensure project management is done right from the start, from budgeting, resource planning, and scheduling work packages to ensure success while managing risk and ambiguity. We work alongside your teams and management to provide expert project leadership with defined milestones in order to track progress.
																</p>
															</div>
														</div>
													</div>
													<div class="col-12 text-center">
														<a routerLink="/Conatctocean" class="btn-first btn-submit-white fw-600 mb-xl-20">Contact Now</a>
														<a routerLink="/Conatctocean" class="d-block text-white fw-600">*See eligibility and limitations.</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Type of payment -->
		<!-- Start About -->
		<!-- start aboutus-2 -->
		<section class="section-padding lw-about-section p-relative">
			<div class="side-lines right-side"> <span class="box"></span>
				<span class="text">I-Bank</span>
				<span class="line"></span>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<div class="ln-about-left-side p-relative full-height">
							<div class="first-img full-height">
								<img src="assets/images/homepage/I-Logo.jpg" class="image-fit" alt="img">
							</div>
							<div class="second-img">
								<img src="assets/images/homepage/about1.jpg" class="image-fit" alt="img">
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<div class="lw-about-section-right">
							<h3 class="p-relative lw-about-right-heading">Features of I-Bank 4.0 Core Banking System</h3>
							<div class="lw-about-right-content">
								<p  style="text-align: justify;">Ocean "I-Bank 4.0" CBS most sutable application for 
									<span class="text-gold">
                   						<strong>Urban Banks & all major type of Financial Institution</strong>
                 					</span>
									 which help our customers stay on-the-go by delivering quick financial service. 
									 Ocean<span class="text-gold"><strong> I-Bank 4.0</strong> </span>
									 offers tools that help gain more control over every critical area of Banking & Finance (Cooperative Society, Multi-State Society, Employees Cooperative Society, Nidhi Company, Cooperative Banks, Micro Finance Company).
	                 			</p>
								<div class="lw-about-right-list">
									<ul>
										<li> <i class="fas fa-check"></i>
											Platform Independent</li>
										<li> <i class="fas fa-check"></i>
											Operating Cost reduced by 30%</li>
										<li> <i class="fas fa-check"></i>
											Online banking (RTGS/NEFT)</li>
										<li> <i class="fas fa-check"></i>
											Cloud Banking System</li>
										<li> <i class="fas fa-check"></i>
											Data first approach</li>
										<li> <i class="fas fa-check"></i>
											N-tier architecture</li>
										<li> <i class="fas fa-check"></i>
											.Net framework 4.5</li>
										<li> <i class="fas fa-check"></i>
											Centralized Database</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End aboutus-2 -->
		<!-- start Loan Calculator -->
		<!--<section class="section-padding pb-0 exp-cal2">
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-white fw-700">Loan Calculator</h3>
						<div class="section-description">
							<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="offset-lg-2 col-lg-8 ">
						<div class="exp-cal2-loan">
							<div class="row">
								<div class="col-md-5">
									<p class="fw-600 text-center">Enter your loan amount</p>
									<div class="exp-cal2-input">
										<input placeholder="$0" maxlength="10" name="calculator" type="text" value="100" id="num1">
										<input placeholder="$0" maxlength="10" class="d-none" name="calculator" value="2" type="text" id="num2">
									</div>
									<p class="fw-600 mt-2 fs-13">please enter amount $100 to $1000</p>
								</div>
								<div class="col-md-2 align-self-center">
									<div class=exp-cal-icon>
										<button type="button" id="calculate"><i class="fa fa-angle-right"></i>
										</button>
									</div>
								</div>
								<div class="col-md-5">
									<p class="fw-600 text-center exp-cal2-content">You could be saving</p>
									<div class="ex-calculate-value">
										<input type="text" name="sum" id="sum" value="$50" readonly> <a routerLink="/">How do we calculate this?</a>
									</div>
								</div>
								<div class="col-lg-12 text-center"> <a routerLink="/loan-steps" class="btn-first btn-submit-fill ">Find my Rates</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>-->
		<!-- End Loan Calculator -->
		<!-- start lw-tab -->
		<div class="section-padding lw-tab-section p-relative">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="lw-mega-tab">
							<ul class="nav lw-nav-tabs">
								<li class="nav-item"> <a href="#I-Bank-P" class="nav-link active" data-toggle="tab">
                       I-Bank 4.0 CBS
                    </a>
								</li>
								<li class="nav-item"> <a href="#Website" class="nav-link" data-toggle="tab">
					Website Development
                    </a>
								</li>
								<li class="nav-item"> <a href="#VPS-S" class="nav-link" data-toggle="tab">
                    VPS Hosting
                    </a>
								</li>
								<li class="nav-item"> <a href="#Dedicated-S" class="nav-link" data-toggle="tab">
					Dedicated Server
                    </a>
								</li>
							</ul>
							<div class="tab-content lw-tab-content-wrapper">
								<div class="tab-pane container active" id="I-Bank-P">
									<div class="table-responsive">
										<table class="lw-tab-table">
											<tr class="lw-table-row-1">
												<th>SrNo.</th>
												<th>Plans</th>
												<th>Price For India</th>
												<th>Database Size</th>
												<th>Bandwidth <br>GB Per Month</th>
												<th>Get it</th>
											</tr>
											<tr>
												<td class="lw-table-data-1">1</td>
												<td class="lw-table-data-2">
												<b>I-Bank 4.0 Plan - 1</b>	
												</td>
												<td class="lw-table-data-3">
												<ul>
													<li>
														<strong>15,000/- Rs. Per Br. / Year</strong><br>
														25 $ Per Br. / Month For Africa
													</li>
												</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>1 GB</strong>
														</li>
														<li> 
															<span>
																(DB + Documents)
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">10 GB</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="i-bank-price" class="btn-first btn-submit-fill">Choose</a>
												</td>
											</tr>
										
											<tr>
												<td class="lw-table-data-1">2</td>
												<td class="lw-table-data-2">
												<b>I-Bank 4.0 Plan - 2</b>	
												</td>
												<td class="lw-table-data-3">
												<ul>
													<li>
														<strong>22,000/- Rs. Per Br. / Year</strong><br>
														50 $ Per Br. / Month For Africa
													</li>
												</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>2 GB </strong>
														</li>
														<li> 
															<span>
																(DB + Documents)
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">20 GB</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="i-bank-price" class="btn-first btn-submit-fill">Choose</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-1">3</td>
												<td class="lw-table-data-2">
												<b>I-Bank 4.0 Plan - 3</b>
												</td>
												<td class="lw-table-data-3">
												<ul>
													<li>
														
														<strong>24,000/- Rs. Per Br. / Year</strong><br>
														60 $ Per Br./ Month For Africa
													</li>
												</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>3 GB </strong>
														</li>
														<li> 
															<span>
																(DB + Documents)
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">40 GB</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> 
													<a routerLink="i-bank-price" class="btn-first btn-submit-fill">Choose</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-1">4</td>
												<td class="lw-table-data-2">
												<b>I-Bank 4.0 Plan - 4</b>
												</td>
												<td class="lw-table-data-3">
												<ul>
													<li>
														<strong>28,000/- Rs. Per Br. / Year</strong><br>
														75 $ Per Br. / Month For Africa
													</li>
												</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>4 GB</strong>
														</li>
														<li> 
															<span>
																(DB + Documents)
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">Unlimited</span>
												</td>
												<td class="lw-table-data-3 choose-btn">
													<a routerLink="i-bank-price" class="btn-first btn-submit-fill">Choose</a>
												</td>
											</tr>
										<!--	<tr>
												<td class="lw-table-data-1">4</td>
												<td class="lw-table-data-2">
													<img src="assets/images/homepage/logo4.png" alt="img">
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>$1900 Bonus</strong>
														</li>
														<li> <span>
                                100% up to $100
                              </span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> <span>
                                <i class="fas fa-check"></i>
                              </span>
															$100 free Plan</li>
														<li> <span>
                                <i class="fas fa-check"></i>
                              </span>
															$220 Monthly Plan</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>10 Free Plans</strong>
														</li>
														<li> <span>
                                with no Desopits
                              </span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> <span class="rating-number">5/5</span>
													<div class="ratings"> <span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
													</div>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/" class="Loan-content">T&C Apply</a>
												</td>
											</tr> -->
										</table>
									</div>
								</div>
								<div class="tab-pane fade container" id="Website">
									<div class="table-responsive">
										<table class="lw-tab-table">
											<tr class="lw-table-row-1">
												<th>SrNo</th>
												<th>Category</th>
												<th>Features</th>
												<th>Technologies</th>
												<th>Price</th>
												<th>Get it</th>
											</tr>
											<tr>
												<td class="lw-table-data-1">1</td>
												<td class="lw-table-data-2">
												<b>Starter</b>
												</td>
												<td class="lw-table-data-3">
													<span>
														Single Page, Mobile Responsive Layout<br>
														Contact Form, Google Map, SEO Friendly<br>
														Social Media Integration.
													</span>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> 
															Angular 10<br> Node.js <br> Sql-Server 2019 R2
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">
														3999 /- Rs.
													</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/website-design" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/website-design" class="Loan-content">Read More</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-1">2</td>
												<td class="lw-table-data-2">
													<b>Corporate</b>
												</td>
												<td class="lw-table-data-3">
													<span>
														5 Pages, Mobile Responsive Layout<br>
														Image Slider, Image Gallery<br>
														Contact Form, Newsletter Subscription<br>
														Google map, SEO Friendly<br>
														Social Media Integration.
													</span>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> 
															Angular 10<br> Node.js <br> Sql-Server 2019 R2
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">
														5999 /- Rs.
													</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/website-design" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/website-design" class="Loan-content">Read More</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-1">3</td>
												<td class="lw-table-data-2">
													<b>Premium</b>
												</td>
												<td class="lw-table-data-3">
													<span>
														8 Pages, Mobile Responsive Layout<br>
														Image Slider, Image Gallery, Video Gallery<br>
														Testimonials, Blog, Contact Form, Live Chat<br>
														Newsletter Subscription, Google map<br>
														SEO Friendly, Social Media Integration<br>
														Google Analytics, SSL For 1 Year Free<br>
														Free Logo, Free Basic SEO Submission<br>
														Editable Admin Section for 5 Daynamic Pages<br>
														1 Year Free Annual Maintenance Charges (AMC) 
													</span>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> 
															Angular 10<br> Node.js <br> Sql-Server 2019 R2
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">
														8999 /- Rs.
													</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/website-design" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/website-design" class="Loan-content">Read More</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-1">4</td>
												<td class="lw-table-data-2">
													<b>Custom Web Application</b>
												</td>
												<td class="lw-table-data-3">
													<span>
														Matrimonial Website Application<br>
														Government & Private Org Web Application<br>
														Offline & Online Event Web Application<br>
														Online Booking Web Application<br>
														Media, Press News & TV News Channel Application<br>
														Website Application With Payments Gateway<br>
													</span>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> 
															Angular 10<br> Node.js <br> Sql-Server 2019 R2
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> 
													<span class="rating-number">
														Mob. 9423685852
													</span>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/website-design" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/website-design" class="Loan-content">Read More</a>
												</td>
											</tr>
										</table>
									</div>
								</div>
								<div class="tab-pane fade container" id="VPS-S">
									<div class="table-responsive">
										<table class="lw-tab-table">
											<tr class="lw-table-row-1">
												<th>PROCESSOR</th>
												<th>RAM</th>
												<th>STORAGE</th>
												<th>B/W<br>IP</th>
												<th>OS</th>
												<th>PRICE/MO.</th>
												<th>ORDER</th>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba; text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 2 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 20 GB M.2 SSD <br> 40 GB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 1 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>666/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>6,393/- ₹ Yearly Plan<br>(flat 20% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/server-vps" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-vps" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba; text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 2 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 40 GB M.2 SSD <br> 200 GB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 1 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
															<strong>999/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>9,595/- ₹ Yearly Plan<br>(flat 20% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/server-vps" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-vps" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba;text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 4 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 75 GB M.2 SSD <br> 300 GB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 2 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7<br>Windows 2012 R2*
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>1999/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>18,232/- ₹ Yearly Plan<br>(flat 24% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/server-vps" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-vps" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba; text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 8 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 128 GB M.2 SSD <br> 400 GB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 2 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7<br>Windows 2012 R2*
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>5999/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>57,575/- ₹ Yearly Plan<br>(flat 20% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn">
													<a routerLink="/server-vps" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-vps" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba; text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 14 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 265 GB M.2 SSD <br> 512 GB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 3 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7<br>Windows 2012 R2*
												</td>
												<td class="lw-table-data-4"> 
													<ul style="font: bold;">
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>7999/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>76,767/- ₹ Yearly Plan<br>(flat 20% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn">
													<a routerLink="/server-vps" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-vps" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<!--
											<tr>
												<td class="lw-table-data-1">8</td>
												<td class="lw-table-data-2">
													<img src="assets/images/homepage/logo1.png" alt="img">
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>$100 Bonus</strong>
														</li>
														<li> <span>
																100% up to $100
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
															$100 free Plan</li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
															$220 Monthly Plan</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>10 Free Plans</strong>
														</li>
														<li> <span>
																with no Desopits
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> <span class="rating-number">5/5</span>
													<div class="ratings"> <span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
													</div>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											-->
										</table>
									</div>
								</div>
								<div class="tab-pane fade container" id="Dedicated-S">
									<div class="table-responsive">
										<table class="lw-tab-table">
											<tr class="lw-table-row-1">
												<th>PROCESSOR</th>
												<th>RAM</th>
												<th>STORAGE</th>
												<th>B/W<br>IP</th>
												<th>OS</th>
												<th>PRICE/MO.</th>
												<th>ORDER</th>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba; text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 8 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 480 GB M.2 SSD <br> 1 TB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 1 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7<br>Windows 2012 R2*
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>7999/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>86389/- ₹ Yearly Plan<br>(flat 10% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn">
													<a routerLink="/server-dedicated" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-dedicated" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba; text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 16 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 480 GB M.2 SSD <br> 1 TB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 1 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7<br>Windows 2012 R2*
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
															<strong>12222/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>131999/- ₹ Yearly Plan<br>(flat 10% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn">
													<a routerLink="/server-dedicated" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-dedicated" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<tr>
												<td class="lw-table-data-4" style="color: #0070ba;text-align: center;">
													Intel® CORE™ i5-9400 <br>CPU @ 2.90GHz
												</td>
												<td class="lw-table-data-2">
													 32 GB DDR4
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 
															 480 GB M.2 SSD <br> 1 TB HDD
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> 2 TB
														</li>
														<li> <span>1 IP</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4" style="text-align: center;">
													CentOS 7<br>Windows 2012 R2*
												</td>
												<td class="lw-table-data-4"> 
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>16666/- ₹ Monthly Plan</strong></li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
														<strong>179993/- ₹ Yearly Plan<br>(flat 10% off)</strong></li>
													</ul>
												</td>
												<td class="lw-table-data-3 choose-btn">
													<a routerLink="/server-dedicated" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/server-dedicated" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											<!--
											<tr>
												<td class="lw-table-data-1">8</td>
												<td class="lw-table-data-2">
													<img src="assets/images/homepage/logo1.png" alt="img">
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>$100 Bonus</strong>
														</li>
														<li> <span>
																100% up to $100
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-4">
													<ul>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
															$100 free Plan</li>
														<li> <span>
																<i class="fas fa-check"></i>
															</span>
															$220 Monthly Plan</li>
													</ul>
												</td>
												<td class="lw-table-data-3">
													<ul>
														<li> <strong>10 Free Plans</strong>
														</li>
														<li> <span>
																with no Desopits
															</span>
														</li>
													</ul>
												</td>
												<td class="lw-table-data-3 company-rating"> <span class="rating-number">5/5</span>
													<div class="ratings"> <span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
														<span class="text-custom-yellow"><i class="fas fa-star"></i></span>
													</div>
												</td>
												<td class="lw-table-data-3 choose-btn"> <a routerLink="/" class="btn-first btn-submit-fill">Choose</a>
													<a routerLink="/" class="Loan-content">T&C Apply</a>
												</td>
											</tr>
											-->
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- End lw-tab -->
		<!-- Start section process-->
		<section class="section-padding exp-cal parallax ln-process">
			<div class="overlay overlay-bg-black"></div>
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-white fw-700">Why Ocean Software Technologies</h3>
						<div class="section-description">
							<p class="text-custom-white" style="text-align: justify;">
								Ocean SoftTech is working since 2009, in any area like Banking, Finance, Web Development, App Development & Windows Software Development.
							</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-3 col-sm-6">
						<div class="exp-cal-img mb-md-30">
							<h1 class="text-custom-white fw-600">250+</h1>
							<h4 class="text-custom-white">Clients</h4>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="exp-cal-img mb-md-30">
							<h1 class="text-custom-white fw-600">100+</h1>
							<h4 class="text-custom-white">I-Bank 4.0 CBS Clients</h4>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="exp-cal-img mb-xs-30">
							<h1 class="text-custom-white fw-600">2009</h1>
							<h4 class="text-custom-white">Since Working</h4>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="exp-cal-img mb-xs-30">
							<h1 class="text-custom-white fw-600">15+</h1>
							<h4 class="text-custom-white">Professionals</h4>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End section process-->
		<!-- Start why choose us -->
		<!-- <section class="why-choose-us-style-2 section-padding">
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-black fw-700">Practice Area</h3>
						<div class="section-description">
							<p class="text-custom-black">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div class="square-tabs">
							<ul class="nav nav-tabs custom mb-xl-20">
								<li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#teeth-whitening">Home Loan</a>
								</li>
								<li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#cosmetic-dentistry">Car Loan</a>
								</li>
								<li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#pediatric-dentistry">Real Estate Loan</a>
								</li>
								<li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#preventive-dentistry">Education Loan</a>
								</li>
								<li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#dental-crowns">Business Loan</a>
								</li>
							</ul>
							<div class="tab-content padding-20 bx-wrapper bg-custom-white">
								<div class="tab-pane container active" id="teeth-whitening">
									<div class="tab-inner">
										<div class="row">
											<div class="col-lg-6">
												<div class="img-sec mb-md-40">
													<img src="assets/images/whyus1.jpg" class="full-width" alt="img">
												</div>
											</div>
											<div class="col-lg-6 align-self-center">
												<div class="content-box">
													<h4 class="text-custom-black">What does Home Loan Advisor do?</h4>
													<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
													<div class="list">
														<div class="row no-gutters">
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Well Documented</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic1.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Top Agent </h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic2.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Best Effort</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic3.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Quick Charges</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic4.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="cosmetic-dentistry">
									<div class="tab-inner">
										<div class="row">
											<div class="col-lg-6">
												<div class="img-sec mb-md-40">
													<img src="assets/images/whyus2.jpg" class="full-width" alt="img">
												</div>
											</div>
											<div class="col-lg-6 align-self-center">
												<div class="content-box">
													<h4 class="text-custom-black">What does Car Loan Advisordo?</h4>
													<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
													<div class="list">
														<div class="row no-gutters">
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Well Documented</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic1.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Top Agent </h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic2.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Best Effort</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic3.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Quick Charges</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic4.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="pediatric-dentistry">
									<div class="tab-inner">
										<div class="row">
											<div class="col-lg-6">
												<div class="img-sec mb-md-40">
													<img src="assets/images/whyus3.jpg" class="full-width" alt="img">
												</div>
											</div>
											<div class="col-lg-6 align-self-center">
												<div class="content-box">
													<h4 class="text-custom-black">What does Real State Advisor do?</h4>
													<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
													<div class="list">
														<div class="row no-gutters">
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Well Documented</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic1.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Top Agent </h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic2.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Best Effort</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic3.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Quick Charges</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic4.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="preventive-dentistry">
									<div class="tab-inner">
										<div class="row">
											<div class="col-lg-6">
												<div class="img-sec mb-md-40">
													<img src="assets/images/whyus4.jpg" class="full-width" alt="img">
												</div>
											</div>
											<div class="col-lg-6 align-self-center">
												<div class="content-box">
													<h4 class="text-custom-black">What does Business Loan Advisor do?</h4>
													<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
													<div class="list">
														<div class="row no-gutters">
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Well Documented</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic1.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Top Agent </h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic2.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Best Effort</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic3.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Quick Charges</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic4.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="tab-pane container fade" id="dental-crowns">
									<div class="tab-inner">
										<div class="row">
											<div class="col-lg-6">
												<div class="img-sec mb-md-40">
													<img src="assets/images/whyus2.jpg" class="full-width" alt="img">
												</div>
											</div>
											<div class="col-lg-6 align-self-center">
												<div class="content-box">
													<h4 class="text-custom-black">What Business Loan Advisor do?</h4>
													<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
													<div class="list">
														<div class="row no-gutters">
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Well Documented</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic1.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Top Agent </h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic2.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Best Effort</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic3.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="grid-box">
																	<div class="grid-box-inner">
																		<a routerLink="/" class="d-block clearfix">
																			<h5 class="text-custom-black">Quick Charges</h5>
																			<div class="icon-box mb-xl-20"> <img src="assets/images/homepage/ic4.png" alt="img"></div>
																			<p class="text-light-white no-margin">Lorem Ipsum is simply</p>
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>-->
		<!-- End why choose us -->
		<!-- Start Team Doctors -->
		<section class="section-padding bg-gradient doctors-team-style-2">
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-white fw-700">Our Teams</h3>
						<!-- <div class="section-description">
							<p class="text-custom-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
						</div> -->
					</div>
				</div>
				<div class="row">
					<div class="col-12 no-padding">
						<div class="doctors-slider">
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/person.png" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a href="https://www.youtube.com/channel/UCd-sM2ur-X9Nvjs6TO7QWnA" target="_blank" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a href="https://www.linkedin.com/in/yogesh-kedar-65210734/" target="_blank" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a href="https://www.facebook.com/yogeshkedar/" target="_blank" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a href="https://twitter.com/yogeshkedar"target="_blank" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="/about-us" fragment="Yogesh" class="text-custom-black fw-600 fs-20">Yogesh A. Kedar</a></h4>
											<p class="designation text-light-white">CEO & Founder<br>9423685852</p>
										</div>
									</div>
								</div>
							</div>
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/person.png" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a routerLink="/" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a routerLink="/" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a href="https://www.facebook.com/b.sachin162" target="_blank" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a routerLink="https://www.facebook.com/b.sachin162" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="" class="text-custom-black fw-600 fs-20">Sachin S. Bawane</a></h4>
											<p class="designation text-light-white">Director<br>+91 9766916371</p>
										</div>
									</div>
								</div>
							</div>
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/person.png" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a routerLink="/" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a routerLink="/" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a routerLink="https://www.facebook.com/sagar.wakharia" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a routerLink="/" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="" class="text-custom-black fw-600 fs-20">Sagar Wakharia</a></h4>
											<p class="designation text-light-white">Technical Advisor<br>0712-2715852</p>
										</div>
									</div>
								</div>
							</div>
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/person.png" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a routerLink="/" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a routerLink="/" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a routerLink="/" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a routerLink="/" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="" class="text-custom-black fw-600 fs-20">Sarita N. Warudkar</a></h4>
											<p class="designation text-light-white">Business Analyst<br>0712-2715852</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Team Doctors -->
		<!-- Start Blog -->
		<!-- <section class="section-padding our_articles">
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-black fw-700">Our Blog</h3>
						<div class="section-description">
							<p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 no-padding">
						<div class="blog-slider">
							<article class="post col-12">
								<div class="single-blog">
									<div class="post-meta">
										<div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 4, Nov</a>
										</div>
									</div>
									<div class="blog-photo">
										<img src="assets/images/blog/blog1.jpg" class="full-width" alt="#">
									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">Bigger home still the goal?</h2>
										<p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
										<div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Michael Dav</a></span>
										</div>
									</div>
									<div class="blog-overlay">
										<div class="right-text-effect post-content">
											<h2 class="post-title"><a routerLink="/blog-detail" class="text-white">Bigger home still the goal?</a></h2>
											<p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
										</div>
										<div class="blog-read-more-icon">
											<div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Michael Dav</a></span>
											</div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
							<article class="post col-12">
								<div class="single-blog">
									<div class="post-meta">
										<div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 29, June</a>
										</div>
									</div>
									<div class="blog-photo">
										<img src="assets/images/blog/blog2.jpg" class="full-width" alt="#">

									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">Choosing right education loan</h2>
										<p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
										<div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Jhone Deo</a></span>
										</div>
									</div>
									<div class="blog-overlay">
										<div class="right-text-effect post-content">
											<h2 class="post-title"><a routerLink="/blog-detail" class="text-white">Choosing right education loan</a></h2>
											<p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
										</div>
										<div class="blog-read-more-icon">
											<div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Jhone Deo</a></span>
											</div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
							<article class="post col-12">
								<div class="single-blog">
									<div class="post-meta">
										<div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 30, May</a>
										</div>
									</div>
									<div class="blog-photo">
										<img src="assets/images/blog/blog3.jpg" class="full-width" alt="#">
									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">FAST APPROVAL  May For You If You Are Innocent</h2>
										<p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
										<div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Linda Choi</a></span>
										</div>
									</div>
									<div class="blog-overlay">
										<div class="right-text-effect post-content">
											<h2 class="post-title"><a routerLink="/blog-detail" class="text-white">FAST APPROVAL  May For You If You Are Innocent</a></h2>
											<p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
										</div>
										<div class="blog-read-more-icon">
											<div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Linda Choi</a></span>
											</div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
							<article class="post col-12">
								<div class="single-blog">
									<div class="post-meta">
										<div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 13, Nov</a>
										</div>
									</div>
									<div class="blog-photo">
										<img src="assets/images/blog/blog3.jpg" class="full-width" alt="#">

									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">How Is Mediation Useful in Home Loan Cases?</h2>
										<p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
										<div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Jhone Deo</a></span>
										</div>
									</div>
									<div class="blog-overlay">
										<div class="right-text-effect post-content">
											<h2 class="post-title"><a routerLink="/blog-detail" class="text-white">How Is Mediation Useful in Home Loan Cases?</a></h2>
											<p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
										</div>
										<div class="blog-read-more-icon">
											<div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Jhone Deo</a></span>
											</div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
		</section> -->
		<!-- End Blog -->
		<!-- Start Apply Today/faqs -->
		<section class="book-appointment parallax section-padding" id="book-appointment">
			<div class="overlay overlay-bg-black"></div>
			<div class="pattern"></div>
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="appointment-sec">
							<div class="row no-gutters">
								<div class="col-lg-6 ">
									<div class="padding-40 full-height bg-white">
										<div class="align-self-center">
											<div class="section-heading">
												<h3 class="text-custom-blue fw-600 ">Get Adviser</h3>
											</div>
											<p class="text-dark">Call us today, leave a message, email or find your nearest office below and We are here for you 24 hours , 7 days a week.</p>
											<h6 class="fs-20 mt-4 text-dark">We are here for you 24 hours a day, 7 days a week</h6>
											<div class="LOANLY-address">
												<p class="text-dark "><span class="fw-600">Address</span> : Sh. No. A-0002, Shivaji Park, Shrikrishna Nagar, Nagpur</p>
												<p class="text-dark "><span class="fw-600">Address</span> : -</p>
												<p class="text-dark "><span class="fw-600">Email</span> : help@oceansofttech.net / yogeshk_123@yahoo.co.in</p>
											</div>
											<button type="submit" class="btn-first btn-submit-fill"><i class="fas fa-phone mr-2 fs-16"></i><span class="fs-16">0712-2715852</span>
											</button>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="right-side full-height bg-border">
										<div class="section-heading">
											<h3 class="text-white fw-600">Contact Us</h3>
										</div>
										<form [formGroup]="form" id="contactForm" class="form-style-2 form-style-3">
											<div class="row">
												<div class="col-md-12">
													<div class="form-group">
														<div class="input-group"> <span class="input-group-preappend"><i class="fas fa-user"></i></span>
															<input type="text" name="name" id="name" class="form-control" placeholder="Your Name"formControlName="Name">
														</div>
													</div>
												</div>
												<div class="col-md-12">
													<div class="form-group">
														<div class="input-group"> <span class="input-group-preappend"><i class="fas fa-envelope"></i></span>
															<input type="email" name="email" id="email" class="form-control" placeholder="Your Email" formControlName="Email">
														</div>
													</div>
												</div>
												<div class="col-md-12">
													<div class="form-group">
														<div class="input-group"> <span class="input-group-preappend"><i class="fas fa-phone-alt"></i></span>
															<input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone" formControlName="phone_number">
														</div>
													</div>
												</div>
												<div class="col-md-12">
													<div class="form-group">
														<div class="input-group"> <span class="input-group-preappend"><i class="fas fa-phone-alt"></i></span>
															<input type="text" name="msg_subject" id="msg_subject" class="form-control"placeholder="Your Subject" formControlName="Subject">
														</div>
													</div>
												</div>
												<div class="col-md-12">
													<button type="submit" (click)="Email_send()" class="btn-first btn-submit full-width">Book I-Bank</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Apply Today/faqs -->
		<!-- Start testimonials-->
		<!--<section class="section-padding findrate-bottom">-->
		<!--
			<section class="section-padding">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="testimonials-box mb-md-80">
							<div class="section-header">
								<div class="section-heading">
									<h3 class="text-custom-black fw-700">Testimonials</h3>
								</div>
								<div class="section-description">
									<p class="text-light-white">
										Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
									</p>
								</div>
							</div>
							<div class="testimonials-wrapper">
								<div class="col-12">
									<div class="testimonial-nav row mb-xl-40">
										<div class="slide-item col-12">
											<div class="testimonials-author">
												<a routerLink="/">
													<img src="assets/images/doctors/doctor2.jpg" class="image-fit" alt="img">
												</a>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-author">
												<a routerLink="/">
													<img src="assets/images/doctors/doctor3.jpg" class="image-fit" alt="img">
												</a>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-author">
												<a routerLink="/">
													<img src="assets/images/doctors/doctor4.jpg" class="image-fit" alt="img">
												</a>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-author">
												<a routerLink="/">
													<img src="assets/images/doctors/doctor5.jpg" class="image-fit" alt="img">
												</a>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-author">
												<a routerLink="/">
													<img src="assets/images/doctors/doctor6.jpg" class="image-fit" alt="img">
												</a>
											</div>
										</div>
									</div>
									<div class="testimonial-for row">
										<div class="slide-item col-12">
											<div class="testimonials-inner">
												<div class="testimonial-content">
													<h4 class="text-custom-blue fw-700 fs-20">Very satisfied!</h4>
													<p class="text-light-white mb-xl-20" style="text-align: justify;">With its next generation of products "I-Bank 4.0" OCEAN offers a concept that can be quickly implemented for your digital banking products, which has already proven effective and is years ahead today. Ocean Software Technologies transfer our 10 years old bank data from our old sofware to there "I-Bank 4.0" within 3 days with 99.98% accuracy and our woking live in 4<sup>th</sup> day on I-Bank software.</p>
													<p class="text-custom-black fw-700"><strong>Mr. Mahendra Raut<br><span class="text-light-blue fw-500">Prescient, Utthan Credit Cooperative Society</span></strong>
													</p>
												</div>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-inner">
												<div class="testimonial-content">
													<h4 class="text-custom-blue fw-700 fs-20">Very satisfied!</h4>
													<p class="text-light-white mb-xl-20" style="text-align: justify;">We put great emphasis on creating digital experiences for our citizen wherin Ocean has helped us building easy to use platform that serving thousands of Nature lovers right on their fingertips. Newly built website application developed by Ocean helps us generating more income than previous website.</p>
													<p class="text-custom-black fw-700"><strong>Mr. Srinivasa Reddy<br><span class="text-light-blue fw-500">Additional Principal Chief Conservator of Forests & Field Director, Melghat Tiger Project Amravati</span></strong>
													</p>
												</div>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-inner">
												<div class="testimonial-content">
													<h4 class="text-custom-blue fw-700 fs-20">Very satisfied!</h4>
													<p class="text-light-white mb-xl-20">The combination of standard banking functions that can be implemented immediately and the flexibility and stability of the "I-Bank 4.0" framework meet our requirements for a modern digital banking solution precisely.</p>
													<p class="text-custom-black fw-700"><strong>Mr. Sairam Chauhan<br><span class="text-light-blue fw-500">Nanded Urban Multiple Nidhi Ltd.</span></strong>
													</p>
												</div>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-inner">
												<div class="testimonial-content">
													<h4 class="text-custom-blue fw-700 fs-20">Very satisfied!</h4>
													<p class="text-light-white mb-xl-20">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
													<p class="text-custom-black fw-700"><strong>James Brown<br><span class="text-light-blue fw-500">Web Developer</span></strong>
													</p>
												</div>
											</div>
										</div>
										<div class="slide-item col-12">
											<div class="testimonials-inner">
												<div class="testimonial-content">
													<h4 class="text-custom-blue fw-700 fs-20">Very satisfied!</h4>
													<p class="text-light-white mb-xl-20">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
													<p class="text-custom-black fw-700"><strong>Maria Lopez<br><span class="text-light-blue fw-500">Sales Manager</span></strong>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
-->
		<!-- End testimonials -->
	
		<!-- Start clients-->
		<section >
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="clients-box">
							<div class="section-header">
								<div class="section-heading">
									<h3 class="text-custom-black fw-700">Our Clients</h3>
								</div>
								<div class="section-description">
									<p class="text-light-white">Work done by Ocean Software Technologies</p>
								</div>
							</div>
							<div class="clients-wrapper">
								<div class="client-box">
									<div class="client-item">
										<a href="https://www.tech-emergingengg.com/" target="_blank">
											<img src="assets/images/clients/Tech.png" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://bbncoop.in/" target="_blank">
											<img src="assets/images/clients/BBN.png" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://saccos.smeaz.org.zw/" target="_blank">
											<img src="assets/images/clients/SACCOS.jpg" style="height: 50px;" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://www.kygoldfinance.com/" target="_blank">
											<img src="assets/images/clients/KYGOLD.jpg" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://vanshahplantation.com/" target="_blank">
											<img src="assets/images/clients/MBDB.jpg" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://ofroh.org/" target="_blank">
											<img src="assets/images/clients/ofroh.jpg" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="http://vrentify.com/" target="_blank">
											<img src="assets/images/clients/Vrentify.jpg" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://www.magicalmelghat.in/" target="_blank">
											<img src="assets/images/clients/melghat.png" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="https://www.mahapenchtiger.com/" target="_blank">
											<img src="assets/images/clients/Pench.png" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="/">
											<img src="assets/images/clients/utthan.jpg" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="" target="_blank">
											<img src="assets/images/clients/ibank.png" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								<div class="client-box">
									<div class="client-item">
										<a href="" target="_blank">
											<img src="assets/images/clients/1.jpeg" class="image-fit" alt="img">
										</a>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End clients -->
		<!-- Start Faqs -->
		<section class="section-padding loan-faqs-sec bg-light-white findrate-top">
			<div class="container">
				<div class="row">
					<div class="col-xl-5 col-lg-6 align-self-center">
						<div class="section-header">
							<div class="section-heading">
								<h3 class="text-custom-black fw-700">Our FAQs</h3>
								<div class="section-description">
									<p class="text-light-white no-margin">Still have questions?
										<br>Our Client Success Team is always here to help</p>
								</div>
							</div>
						</div>
						<div class="loan-faqs-leftside mb-md-80">
							<div class="support-card mb-xl-20">
								<div class="support-user">
									<i class="fa fa-phone fa-4x" style="color: #27346a; padding-bottom: 10px;" aria-hidden="true"></i>
								</div>
								<div class="links text-center mb-xl-20"> <a routerLink="/">help@oceansofttech.net</a>
									<a routerLink="/">0712-2715852</a>
									<a routerLink="/">+91 9423685852</a>
								</div>
								<div class="schdule">
									<ul class="custom">
										<li>Mon - Fri <span class="text-light-white"> 10 AM – 7 PM</span>
										</li>
										<li>Sat <span class="text-light-white">10 AM – 2 PM</span>
										</li>
									</ul>
								</div>
							</div> <a routerLink="/Conatctocean" class="btn-first btn-submit-fill"> Contact Us</a>
						</div>
					</div>
					<div class="col-xl-7 col-lg-6 align-self-center">
						<div id="accordion" class="faqs-accordion">
							<div class="card" style="text-align: justify;">
								<div class="card-header" id="headingOne">
									<button class="collapsebtn" data-toggle="collapse" data-target="#collapseOne">What is an I-Bank 4.0 CBS Core Banking System?</button>
								</div>
								<div id="collapseOne" class="collapse show" data-parent="#accordion">
									<div class="card-body">
										<p class="text-light-white">
											"I-Bank 4.0 CBS" is Comprehensive, Robust, Secured, Fully Web Based modular core banking solution. It is very user-friendly & simple to operate which provide unified platform for Bank, Cooperative Society, Multistate Cooperative society, Employee Cooperative Society, Nidhi Company, NBFC & Finance Company.
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header" id="headingTwo">
									<button class="collapsebtn collapsed" data-toggle="collapse" data-target="#collapseTwo">What are the requirements for I-Bank 4.0 Core Banking System?</button>
								</div>
								<div id="collapseTwo" class="collapse" data-parent="#accordion">
									<div class="card-body">
										<p class="text-light-white no-margin">
											1) Google Chrome<br>
											2) Static IP<br>
											3) 2 MBPS Brodband Internet Connection
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header" id="headingThree">
									<button class="collapsebtn collapsed" data-toggle="collapse" data-target="#collapseThree">Main Madouls of I-Bank 4.0 CBS?</button>
								</div>
								<div id="collapseThree" class="collapse" data-parent="#accordion">
									<div class="card-body">
										<p class="text-light-white">1) Masters Entery</p>
										<p class="text-light-white">2) Accounts Entery</p>
										<p class="text-light-white">3) Pigmy/Daily Entery</p>
										<p class="text-light-white">4) All Transactions</p>
										<p class="text-light-white">5) Daily Reports</p>
										<p class="text-light-white">6) Reports</p>
										<p class="text-light-white">7) Mobile App</p>
										<p class="text-light-white">8) interest Posting</p>
										<p class="text-light-white">9) Services</p>
										<p class="text-light-white">10) Tools & Utilities</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header" id="headingFour">
									<button class="collapsebtn collapsed" data-toggle="collapse" data-target="#collapseFour">
										Any server need to purchase for I-Bank 4.0 Software hosting?
									</button>
								</div>
								<div id="collapseFour" class="collapse" data-parent="#accordion">
									<div class="card-body">
										<p class="text-light-white">
											I-Bank 4.0 CBS is hosting on Ocean Datacenter in Nagpur & Bangalore.<br>
										</p>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-header" id="headingFour">
									<button class="collapsebtn collapsed" data-toggle="collapse" data-target="#collapseFive">
										Which Technologies used in “I-Bank 4.0” CBS?
									</button>
								</div>
								<div id="collapseFive" class="collapse" data-parent="#accordion">
									<div class="card-body">
										<p class="text-light-white">
											.Net 4.5 Framework, ASP.Net, C#, Json, XML & Sql-Server 2019 R2.<br>
											Windows Server 2012 R2<br>
											Data First Programming approach with Centralized Database.<br>
											N Tier Programming Architecture.<br>
											Object Oriented Programming concepts.<br>
										</p>
									</div>
								</div>
							</div>

							<div class="card">
								<div class="card-header" id="headingFour">
									<button class="collapsebtn collapsed" data-toggle="collapse" data-target="#collapsesix">
										Mobile App in “I-Bank 4.0” CBS?
									</button>
								</div>
								<div id="collapsesix" class="collapse" data-parent="#accordion">
									<div class="card-body">
										<p class="text-light-white">
											Agent Deposit & Loan Collection App<br>
											Customer App Android<br>
											Employee App<br>
											Bank Management App
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Faqs -->
		<!--LOANLY-advertisement-->
	<!--<section class="section-padding LOANLY-advertisement parallax">
			<div class="row">
				<div class="col-12">
					<div class="advertisement-text">
						<p class=" text-white fs-20">Website-Development & Mobile App</p>
						<h3 class="text-white">We are with you every step .</h3>
						<div class="btn-group">
							<a routerLink="/service" class="btn-first btn-submit fw-600 mb-xs-20">Our Services</a>
							<a routerLink="/contactus" class="btn-first btn-submit-fill fw-600 mb-xs-20">Start Now</a>
						</div>
					</div>
				</div>
			</div>
		</section>-->
		<!-- LOANLY-advertisement end -->
		<app-footer></app-footer>
</div>