<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-App p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h3 style="color:white">APP for Agent / Branch Manager / Bank Manager / Customer</h3>
                        <h4 style="color: aliceblue;">Agent App, Branch Manager App, & Bank Manager App (Android & iPhone)</h4>
                        <h6 style="color:white">"I-Bank 4.0" Come with Agent Mobile App for Pigmy / Daily collection, Branch Manager App, Bank Manager App & Customer App</h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">I-Bank 4.0 CBS SaaS provider with 98% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support*</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Ionic, Capacitor, Json, Javascript, HTML & Android</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Security & Authentication</h6>
                                            <p class="text-custom-white">Static IP & Two-Step Authentication</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Mobile App</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
 <!-- Start About Us Intro -->
<section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta">
                    <h4><a routerLink="#" class="text-custom-black fw-600">Mobile First – an innovative approach</a></h4>
                    <p class="text-light-white" style="text-align: justify;">Mobile First (In 2018) is an innovative approach that OCEAN Softtech is currently using to drive its innovations forwards. The optimization of the user experience, taking the user context into account, and the standardization of the different screen formats and mobile devices are a must today. The following figures give a good overview of how banking customers are performing their banking operations today.</p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<section class="our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta" style="text-align: center;">
                    <h4><a class="text-custom-black fw-600">Successfully implemented Agent Collection App, Branch Manager & Bank Manager App in Android & iPhone</a></h4>
                    <p class="text-light-white" style="text-align: justify;">With new Ionic Technology company develop new hybrid app that work on both Android & iPhone platform. There are 3 different types of App we provide to client B2B business section.<br>
                        1) Agent RD/Daily collection App <br>
                        2) Branch Manager App <br>
                        3) Bank Manager App <br><br>
                        We also provide 1 App in B2C section<br>
                        1) Customer App    
                        
                    </p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<!-- End About Us Intro -->
<!-- Start Main Services -->
<section class="main-services p-relative section-padding" >
    <div class="side-lines"> <span class="box"></span>
        <span class="text">App's</span>
        <span class="line"></span>
    </div>
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">Different Apps used in I-Bank 4.0 for time efficient banking</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-android2"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Agent App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Agent Multi GL collection App is useful for RD / Daily collection, App is directly connected to bank server.<br><b> All transactions are done in Real-Time.</b><br>
                            1) RD/Daily Collection<br>
                            2) Check all account status<br>
                            3) Balances of customs**<br>
                            4) Agent Chart report**<br>
                            5) Agent liability report**<br>
                            *Report generated in Real-Time<br>
                            ** Optional in Agent App

                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-phone"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Branch Manager App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Branch Manager of Nidhi / Society / NBFC can check online status of there branch.<b> Reports like below use in field.</b><br>
                            1) CashBook <br> 
                            2) DayBook <br>
                            3) Profit-Loss<br>
                            4) Receipt-Payment <br>
                            5) Voucher-Transfer by user <br>
                            <b>Using this App Manager also check</b><br>
                            6) Clients A/C Balance<br>
                            7) Account Statement
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-apple"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Bank Manager App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Bank Manager of Nidhi / Society / NBFC can check online status of there all branchs. Report are show on single branch & Consolidated.<br>
                            1) CashBook (All Branches) <br> 
                            2) DayBook (All Branches)<br>
                            3) Profit-Loss (All Branches)<br>
                            4) Receipt-Payment<br>
                            5) Voucher-Transfer by user <br>
                            <b>Using this App Manager also check</b><br>
                            6) Clients A/C Balance<br>
                            7) Account Statement
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xs-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-badge"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Bank Customer App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Customer is in center of our finance business, we provide very basic to all advance activity to all your financial institutions customers.<br>
                            1) Balance Checking<br>
                               (Saving - FD - RD - Loan Acc.)<br>
                            2) New Loan Enquiry<br>
                            3) Check New Updates<br>
                            <b>Other Bank RTGS/NEFT  </b><br>
                            5) QR Code<br>
                            6) UPI ID<br>
                            7) Bank Transfer<br>
                            8) Investment (MF, Gold, ETC.)
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Main Services -->
	<!-- About One section-->
    <section class="section-padding about-us-sec p-relative">
        <div class="side-lines right-side"> <span class="box"></span>
            <span class="text">Android</span>
            <span class="line"></span>
        </div>
        <h3 class="text-custom-black fw-700" style="text-align:center;">Ocean Software Technologies App used In I-Bank 4.0</h3>
        <div class="container">
            <div class="row no-gutters">
                <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                    <div class="about-left-side-1">
                        <div class="section-header">
                            <div class="section-heading">
                                <h4 class=" fw-700 text-dark">Agent Collection Android & iPhone App</h4>
                            </div>
                        </div>
                        <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                            <b>Daily / RD Collection<br>
                               <small>Online Real-Time collection </small><br>
                               Loan Installment Collection <br>
                               <small>for fast Loan Recovery </small><br>
                               Bachat Gat Installment Collection <br>
                               <small>for fast Loan Recovery </small><br>
                               Customer Balance Checking<br>
                               <small>Real-Time Closeing Balance</small><br>
                               Today Total Collection Report<br>
                               <small>Accountwise collection list</small><br>
                               Cash collection is increased by 20 to 25%<br>
                               Cash Collection in Cash & Transfer both mode<br>
                               
                            </b>
                            </p>
                        <a routerLink="/i-bank-price" fragment="AgentApp" class="btn-first btn-submit-fill fw-600 mb-xl-20"><i class="fas fa-rupee-sign mr-2 fs-16"></i>Agent App Price</a>
                        <a href="/assets/AgentApp.apk" download="AgentApp.apk" class="btn-first btn-submit-fill fw-600 mb-xl-20"><i class="bi bi-android2 mr-2 fs-16"></i> Download APK</a>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="blog-img animate-img mb-xl-20">
                        <video controls playsinline="playsinline" class="jarallax" autoplay="autoplay" height="50%" muted="muted" loop="loop">
                            <source src="https://www.tech-emergingengg.com/App.mp4" type="video/mp4">
                          </video>
                    </div>
                </div>
            </div>
            <div class="container about-sec-second">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="blog-img animate-img mb-xl-20">
                            <video controls playsinline="playsinline" class="jarallax" autoplay="autoplay"  muted="muted" loop="loop">
                                <source src="https://www.tech-emergingengg.com/CustApp.mp4" type="video/mp4" style="height: 25%;">
                              </video>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg">
                        <div class="about-left-side-1 p-relative">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h4 class=" fw-700 text-dark">Customer App</h4>
                                </div>
                            </div>
                            <div class="about-desc">
                                <p class="text-light-white mb-xl-20" style="text-align: justify;">
                                    Self Registration process for Customer App. (Token Based)<br>
                                    Unique UUID for each Customer App. <br>
                                    Customer can check All account details.<br>
                                    Customer can check Balance of all accounts.<br>
                                    <b><i>Secure, simple and convenient authentication!</i></b>
                                </p>
                            </div>
                            <div class="company-progress">
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--About-two-section-end-->
<app-footer></app-footer>