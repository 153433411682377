<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header overlapsec p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Loan Calculator</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Loan Calculator</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Loan Calculator -->
        <section class="section-padding-bottom ln-loan-calculator-style">
            <div class="container">
                <div class="row">
                    <div class="offset-lg-3 col-lg-6">
                        <div class="ln-loan-calculator-wrap">
                            <h4 class="text-center text-custom-black fw-600">Customize your loan</h4>
                            <p class="text-custom-black text-center">Adjust the loan amount to see your options</p>
                            <div class="loan-price-wrap">
                                <div class="quantity mb-xl-20">
                                    <input type="text" class="form-control form-control-custom" name="name" value="50000">
                                    <button class="minus-btn btn-first btn-submit-fill btn-height" type="button" name="button"> <i class="fas fa-minus"></i></button>
                                    <button class="plus-btn btn-first btn-submit-fill btn-height border-0" type="button" name="button"> <i class="fas fa-plus"></i></button>
                                </div>
                                <p class="text-center text-light-grey">You can borrow between $1,000 - $1,00,000</p>
                                <div class="form-group">
                                    <div class="custom-radio">
                                        <input id="loanstep1" checked="" name="loanstep" value="30" type="radio">
                                        <label for="loanstep1">
                                            <span class="upper-text">
                                                Repay With
                                                <span>One-Time Fee</span>
                                            </span>
                                            <span class="bottom-text">
                                                <span>30% <small class="fw-600">of sales</small></span>
                                                <span>$1,672</span>
                                            </span>
                                            <span class="radio-loan-amount-wrap">
                                                <span class="top-text">
                                                    Total You Pay
                                                    <span class="fw-600">$43,000</span>
                                                </span>
                                                <span class="btm-text">
                                                    Estimated Time To Repay
                                                    <span class="fw-600">
                                                        12 Months
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                        <input type="hidden" name="intrest-30" id="intrest-30" value="30">
                                        <input type="hidden" name="months-30" id="months-30" value="12">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="custom-radio">
                                        <input id="loanstep2"  name="loanstep" value="25" type="radio">
                                        <label for="loanstep2">
                                            <span class="upper-text">
                                                Repay With
                                                <span>One-Time Fee</span>
                                            </span>
                                            <span class="bottom-text">
                                                <span>25% <small class="fw-600">of sales</small></span>
                                                <span>$2,027</span>
                                            </span>
                                            <span class="radio-loan-amount-wrap">
                                                <span class="top-text">
                                                    Total You Pay
                                                    <span class="fw-600">$43,000</span>
                                                </span>
                                                <span class="btm-text">
                                                    Estimated Time To Repay
                                                    <span class="fw-600">
                                                        12 Months
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="custom-radio">
                                        <input id="loanstep3"  name="loanstep" value="20" type="radio">
                                        <label for="loanstep3">
                                            <span class="upper-text">
                                                Repay With
                                                <span>One-Time Fee</span>
                                            </span>
                                            <span class="bottom-text">
                                                <span>20% <small class="fw-600">of sales</small></span>
                                                <span>$2,749</span>
                                            </span>
                                            <span class="radio-loan-amount-wrap">
                                                <span class="top-text">
                                                    Total You Pay
                                                    <span class="fw-600">$43,000</span>
                                                </span>
                                                <span class="btm-text">
                                                    Estimated Time To Repay
                                                    <span class="fw-600">
                                                        12 Months
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="custom-radio">
                                        <input id="loanstep4"  name="loanstep" value="15" type="radio">
                                        <label for="loanstep4">
                                            <span class="upper-text">
                                                Repay With
                                                <span>One-Time Fee</span>
                                            </span>
                                            <span class="bottom-text">
                                                <span>15% <small class="fw-600">of sales</small></span>
                                                <span>$4,080</span>
                                            </span>
                                            <span class="radio-loan-amount-wrap">
                                                <span class="top-text">
                                                    Total You Pay
                                                    <span class="fw-600">$43,000</span>
                                                </span>
                                                <span class="btm-text">
                                                    Estimated Time To Repay
                                                    <span class="fw-600">
                                                        12 Months
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="custom-radio">
                                        <input id="loanstep5"  name="loanstep" value="10" type="radio">
                                        <label for="loanstep5">
                                            <span class="upper-text">
                                                Repay With
                                                <span>One-Time Fee</span>
                                            </span>
                                            <span class="bottom-text">
                                                <span>10% <small class="fw-600">of sales</small></span>
                                                <span>$7,394</span>
                                            </span>
                                            <span class="radio-loan-amount-wrap">
                                                <span class="top-text">
                                                    Total You Pay
                                                    <span class="fw-600">$43,000</span>
                                                </span>
                                                <span class="btm-text">
                                                    Estimated Time To Repay
                                                    <span class="fw-600">
                                                        12 Months
                                                    </span>
                                                </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Loan Calculator -->
        <app-footer></app-footer>