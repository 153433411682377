import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("About Ocean Software Technologies");
    this.metaService.addTags([
      {name: 'keywords', content: 'about Ocean Software Technologies,about ocean Softtech, Bank free Software, Credit, Cooperative, Society,free Banking Credit Cooperative Society Software in Hindi Marathi & English,Best Core Banking System,online Credit Cooperative Society CBS free software, online Credit Cooperative Society CBS free Demo software, Yogesh Kedar, Sachin Bawane, Sarita Warudkar, Sarita Kedar, Sagar Wakhariya, Nilesh Meshram, Sunil Zore'},
      {name: 'description', content: 'Ocean Software Technologies, About Ocean Software Technologies Nagpur'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }
 
}
