import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})
export class ClientsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Clients of Ocean Software Technologies");
    this.metaService.addTags([
      {name: 'keywords', content: 'Cooperative Society, Multistate Society, Nidhi Finance Company, Goverment office, Pench tiger reserve, Melghat Tiger reserve, zoo authority of maharashtra, nawegoan nagzia tiger reserve'},
      {name: 'description', content: 'Clients of Ocean Software Technologies'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

}
