import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-disclaimer',
  templateUrl: './legal-disclaimer.component.html',
  styleUrls: ['./legal-disclaimer.component.css']
})
export class LegalDisclaimerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
