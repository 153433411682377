<div class="extra-page">
	<div class="header">
            <a routerLink="/" class="logo">
                <img src="assets/images/Ocean-SoftTech-Logo.jpg" style="width: 150px;" alt="logo">
            </a>
            <a routerLink="https://www.facebook.com/Oceansofttech.net/"> <i class="fab fa-facebook-f"></i>
            </a>
            <div class="divider"></div>
            <a routerLink="/404"> <i class="fab fa-twitter"></i>
            </a>
            <a routerLink="/"> <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <div class="ghost"> <span class="text">404</span>
            <div class="eye"></div>
            <div class="eye two"></div>
            <div class="mouth"></div>
            <div class="corner"></div>
            <div class="corner two"></div>
            <div class="corner three"></div>
            <div class="corner four"></div>
            <div class="over"></div>
            <div class="over two"></div>
            <div class="over three"></div>
            <div class="over four"></div>
            <div class="shadow"></div>
        </div>
        <div class="main">
            <div class="error">error</div>
            <h2>Page not found</h2>
            <h6 class="text-custom-white">The page you are looking for might have been removed, had its name changed,<br>or is temporarily unavailable.</h6>
        </div>
</div>