import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-multistate-society',
  templateUrl: './multistate-society.component.html',
  styleUrls: ['./multistate-society.component.css']
})
export class MultistateSocietyComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Core Banking System Software For Multi State Cooperative Society By Ocean Softtech");
    this.metaService.addTags([
      {name: 'keywords', content: 'Free Software For Multi State Cooperative Society, Multi State Cooperative Society Software free downloan, Bank free Software, Credit,Multi State Cooperative,Multi State Society,free Banking Multi State Cooperative Society Software in Hindi Marathi & English,Best Core Banking System,online Multi State Cooperative Society CBS free software,online Multi State Cooperative Society CBS free Demo software मोफत बँकिंग क्रेडिट कोऑपरेटिव्ह सोसायटी सॉफ्टवेअर हिंदी मराठी आणि इंग्रजीमध्ये'},
      {name: 'description', content: 'I-Bank 4.0 CBS Core Banking System Software For Multi State Cooperative Society By Ocean Software Technologies'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

}
