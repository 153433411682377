
<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Legal Disclaimer</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Legal Disclaimer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container" style="text-align: justify;">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h2><a routerLink="/legal-dis" class="text-custom-black fw-600">Legal Disclaimer</a></h2>
                            <br>
                            <p>
                                            Please read these Terms of Use (hereafter “Terms”) carefully. Upon access to this website (hereafter “site”) you declare that you acknowledge the following Terms and legal information in connection with this site (including all elements contained therein). If you are not in agreement with the following Terms, please exit the site and all its pages and refrain from any access to it.
                                            <p>
                                                <strong>No Offer</strong><br>
                                                The information contained on this site serves solely informational purposes. With the exception of the specific offers including prices on www.clx-shop.com, there are no general offers presented on this site.
                                            </p>
                                            <p>
                                                The information and opinions published on this site do not constitute advice or recommendation on the part of Ocean Software Technologies or any of its subsidiary companies (hereafter individually and together “OCEAN”). The services described on the site are not directed at or intended for use by persons residing, in any jurisdiction or country in which access to such information is prohibited on the basis of existing law or regulations. Persons to whom such prohibitions would apply on grounds of citizenship must not access the site. The information presented on the site bears descriptive character for OCEAN as a whole; it can however be the case that the services described are not available, applicable or appropriate for you.<br>
                                                With the exception of the specific offers including prices on www.clx-shop.com, there are no general offers presented on this site.
                                            </p>
                                            <p>
                                                <strong>Links</strong><br>
                                                OCEAN has not reviewed sites possibly linked with this site and is not responsible for the content of these off-site pages or of any other sites linked with the OCEAN site. The activation of any link to an off-site page or to another site occurs at one’s own risk.
                                            </p>
                                            <p>
                                                <strong>No Solicitation</strong><br>
                                                OCEAN expressly maintains no obligation to accept any offers of business transactions or other business activities that are communicated to OCEAN through this site.
                                            </p>
                                            <p>
                                                <strong>No Warranty</strong><br>
                                                While OCEAN makes every effort that the information on this site is accurate at the time of publication, neither OCEAN nor its contractual partners can make any explicit or implicit warranty or guarantee (including opposite third parties) regarding the accuracy, reliability or completeness of information presented. Opinions and other information presented on the site can be changed at any time without prior notice. The publication of opinions or information on this site does not imply that these same opinions or information or the basis for these opinions or information have not changed since their publication.
                                            </p>
                                            <p>
                                                <strong>No Guarantee</strong><br>
                                                OCEAN makes information and opinions available without any guarantee whatsoever. OCEAN furthermore assumes no responsibility and provides no guarantee that the functions of this site will continue without interruption or be free from error, that errors will be rectified or that this site or the respective server is free of viruses or harmful components.
                                            </p>
                                            <p>
                                                <strong>No Liability</strong><br>
                                                OCEAN excludes any liability whatsoever (including negligence) for damages or resulting damages of any kind that may be incurred through the use or access to this site, elements of this site or through links or sites of third parties (including any damages incurred from an impossibility of use or of access). Furthermore OCEAN excludes any liability for manipulation of the IT system of an Internet user by third parties.
                                            </p>
                        </div>
                    </div>
                </article>
                <!-- article -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <app-footer></app-footer>