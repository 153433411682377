<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-contact p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h1 class="text-custom-white lh-default fw-600">Contact Us</h1>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Contact -->
<section class="section-padding bg-gray contact-us">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-4 mb-md-80">
                <div class="contact-form full-height align-self-center bx-wrapper bg-custom-white">
                    <h4 class="text-custom-black fw-600">Get In Touch</h4>
                    <p class="text-light-white no-margin"><b>Ocean Software Technologies</b><br>SH. No. A-002, Plot No.
                        14, Shivaji Park<br>Shrikrishna Nagar. Nagpur - 440009<br>Maharashtra. (India)</p>
                    <form [formGroup]="form" id="contactForm" class="form-layout-1">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-left">Full Name</label>
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your Name"formControlName="Name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label class="text-left">Email Address</label>
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Your Email" formControlName="Email">
                                </div>
                            </div>
                            <div class="col-sm-6 mb-xl-20">
                                <div class="form-group">
                                    <label class="text-left">Phone No</label>
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone" formControlName="phone_number">
                                </div>
                            </div>
                            <div class="col-sm-6 mb-xl-20">
                                <div class="form-group">
                                    <label class="text-left">Subject</label>
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"placeholder="Your Subject" formControlName="Subject">
                                </div>
                            </div>
                            <div class="col-12 mb-xl-20">
                                <div class="form-group">
                                    <label class="text-left">Message</label>
                                    <textarea rows="5" name="message_html" id="message_html" class="form-control" placeholder="Write Something" formControlName="Message"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button type="submit" (click)="Email_send()" class="btn-first btn-submit text-light-blue full-width">Submit</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <div class="col-xl-7 col-lg-8">
                <iframe class="full-width full-height"
                    src="https://www.google.com/maps/d/embed?mid=1CUVw1qrSTsfWdc9k5xjkAog3Vdw"></iframe>
            </div>
        </div>
    </div>
</section>
<!-- End Contact -->
<!-- Start Contact Bottom -->
<section class="section-padding bg-gray contact-bottom">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info bx-wrapper bg-custom-white text-center mb-md-40">
                    <div class="contact-info-wrapper">
                        <div class="icon mb-xl-20"> <i class="fas fa-phone-alt"></i>
                        </div>
                        <h5 class="text-custom-black fw-600">Phone</h5>
                        <!--<p class="text-light-white">Start working with Ocean that can provide everything</p> -->
                        <a routerLink="/contactus" class="fs-14">0712-2715852</a><br>
                        <a routerLink="/contactus" class="fs-14">0712-2958754</a><br>
                        <a routerLink="/contactus" class="fs-14">+91 9423685852 (Mr.Yogesh)</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info bx-wrapper bg-custom-white text-center mb-md-40">
                    <div class="contact-info-wrapper">
                        <div class="icon mb-xl-20"> <i class="far fa-envelope"></i>
                        </div>
                        <h5 class="text-custom-black fw-600">Email</h5>
                        <!-- <p class="text-light-white">Start working with Ocean that can provide everything</p> -->
                        <a routerLink="/contactus" class="fs-14">help@oceansofttech.net</a><br>
                        <a routerLink="/contactus" class="fs-14">yogeshk_123@yahoo.co.in</a><br>
                        <a routerLink="/contactus" class="fs-14">+91 9766916371 (Mr.Sachin)</a><br>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="contact-info bx-wrapper bg-custom-white text-center">
                    <div class="contact-info-wrapper">
                        <div class="icon mb-xl-20"> <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <h5 class="text-custom-black fw-600">Location</h5>
                        <p class="text-light-white">Way to Ocean Software Technologies</p>
                        <a routerLink="/contactus" class="fs-14">View on Google map</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Bottom -->
<app-footer></app-footer>