<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header overlapsec p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Finance</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Finance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start User Details -->
        <section class="section-padding-bottom user-info-details">
            <div class="container">
                <div class="row">
                    <div class="offset-lg-3 col-lg-6">
                        <div class="user-info-tabs">
                            <div id="add-user-tab" class="step-app">
                                <ul class="step-steps custom mb-xl-40">
                                  <li class="active">
                                    <a href="#step-1"> <span class="number"></span>
                                      <span class="step-name">Basic Info</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#step-2"> <span class="number"></span>
                                      <span class="step-name">Personal Info</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#step-3"> <span class="number"></span>
                                      <span class="step-name">Business Location</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#step-4"> <span class="number"></span>
                                      <span class="step-name">Business Details</span>
                                    </a>
                                  </li>
                                  <li>
                                    <a href="#step-5"> <span class="number"></span>
                                      <span class="step-name">Verify Identity</span>
                                    </a>
                                  </li>
                                </ul>
                                <div class="step-content">
                                    <div class="step-tab-panel active" id="step-1">
                                        <div class="step-tab-inner">
                                            <div class="heading text-center">
                                                <h4 class="text-custom-black fw-600">Enter Basic Information</h4>
                                                <p class="text-custom-black">Check your business's eligibility without impacting your credit.<sup>*</sup></p>
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Name</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Business Name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">First Name</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="First Name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Last Name</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Last Name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Phone No.</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Phone No.">
                                                        </div>

                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Email Address</label>
                                                            <input type="email" name="#" class="form-control form-control-custom" placeholder="Email Address">
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="radio-box form-group">
                                                            <div class="custom-radio">
                                                                <input id="phone1" name="phonetype" type="radio" checked>
                                                                <label for="phone1">Business</label>
                                                            </div>
                                                            <div class="custom-radio">
                                                                <input id="phone2" name="phonetype" type="radio">
                                                                <label for="phone2">Mobile</label>
                                                            </div>
                                                            <div class="custom-radio">
                                                                <input id="phone3" name="phonetype" type="radio">
                                                                <label for="phone3">Home</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Primary Loan Purpose</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>Purchase business location</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Reference</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>Banner/Digital Ad</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="custom-checkbox text-custom-black">
                                                                <input type="checkbox" name="#">
                                                                <span class="checkmark"></span>
                                                                I Agree to the <a routerLink="/finance">terms & conditions</a> and <a routerLink="/finance">privacy policy.</a>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="step-tab-panel" id="step-2">
                                        <div class="step-tab-inner">
                                            <div class="heading text-center">
                                                <h4 class="text-custom-black fw-600">Enter Personal Information</h4>
                                                <p class="text-custom-black">Check your business's eligibility without impacting your credit.<sup>*</sup></p>
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Home Address 1</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Home Address 1">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Home Address 2 (Optional)</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Home Address 2 (Optional)">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">City</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="City">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">State</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>NY</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">ZIP</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="ZIP Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-500">This must be a physical address, not a PO Box number.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Mobile Phone No.</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Mobile Phone No.">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Home Phone No.</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Home Phone No.">
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="step-tab-panel" id="step-3">
                                        <div class="step-tab-inner">
                                            <div class="heading text-center">
                                                <h4 class="text-custom-black fw-600">Enter Business Location</h4>
                                                <p class="text-custom-black">Check your business's eligibility without impacting your credit.<sup>*</sup></p>
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Address 1</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Business Address 1">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Address 2 (Optional)</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Business Address 2 (Optional)">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">City</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="City">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">State</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>NY</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">ZIP</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="ZIP Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-500">This must be a physical address, not a PO Box number.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Phone No.</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Business Phone No.">
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="step-tab-panel" id="step-4">
                                        <div class="step-tab-inner">
                                            <div class="heading text-center">
                                                <h4 class="text-custom-black fw-600">Tell us about your business.</h4>
                                                <p class="text-custom-black">Check your business's eligibility without impacting your credit.<sup>*</sup></p>
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Entry type</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>Sol Proprietorship</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Total annual business revenue</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="$ 10,000.00">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Number of full time employees</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="1">
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Industry</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>Professional and Technical Services</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Business Sub-industry</label>
                                                            <select class="custom-select form-control form-control-custom">
                                                                <option>Advertising Agencies and all other related services</option>
                                                                <option>Option 2</option>
                                                                <option>Option 3</option>
                                                                <option>Option 4</option>
                                                                <option>Option 5</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="step-tab-panel" id="step-5">
                                        <div class="step-tab-inner">
                                            <div class="heading text-center">
                                                <h4 class="text-custom-black fw-600">Tell us about your business.</h4>
                                                <p class="text-custom-black">Check your business's eligibility without impacting your credit.<sup>*</sup></p>
                                            </div>
                                            <form>
                                                <div class="row">
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Date of Birth</label>
                                                            <input type="text" name="#" class="form-control datepickrnew form-control-custom" placeholder="01/01/1990" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Social Security Number</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Social Security Number">
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group mb-1">
                                                            <label class="text-custom-black fs-14 fw-600">Federal Tax ID</label>
                                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Federal Tax ID">
                                                        </div>
                                                        <label class="text-light-grey mb-xl-20">Enter your social security number (SSN) of your business is a sole proprietorship or single-owner LLC without a Federal Tax ID.</label>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="text-custom-black fs-14 fw-600">Are you a citizen or permanent resident of the U.S.?</label>
                                                            <div class="radio-box">
                                                                <div class="custom-radio">
                                                                    <input id="yes" name="nationality" type="radio" checked>
                                                                    <label for="yes">Yes</label>
                                                                </div>
                                                                <div class="custom-radio">
                                                                    <input id="no" name="nationality" type="radio">
                                                                    <label for="no">No</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="custom-checkbox text-custom-black">
                                                                <input type="checkbox" name="#">
                                                                <span class="checkmark"></span>
                                                                I agree to receive Loan disclosures electronically to any email address provided by me.
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label class="custom-checkbox text-custom-black">
                                                                <input type="checkbox" name="#">
                                                                <span class="checkmark"></span>
                                                                I understand that by clicking the “Submit” button below, that I am giving Loanly, LLC and Loanly written instructions under the Fair Credit Reporting Act to obtain my personal and business credit reports and to evaluate my eligibility. Answering these questions lets me check my price and will not impact my credit score. I understand that if I sign a loan agreement, Loanly, LLC and Loanly will not perform credit checks and other public records checks, which may impact my credit score.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="step-footer step-tab-pager">
                                  <button data-direction="prev" class="btn-first btn-submit-fill">Previous</button>
                                  <button data-direction="next" class="btn-first btn-submit-fill">Continue</button>
                                  <button data-direction="finish" class="btn-first btn-submit-fill">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Start User Details -->
        <app-footer></app-footer>