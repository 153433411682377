<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Dashboard</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Dashboard</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start User Dashboard -->
        <section class="section-padding user-dashboard-sec bg-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="dashboard-first-sec">
                            <div class="row">
                                <div class="col-lg-6 align-self-center">
                                    <div class="left-side mb-md-80">
                                        <h3><a routerLink="/loan-dashboard" class="text-custom-black" target="_blank">www.companyname.com</a></h3>
                                        <h5 class="no-margin fw-600" data-toggle="collapse" data-target="#user-profile"><a routerLink="/loan-dashboard" class="text-custom-blue">Your Loan Profile</a></h5>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="right-side">
                                        <ul class="custom">
                                            <li>
                                                <div class="icon-box">
                                                    <img src="assets/images/more.png" alt="icon">
                                                </div>
                                                <p class="text-custom-black">Get more out of <br>Loanly</p>
                                            </li>
                                            <li>
                                                <div class="icon-box">
                                                    <img src="assets/images/connect.png" alt="icon">
                                                </div>
                                                <p class="text-custom-black">Connect with the <br>best</p>
                                            </li>
                                            <li>
                                                <div class="icon-box">
                                                    <img src="assets/images/move.png" alt="icon">
                                                </div>
                                                <p class="text-custom-black">Plan your next <br>move</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-padding user-dashboard-sec">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="collapse show user-loan-profile" id="user-profile">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="welcome-box mb-md-40">
                                        <div class="icon-box">
                                            <img src="assets/images/faq-user.png" alt="icon">
                                        </div>
                                        <div class="welcome-text">
                                            <h4 class="text-custom-black">Welcome Back!</h4>
                                            <p class="text-custom-black">We're happy to help you grow your business with Loanly Working Capital.</p>
                                            <a routerLink="/loan-dashboard" class="text-custom-blue fw-600 fs-14">View loan history</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-sm-4">
                                    <div class="loan-box-wrap">
                                        <div class="content">
                                            <h2 class="text-custom-black fw-600">5</h2>
                                            <p class="no-margin text-custom-black">Loans</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-8">
                                    <div class="loan-box-wrap justify-content-between">
                                        <div class="content">
                                            <h2 class="text-custom-black fw-600">$ 50,000</h2>
                                            <p class="no-margin text-custom-black">Total Borrowed</p>
                                        </div>
                                        <div class="content-infor">
                                          <ul class="custom">
                                            <li>Last Payment on <a routerLink="/loan-dashboard"></a>24th April 20</li>
                                            <li> <a routerLink="/loan-dashboard">View Monthly Details</a> </li>
                                            <li> <a routerLink="/loan-dashboard">View Yearly Details</a> </li>
                                          </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="close-btn" data-toggle="collapse" data-target="#user-profile">
                                    <a routerLink="/loan-dashboard"><i class="fas fa-times"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="dashboard-second-sec" data-toggle="collapse" data-target="#loan-progress">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 align-self-center">
                                    <div class="heading-text">
                                        <h4 class="text-custom-black no-margin">LOAN PROGRESS CHARTS</h4>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-6">
                                    <div class="right-side">
                                        <div class="pie-chart-box">
                                            <div class="active-border activeBorder">
                                                <div class="circle circlestyle">
                                                    <span class="prec 282 precs">0%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="line-chart-box">
                                            <div class="graph">
                                                <canvas id="small-graph"></canvas>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-second-sec-large collapse show" id="loan-progress">
                            <div class="row">
                                <div class="col-lg-5">
                                    <h5 class="text-custom-black fw-600 text-center mb-xl-40">Repayment Progress</h5>
                                    <div class="left-side mb-md-40">
                                        <div class="pie-chart-area">
                                            <div class="active-border activeBorder">
                                                <div class="circle circlestyle-big">
                                                    <span class="prec 282 precs">0%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pie-chart-area-text">
                                            <div class="text-wraper mb-xl-20">
                                                <label class="fw-600 fs-16 text-custom-black">$ 10,000</label>
                                                <p class="no-margin fw-500 text-custom-black">Outstanding balance</p>
                                            </div>
                                            <div class="text-wraper">
                                                <label class="fw-600 fs-16 text-custom-black">$ 1,257</label>
                                                <p class="no-margin fw-500 text-custom-black">Paid to date</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <h5 class="text-custom-black fw-600 text-center mb-xl-40">Repayment Tracking</h5>
                                    <div class="right-side">
                                        <div class="graph mb-xs-40">
                                            <canvas id="big-graph"></canvas>
                                        </div>
                                        <div class="graph-text">
                                            <div class="text-wraper mb-xl-20">
                                                <label class="fw-600 fs-16 text-custom-black">Average Payment</label>
                                                <p class="no-margin fw-500 text-custom-black">$ 57,975</p>
                                            </div>
                                            <div class="text-wraper">
                                                <label class="fw-600 fs-16 text-custom-black">Estimated time to repay</label>
                                                <p class="no-margin fw-500 text-custom-black">1 month(s)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="dashboard-third-sec">
                            <div class="row">
                                <aside class="col-lg-4 mb-md-80">
                                    <div class="widget-box mb-xl-30">
                                        <h4 class="text-custom-black">Loan Details</h4>
                                        <div class="content-wrap">
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-16 text-custom-black">$ 10,000</label>
                                                <p class="no-margin fw-500">Outstanding balance</p>
                                            </div>
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-16 text-custom-black">$ 1,257</label>
                                                <p class="no-margin fw-500">Paid to date</p>
                                            </div>
                                            <button type="submit" class="btn-first btn-submit text-custom-blue">Make Payment</button>
                                        </div>
                                    </div>
                                    <div class="widget-box mb-xl-30">
                                        <div class="content-wrap">
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-16 text-custom-black">$ 10,000</label>
                                                <p class="no-margin fw-500">Initial loan amount</p>
                                            </div>
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-16 text-custom-black">$ 1,257</label>
                                                <p class="no-margin fw-500">Loan Fee</p>
                                            </div>
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-16 text-custom-black">30%</label>
                                                <p class="no-margin fw-500">Repayment percentage</p>
                                            </div>
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-16 text-custom-black">$ 4,500</label>
                                                <p class="no-margin fw-500">Minimum due every 90 days</p>
                                            </div>
                                            <div class="text-wraper">
                                                <a routerLink="/loan-dashboard" class="text-custom-blue fw-600 fs-14"><i class="fas fa-download mr-2"></i> Download contract</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-box mb-xl-30">
                                        <h4 class="text-custom-black">How are we doing?</h4>
                                        <div class="content-wrap">
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-14 text-custom-black mb-xl-10">Your feedback is always appreciated.</label>
                                            </div>
                                            <div class="text-wraper">
                                                <a routerLink="/loan-dashboard" class="text-custom-blue fw-600 fs-14">Take our satisfaction survey</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-box mb-xl-30">
                                        <h4 class="text-custom-black">Loan Tips</h4>
                                        <div class="content-wrap">
                                            <div class="text-wraper mb-xl-10">
                                                <label class="fw-600 fs-14 text-custom-black mb-xl-10">Meet the minimum requirement</label>
                                                <p class="no-margin fw-500">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                            <div class="text-wraper">
                                                <a routerLink="/loan-dashboard" class="text-custom-blue fw-600 fs-14">Learn about the minimum requirement</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="widget-box pb-0 border-0">
                                        <h4 class="text-custom-black">Have Questions?</h4>
                                        <div class="content-wrap">
                                            <div class="text-wraper mb-xl-20">
                                                <label class="fw-600 fs-14 text-custom-black d-block">Call us and we'll be happy to help.</label>
                                                <a routerLink="/loan-dashboard" class="fw-600 fs-14 text-custom-black">(+345 123 456 7890)</a>
                                            </div>
                                            <div class="text-wraper">
                                                <label class="fw-600 fs-14 text-custom-black d-block">9:00AM to 8:00PM Eastern Time </label>
                                                <a routerLink="/loan-dashboard" class="fw-600 fs-14 text-custom-black">Monday - Friday</a>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <div class="col-lg-8">
                                    <div class="tabs-sec">
                                        <ul class="custom nav nav-tabs mb-xl-30">
                                            <li class="nav-item">
                                                <a href="#dailyactivity" class="nav-link active" data-toggle="tab">Daily Activity</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="#daysummary" class="nav-link" data-toggle="tab">90-Day Summary</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" id="dailyactivity">
                                                <div class="sch-payment mb-xl-30">
                                                    <h4 class="text-custom-black">Scheduled Payments</h4>
                                                    <div class="sch-payment-box">
                                                        <a routerLink="/loan-dashboard" class="payment-activity-box border-0">
                                                            <div class="left-side">
                                                                <div class="date-box">
                                                                    <p class="month no-margin">Mar <span>05</span></p>
                                                                </div>
                                                                <div class="payment-type-box">
                                                                    <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                                    <p class="no-margin fw-600">Loan balance</p>
                                                                </div>
                                                            </div>
                                                            <div class="right-side">
                                                                <div class="price-box">
                                                                    <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                                    <p class="no-margin text-light-grey">2 Completed</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="recent-activity">
                                                    <h4 class="text-custom-black">Recent Activity</h4>
                                                </div>

                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>04</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>03</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>06</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>10</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div class="all-act">
                                                    <a routerLink="/loan-dashboard" class="text-right fw-600">View all activity</a>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="daysummary">
                                                <div class="sch-payment mb-xl-30">
                                                    <h4 class="text-custom-black">Scheduled Payments</h4>
                                                    <div class="sch-payment-box">
                                                        <a routerLink="/loan-dashboard" class="payment-activity-box border-0">
                                                            <div class="left-side">
                                                                <div class="date-box">
                                                                    <p class="month no-margin">Mar <span>05</span></p>
                                                                </div>
                                                                <div class="payment-type-box">
                                                                    <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                                    <p class="no-margin fw-600">Loan balance</p>
                                                                </div>
                                                            </div>
                                                            <div class="right-side">
                                                                <div class="price-box">
                                                                    <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                                    <p class="no-margin text-light-grey">1 Completed</p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="recent-activity">
                                                    <h4 class="text-custom-black">Recent Activity</h4>
                                                </div>

                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a routerLink="/loan-dashboard" class="payment-activity-box">
                                                    <div class="left-side">
                                                        <div class="date-box">
                                                            <p class="month no-margin">Mar <span>05</span></p>
                                                        </div>
                                                        <div class="payment-type-box">
                                                            <p class="text-custom-blue mb-1 fw-600">Auto-repayment</p>
                                                            <p class="no-margin fw-600">Loan balance</p>
                                                        </div>
                                                    </div>
                                                    <div class="right-side">
                                                        <div class="price-box">
                                                            <p class="mb-1 fw-600 text-custom-black">$ 1,257.00</p>
                                                            <p class="no-margin text-light-grey">1 Completed</p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div class="all-act">
                                                    <a routerLink="/loan-dashboard" class="text-right fw-600">View all activity</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End User Dashboard -->
        <app-footer></app-footer>