<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Service Detail</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Service Detail</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Service -->
        <section class="section-padding bg-gray service-page">
            <div class="container">
                <div class="row">
                    <aside class="col-lg-4">
                        <div class="sidebar_wrap mb-md-80">
                            <div class="sidebar">
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Search</h5>
                                    </div>
                                    <form>
                                        <div class="form-group mb-0">
                                            <div class="search p-relative">
                                                <input type="text" name="#" class="form-control form-control-custom" placeholder="Title or keywords">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600"> LOANLY Services</h5>
                                    </div>
                                    <ul class="custom sidebar-services">
                                        <li class="active"> <a routerLink="/service-detail" class="text-custom-black fs-14">PERSONAL LOAN <span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Business Loan<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Real State LOANLY<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">CORPORATE LOAN<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Educational LOANLY<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Car Loan <span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Personal Injuery<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Divorce LOANLY<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Opening Hours</h5>
                                    </div>
                                    <table class="table text-custom-black">
                                        <tbody>
                                            <tr>
                                                <td>Mon – Wed</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 7:00 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Thursday</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 6:30 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Friday</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 6:00 PM</td>
                                            </tr>
                                            <tr class="last-tr">
                                                <td>Sun - Sun</td>
                                                <td>-</td>
                                                <td class="text-right">CLOSED</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="sidebar_widgets">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Recent News</h5>
                                    </div>
                                    <ul class="custom popular_post">
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog2.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog3.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <div class="col-lg-8">
                        <div class="col-12">
                            <div class="service-detail-sec bg-custom-white bx-wrapper">
                                <div class="services-detail-slider mb-xl-30">
                                    <div class="service-image">
                                        <img src="assets/images/services.jpg" class="image-fit" alt="img">
                                        <div class="overlay overlay-bg-black"></div>
                                        <div class="service-text-wrapper transform-center">
                                            <h3 class="text-custom-white">Choosing right education loan</h3>
                                            <a routerLink="/finance" class="btn-first btn-submit">Make Apply Today</a>
                                        </div>
                                    </div>
                                    <div class="service-image">
                                        <img src="assets/images/services1.jpg" class="image-fit" alt="img">
                                        <div class="overlay overlay-bg-black"></div>
                                        <div class="service-text-wrapper transform-center">
                                            <h3 class="text-custom-white">Choosing right education loan</h3>
                                            <a routerLink="/finance" class="btn-first btn-submit">Make Apply Today</a>
                                        </div>
                                    </div>
                                    <div class="service-image">
                                        <img src="assets/images/services2.jpg" class="image-fit" alt="img">
                                        <div class="overlay overlay-bg-black"></div>
                                        <div class="service-text-wrapper transform-center">
                                            <h3 class="text-custom-white">Choosing right education loan</h3>
                                            <a routerLink="/finance" class="btn-first btn-submit">Make Apply Today</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="service-meta mb-xl-30">
                                    <h2 class="text-custom-black fw-600">LOAN FIRM DEVOTED TO OUR CLIENTS</h2>
                                    <p class="text-light-white">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque.</p>
                                    <p class="text-light-white">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque.</p>
                                    <h4 class="text-custom-black fw-600">What we do ?.</h4>
                                    <p class="text-light-white">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque.</p>
                                    <h4 class="text-custom-black fw-600">What the procedure ?.</h4>
                                    <p class="text-light-white no-margin">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nulla recusandae quia nemo possimus soluta dignissimos provident ipsum tenetur non ratione incidunt, debitis commodi similique maxime unde fugiat ab? Odio, eaque.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Service -->
         <app-footer></app-footer>