<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Service</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Service</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Service -->
        <section class="section-padding bg-gray service-page">
            <div class="container">
                <div class="row">
                    <aside class="col-lg-4">
                        <div class="sidebar_wrap mb-md-80">
                            <div class="sidebar">
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Search</h5>
                                    </div>
                                    <form>
                                        <div class="form-group mb-0">
                                            <div class="search p-relative">
                                                <input type="text" name="#" class="form-control form-control-custom" placeholder="Title or keywords">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600"> LOANLY Services</h5>
                                    </div>
                                    <ul class="custom sidebar-services">
                                        <li class="active"> <a routerLink="/service-detail" class="text-custom-black fs-14">PERSONAL LOAN <span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Business Loan<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Real State LOANLY<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">CORPORATE LOAN<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Education LOAN<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Car Loan <span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Personal Injuery<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                        <li> <a routerLink="/service-detail" class="text-custom-black fs-14">Divorce LOANLY<span><i class="fas fa-chevron-right"></i></span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Opening Hours</h5>
                                    </div>
                                    <table class="table text-custom-black">
                                        <tbody>
                                            <tr>
                                                <td>Mon – Wed</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 7:00 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Thursday</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 6:30 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Friday</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 6:00 PM</td>
                                            </tr>
                                            <tr class="last-tr">
                                                <td>Sun - Sun</td>
                                                <td>-</td>
                                                <td class="text-right">CLOSED</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="sidebar_widgets">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Recent News</h5>
                                    </div>
                                    <ul class="custom popular_post">
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog2.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog3.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <div class="col-lg-8">
                        <div class="col-12">
                            <div class="service-detail-sec bg-custom-white bx-wrapper">
                                <div class="service-image mb-xl-30">
                                    <img src="assets/images/services1.jpg" class="image-fit" alt="img">
                                    <div class="overlay overlay-bg-black"></div>
                                    <div class="service-text-wrapper transform-center">
                                        <h3 class="text-custom-white">Social Media Posting Leads To Defense Verdict</h3>
                                        <a routerLink="/finance" class="btn-first btn-submit">Make Apply Today</a>
                                    </div>
                                </div>
                                <div class="service-meta mb-xl-40">
                                    <h2 class="text-custom-black fw-600">LOAN FIRM DEVOTED TO OUR CLIENTS</h2>
                                    <p class="text-light-white no-margin">Phasellus velit risus, euismod a lacus et, mattis condimentum augue. Vivamus fermentum ex quis imperdiet sodales. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque fermentum massa vel enim</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-sm-6">
                                    <div class="main-services-box p-relative mb-xl-30">
                                        <div class="main-service-wrapper padding-20">
                                            <div class="icon-box"> <i class="flaticon-kidnapping"></i>
                                            </div>
                                            <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Car Loan</a></h5>
                                            <p class="text-light-white no-margin">Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="main-services-box p-relative mb-xl-30">
                                        <div class="main-service-wrapper padding-20">
                                            <div class="icon-box"> <i class="flaticon-book"></i>
                                            </div>
                                            <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Education &nbsp;Loan</a></h5>
                                            <p class="text-light-white no-margin">Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="main-services-box p-relative mb-xl-30">
                                        <div class="main-service-wrapper padding-20">
                                            <div class="icon-box"> <i class="flaticon-wounded"></i>
                                            </div>
                                            <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Personal Loan</a></h5>
                                            <p class="text-light-white no-margin">Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="main-services-box p-relative mb-xl-30">
                                        <div class="main-service-wrapper padding-20">
                                            <div class="icon-box"> <i class="flaticon-family"></i>
                                            </div>
                                            <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Home Loan</a></h5>
                                            <p class="text-light-white no-margin">Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="main-services-box p-relative mb-xl-30">
                                        <div class="main-service-wrapper padding-20">
                                            <div class="icon-box"> <i class="flaticon-hook"></i>
                                            </div>
                                            <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Real Estate Loan</a></h5>
                                            <p class="text-light-white no-margin">Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-6">
                                    <div class="main-services-box p-relative mb-xl-30">
                                        <div class="main-service-wrapper padding-20">
                                            <div class="icon-box"> <i class="flaticon-LOANLY"></i>
                                            </div>
                                            <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Corporate Loan</a></h5>
                                            <p class="text-light-white no-margin">Lorem ipsum dolor sit amet consecte adipiscing elit sed do eiusincidunt.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Service -->
        <!-- Start Apply Today/faqs -->
        <section class="book-appointment parallax section-padding" id="book-appointment">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="appointment-sec">
                            <div class="row no-gutters">
                                <div class="col-lg-6 ">
                                    <div class="padding-40 full-height bg-white">
                                        <div class="align-self-center">
                                            <div class="section-heading">
                                                <h3 class="text-custom-blue fw-600 ">Get Loan Adviser</h3>
                                            </div>
                                            <p class="text-dark">Call us today, leave a message, email or find your nearest office below and We are here for you 24 hours , 7 days a week.</p>
                                            <h6 class="fs-20 mt-4 text-dark">We are here for you
                        24 hours a day, 7 days a week</h6>
                                            <div class="LOANLY-address">
                                                <p class="text-dark "><span class="fw-600">Address</span> : Newyork Mcf-d233</p>
                                                <p class="text-dark "><span class="fw-600">Email</span> : info@gmail.com</p>
                                            </div>
                                            <button type="submit" class="btn-first btn-submit-fill"><i class="fas fa-phone mr-2 fs-16"></i><span class="fs-16">1800-000-2390</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 align-self-center">
                                    <div class="right-side full-height bg-border">
                                        <div class="section-heading text-center">
                                            <h3 class="text-white fw-600">Make An Apply Today</h3>
                                        </div>
                                        <form class="form-style-2 form-style-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend"><i class="fas fa-user"></i></span>
                                                            <input type="text" name="#" class="form-control" placeholder="Full Name">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend"><i class="fas fa-envelope"></i></span>
                                                            <input type="email" name="#" class="form-control" placeholder="Email Address">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <div class="input-group"> <span class="input-group-preappend"><i class="fas fa-phone-alt"></i></span>
                                                            <input type="text" name="#" class="form-control" placeholder="Phone Number">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <button type="submit" class="btn-first btn-submit full-width">Book Apply Today</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Apply Today/faqs -->
         <app-footer></app-footer>