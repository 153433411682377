<!-- Start Footer -->
<div id="fb-root"></div>

<footer class="bg-black section-padding footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="footer-box mb-md-80">
                    <div class="footer-heading">
                        <h4 class="text-custom-white no-margin">What's new</h4>
                    </div>
                    <ul class="custom links">
                        <li> <a routerLink="/i-bank-info" class="text-custom-white">I-Bank 4.0 CBS</a>
                        </li>
                        <li> <a routerLink="/mobile-app" class="text-custom-white">Mobile App</a>
                        </li>
                        <li> <a routerLink="/website-design" class="text-custom-white">Website Design</a>
                        </li>
                        <li> <a routerLink="/website-hosting" class="text-custom-white">Domain & Hosting</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">Become a partner</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">Find a partner</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-box mb-md-80">
                    <div class="footer-heading">
                        <h4 class="text-custom-white no-margin">Comapany</h4>
                    </div>
                    <ul class="custom links">
                        <li> <a routerLink="/internship" class="text-custom-white">Internship</a>
                        </li>
                        <li> <a routerLink="/about-us" class="text-custom-white">About Ocean</a>
                        </li>
                        <li> <a routerLink="/news" class="text-custom-white">Company news</a>
                        </li>
                        <li> <a routerLink="/legal-dis" class="text-custom-white">Privacy at Ocean</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">Investors</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">Security</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-box mb-md-80">
                    <div class="footer-heading">
                        <h4 class="text-custom-white no-margin">Ocean</h4>
                    </div>
                    <ul class="custom links">
                        <li> <a routerLink="/about-us" class="text-custom-white">Customer Login</a>
                        </li>
                        <li> <a routerLink="/service" class="text-custom-white">I-Bank 4.0 Login</a>
                        </li>
                        <li> <a routerLink="/covid19" class="text-custom-white">Covid 19</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">Order Tracking</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">Returns</a>
                        </li>
                        <li> <a routerLink="/" class="text-custom-white">24*7 support with conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="footer-box">
                    <div class="fb-page" data-href="https://www.facebook.com/Oceansofttech.net/" data-tabs="timeline" data-width="" data-height="300px" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Oceansofttech.net/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Oceansofttech.net/">Ocean Software Technologies</a></blockquote></div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="payment-logo mb-md-20"> 
                    <span class="text-custom-white fs-14 mr-3">
                        <a routerLink="/contactus" class="text-custom-white">Ocean Software Technologies</a> &nbsp; 9423685852 / 0712-2715852 / 0712-2958754
                    </span>
                </div>
            </div>
            <div class="col-lg-6">
                <p class="text-custom-white no-margin">
                <a routerLink="/legal-dis" class="text-custom-white">Legal Disclaimer</a> | 
                <a routerLink="/data-protection" class="text-custom-white">Data Protection Policy</a> |
                <a routerLink="/contactus" class="text-custom-white">Sitemap</a> |
                </p>
                
            </div>
        </div>
    </div>
</div>
<!-- End Footer -->
<!-- back to top -->
<div id="back-top" class="back-top"> <a href="#top"><i class="flaticon-up-arrow"></i></a>
</div>
