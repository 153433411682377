<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 style="color:white">Micro Finance, Nidhi Finance Company, Non Banking Finance Company</h2>
                        <h4 style="color:white">High Performance, Low Cost, Starting @ ₹19,900/Year/Branch</h4>
                        <h6 style="color:white">"I-Bank 4.0" CBS is free for New Nidhi Finance Company till <b>"first 1000 Accounts or first 5000 Transactions or 6<sup>st</sup> Months"</b></h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">I-Bank 4.0 CBS SaaS provider with 99% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Json, Javascript, HTML, ASP.NET 4.5 & SqlServer 2012 R2</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Security & Authentication</h6>
                                            <p class="text-custom-white">Static IP & Two-Step Authentication</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Micro Finance, Nidhi Finance Company, Non Banking Finance Company</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
 <!-- Start About Us Intro -->
 <section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta">
                    <h4><a routerLink="#" class="text-custom-black fw-600">Micro Finance, Nidhi Finance Company, Non Banking Finance Company (NBFC)</a></h4>
                    <p class="text-light-white" style="text-align: justify;">“Microcredit, Microfinance, Nidhi Company & NBFC are banking the unbankables, bringing credit, savings and other essential financial services within the reach of millions of people who are too poor to be served by regular banks, in most cases because they are unable to offer sufficient collateral. In general, banks are for people with money, not for people without.” Microcredit belongs to the group of financial service innovations under the term of microfinance, other services according to microfinance is microsavings, microloans, micro vehicle finance and small housing loan.</p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<section class="our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta" style="text-align: center;">
                    <h4><a routerLink="#" class="text-custom-black fw-600">Successfully implemented Nidhi Company & Micro-Finance projects</a></h4>
                    <p class="text-light-white" style="text-align: justify;">With new business designs, the Nidhi Company & Micro-Finance bring fresh impetus to the financial services sector and compete strongly with traditional banks. But these sectors also need expertise, know-how and digital solutions to position themselves in the marketplace. See for yourself which Nadhi companies & Micro-Finance are already active in the market today with solutions from Ocean Software Technologies.</p>
                </div>
            </div>
        </article>
       </div>
</section>
<section class="main-services p-relative section-padding" >
    <div class="side-lines"> <span class="box"></span>
        <span class="text">NBFC</span>
        <span class="line"></span>
    </div>
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">A Complete Nidhi & Mini-Banking Software Solution</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-bounding-box"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">RD Management</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            The premium RD option allows you to maintain RD Accounts include daily, weekly, monthly deposit scheme and plans in just few clicks in I-Bank 4.0.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-bounding-box"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">FD Management</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            FD (Fix Deposit) is imp for nidhi company, Software manage 6 types of FD like Daily Int. FD, Monthly Int. FD, Quaterly Int. FD, & MIS plans. FD certificate printing.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-bounding-box"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Loan Management</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Business Loan, Personal Loan, Unsecured Loan, Secured Loan, Gold Loan, Vehicle Loan, Group Loan, Loan on Deposits. Reducing & Flat Interest Calculation.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xs-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-people"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">User Management</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            User effective program that lets you to keep eye on every transaction from anywhere anytime. Separate login section for Operator, Passing office, & Branch Manager.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20" style="text-align: justify;">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Share Accounts</a></h5>
                        <p class="text-light-white no-margin">In the <b>Share account section</b>, we have three types Regular Share, Nominal Share, & Associates Share. Share accounts are permanent members of society and it is used for loan guarantee & director creation. Cooperative society is a financial entity that belongs to its Share members, who are at the same time the owners and the customers of their Society.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Deposit Accounts</a></h5>
                        <p style="text-align: justify;" class="text-light-white no-margin">In <b>Deposit account section</b> of the software we have, all types of deposit account create & manage (Saving, Current, Fixed Deposit FD, Recurring Deposit RD, Pigmy/Daily Deposit, Locker & ETC). A single form is used for creating & editing deposit account. In a deposit account, you can add Agent & director reference when an account is created.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Loan Accounts</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">In the <b>Loan account section</b> of the software we have, all types of loan account create & manage (Regular Loan, Emergency Loan, Housing Loan, Vehicle Loan, CC Loan, deposit Loan & Etc). A single form is used for creating & editing a loan account. You can add photos of all loan documents in the loan account, link the deposit when the account is created.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">General Accounts</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            In <b>General Accounts section</b> of the software we have, all types of general accounts create & manage (Agent Suspense, TDS, Bank Account, Employees Salary Account, & other suspense accounts). A single form is used for creating & editing a general account. The general account section is used for creating an account which is not managed in other section.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
    <section class="section-padding about-us-sec p-relative">
        <div class="side-lines right-side"> <span class="box"></span>
            <span class="text">Clients</span>
            <span class="line"></span>
        </div>
        <h3 class="text-custom-black fw-700" style="text-align:center;">NBFC, Nidhi Company, Microcredit, & Microfinance</h3>
        <div class="container">
            <div class="row no-gutters">
                <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                    <div class="about-left-side-1">
                        <div class="section-header">
                            <div class="section-heading">
                                <h4 class=" fw-700 text-dark">NBFC & Nidhi Company</h4>
                            </div>
                        </div>
                        <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                            <b>
                            KY Gold Finance<br>
                            <small>16 Branches, H/O Butibori</small><br>
                            NPA Finance Services<br>
                            <small>Nagpur</small><br>
                            Bhavishyam Nidhi Pvt. Ltd.<br>
                            <small>1 Branch Nagpur</small><br>
                            Vidharbh Nidhi Pvt. Ltd.<br>
                            <small>1 Branch Ghugus, Chandrapur</small><br>
                            Nanded Nidhi Company Pvt. Ltd.<br>
                            <small>1 Branch Bokar</small><br>
                            Sanjana Nidhi Pvt. Ltd.<br>
                            <small>1 Branch Raipur</small> </b>
                        </p>
                        <a routerLink="/i-bank-price" class="btn-first btn-submit-fill fw-600 mb-xl-20"><i class="fas fa-rupee-sign mr-2 fs-16"></i>Nidhi Software Price</a>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="full-height">
                        <img src="assets/images/Sanjana.jpg" style="display: block; margin: auto; width: 90%;" alt="Vidharbh Nidhi Pvt. Ltd.">
                        <img src="assets/images/clients/ManaswiNidhi.jpg" style="display: block; margin: auto; width: 90%;" alt="Utthan Credit Cooperative Society">
                    </div>
                </div>
            </div>
        </div>
    </section>
<app-footer></app-footer>