import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.css']
})
export class OurTeamComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Team of Ocean Softtech");
    this.metaService.addTags([
      {name: 'keywords', content: 'Team of Ocean Software Technologies,Team of Ocean Softtech,Ocean team members'},
      {name: 'description', content: 'Ocean Software Technologies Team, Ocean Team Members'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

}
