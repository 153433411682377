import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-i-bank-info',
  templateUrl: './i-bank-info.component.html',
  styleUrls: ['./i-bank-info.component.css']
})
export class IBankInfoComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta ) { }

  ngOnInit(): void {
    this.titleService.setTitle("I-Bank 4.0 CBS Product Infomation");
    this.metaService.addTags([
      {name: 'keywords', content: 'Free Download CBS, Credit Cooperative Society free Software, Bank free Software,Multi-Language Support,Product features of I-Bank 4.0,free Banking Credit Cooperative Society Software in Hindi Marathi & English,Technical features of I-Bank 4.0,Architecture of I-Bank 4.0 (CBS),Top 5 Features of I-Bank 4.0 (CBS)'},
      {name: 'description', content: 'I-Bank 4.0 CBS Core Banking System Software Top Features of I-Bank 4.0 (CBS)'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

}
