<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">About-Us</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">About Us</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h2 style="font-size:xx-large; font-weight: bolder; color: #333;">Welcome To Ocean Software Technologies</h2>
                            <p class="text-light-white">Ocean Software Technologies is one of the leading Software Technologies in Banking & Finance. Based in Nagpur, India, we have development centres in Nagpur. We provide Banking & Finance and other software applications.</p>
                            <p class="text-light-white">The company formed in early 31 Dec 2008 by the current CEO Mr. Yogesh Kedar with the aim of crafting experiences, services, and products that improve people’s lives. We are extremely passionate and devoted to our craft.</p>
                            <p class="text-light-white">We always hone our skills for making digital experiences and meaningful client engagements. Our vast industry and technology proficiency let us to team-up with customers for delivering refined solutions on budget. For our company Ocean Software Technologies, it’s not just about delivering sophisticated IT business solutions, but also about playing role in industry effort to shape up a smarter and brighter future.</p>
<hr>
                            <h5 class="text-custom-black fw-600">Our Vision</h5>
                            <p class="text-light-white">To be one of the most valued Software Development Company in India giving state of the art center of excellence, ROI-Oriented solutions and services with the limelight of industry experts and promising technologies.</p>
                            <!--<blockquote class="mb-xl-20">
                                <p class="text-custom-black fw-600">@Sometimes by accident, sometimes on purpose with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page maker including versions of lorem ipsum. Sometimes by accident, sometimes on purpose."</p>
                            </blockquote>-->
                            <h5 class="text-custom-black fw-600">Our Mission</h5>
                            <p class="text-light-white" style="text-align: justify;">The mission of Ocean Software Technologies - Software Design and Development Company is to create innovative products and deliver unique services which process quality and customer satisfaction to add value to your business. Our dedicated team especially focus on actual needs and requirements of the client to create strong and long-term relations. With the aim to meet the clients demands, our teamwork to bring successful growth for various businesses.<br><strong>We focus on your needs and plan and develop your digital bank with you to position you as a key player in the marketplace.
                            </strong></p>
                        </div>
                    </div>
                </article>
                <!-- article -->
                <hr>
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-black fw-700">Advantages of Ocean SoftTech</h3>
						<div class="section-description">
							<p style="text-align: justify;" class="text-light-white">
                                Ocean Software Technologies is a one-stop Software Development Company in Nagpur with deep roots in ctting age technology and open-source communities. We are specialist in area like "Banking, Finance, Web Development, Mobile App & Windows Application".
                            </p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 no-padding">
						<div class="blog-slider">
							<article class="post col-12">
								<div class="single-blog">
									<div class="blog-photo">
										<img src="assets/images/1000Clients.png" style="height: 233px;" alt="#">
									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">Ocean - The Brand</h2>
										<p class="text-light-white" style="text-align: justify;">
                                            After 15+ years of experience, Ocean Software Technologies is established as a good technologies brand.    
                                        </p>
										<div class="post-author">
                                            <a routerLink="/what-ocean-do" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
							<article class="post col-12">
								<div class="single-blog">
									<div class="blog-photo">
										<img src="assets/images/Teams.jpg" class="full-width" alt="#">
									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">Ocean Team</h2>
										<p class="text-light-white" style="text-align: justify;">
                                            We are a disciplinary team of designers, software developers, administrators...
                                        </p>
										<div class="post-author"> 
                                            <a routerLink="#" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
							<article class="post col-12">
								<div class="single-blog">
									<div class="blog-photo">
										<img src="assets/images/OURCust.jpg" class="full-width" alt="#">
									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">Our Customers</h2>
										<p class="text-light-white" style="text-align: justify;">
                                            Our mission is to deliver outstanding results for our clients.
                                        </p>
										<div class="post-author">
                                            <a routerLink="#" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
							<article class="post col-12">
								<div class="single-blog">
									<div class="blog-photo">
										<img src="assets/images/AboutUs-I-Bank-Story.png" class="full-width" alt="#">
									</div>
									<div class="post-contnet">
										<h2 class="post-title text-custom-black">Story of I-Bank 4.0</h2>
										<p class="text-light-white">We start this dream project in Dec 2012 as "SocietyOnline" for Cooperative Society</p>
                                        <div class="post-author">
                                            <a routerLink="/i-bank-info" fragment="Journey" class="fw-500 fs-14">Read More</a>
										</div>
									</div>
								</div>
							</article>
						</div>
					</div>
				</div>
			</div>
		</section>
        <!-- End About Us Intro -->
        <!-- start aboutus -->
        <section class="section-padding about-us-sec p-relative">
            <div class="side-lines"> <span class="box"></span>
                <span class="text" id="Yogesh">Yogesh</span>
                <span class="line"></span>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 align-self-center">
                        <div class="about-left-side mb-md-40 ">
                            <h3 class="text-custom-black fw-700">Leadership</h3>
                            <p class="text-light-white fs-14 mb-xl-20"><b>Mr. Yogesh A. Kedar</b><br> CEO & Founder</p>
                            <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                                Yogesh's passion for Speed, off-roading, technology, innovation, database design, data encryption, and from FoxPro to VB.Net & Angular 14 has made him the inspiration behind making this company the synonym of ‘Innovation’ and ‘Tech Expertise’. Yogesh is the Founder and CEO of Ocean Software Technologies and he established this company in 2008. He has completed his Diploma in Computer Engineering from the recognized late Vasantdada Polytechnic Nagpur. He possesses excellent management skills with a futuristic view with vast experience working with a few esteemed companies and developing complex software solutions, desktop applications, and mobile & web app development.</p>
                            <div class="about-list">
                                <ul>
                                    <li class="text-light-white fs-14"><i class="fas fa-check"></i> 15+ years of experience in Software.</li><br>
                                    <li class="text-light-white fs-14"><i class="fas fa-check"></i> 10+ years of experience in Fintech</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-right-side-img p-relative">
                            <div class="first-img p-relative">
                                <img src="assets/images/aboutus.jpg" class="img-fluid full-width" alt="about us">
                                <div class="transform-center">
                                    <div class="text">
                                        <h2 class="text-custom-black no-margin">15+</h2>
                                        <div class="side-text">
                                            <p>Years of
                                                <br>Programming Experience</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-light-white-skew-2 bg-custom-black"></div>
        </section>
        <!-- end aboutus -->
        <!-- About One section-->
        <section class="section-padding about-us-sec p-relative">
            <div class="side-lines right-side"> <span class="box"></span>
                <span class="text">Ocean</span>
                <span class="line"></span>
            </div>
            <!--<div class="container">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                        <div class="about-left-side-1">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h3 class=" fw-700 text-dark">OUR GRAGOUS HISTORY</h3>
                                </div>
                            </div>
                            <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                                One morning in 2000, I decided to create a car design company, but after reading articles on bill gate in Lokmat my vision is turned from mechanical to computer technology.
                                
                            </p>
                            <div class="signature-sec">
                                <div class="signature-right">
                                    <h5 class="text-custom-black fw-600">Yogesh A. Kedar</h5>
                                    <p class="text-light-white no-margin">Founder of OCEAN</p>
                                </div>
                                <div class="signature-left">
                                     <img src="https://via.placeholder.com/200X100" alt="signature">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height">
                            <img src="assets/images/about-4.jpg" class="img-fluid image-fit" alt="about us">
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="container about-sec-second">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height ">
                            <img src="assets/images/Statistics.jpg" class="img-fluid image-fit" alt="about">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg">
                        <div class="about-left-side-1 p-relative">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h3 class=" fw-700 text-dark">Statistics</h3>
                                </div>
                            </div>
                            <div class="about-desc">
                                <p class="text-light-white mb-xl-20">Ocean SoftTech Statistics on basis of clients' response.
                                </p>
                            </div>
                            <div class="company-progress">
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Database <span>99%</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Success <span>95%</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Happy Customers<span>98%</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Staff Size<span>27</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--About-two-section-end-->
        <!-- Start About -->
        <section class="section-padding bg-gray about-story ">
            <div class="container">
                <div>
                    <div class="section-heading">
                        <h3 class="text-custom-black fw-700">Ocean Software Technologies wasn't built in a day.</h3>
                        <div class="section-description">
                            <p class="text-light-white">
                                An amazing journey of Ocean Software Technologies.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="tabs square-tabs">
                            <ul class="nav nav-tabs custom p-relative mb-xl-30">
                                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#start">2008</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#setup">2010</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#Pench">2011</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#I-Bank">2012</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#I-BankCBS">2015-16</a>
                                </li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#advance">2020-22</a>
                                </li>
                            </ul>
                            <div class="tab-content padding-20 bg-custom-white bx-wrapper">
                                <div class="tab-pane fade show active" id="start">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/2011-09-28.jpg" class="full-width" alt="img">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600">Beginning</h4>
                                                    <p class="text-light-blue">
                                                         Ocean Software Technologies was born on 31 December 2008. It begins as a Website development company.
                                                    </p>
                                                    Our Action  <p class="text-light-white">
                                                    Like Apple, Microsoft & other software giants Ocean Start working in the shop of 10*16 feet with 2 old desktops and some group of friends, we start a new startup which provides customer new services like a website, Web Applications & some offline software.
                                                    </p>
                                                    <p class="text-light-white">
                                                        This shop is still Ocean Software Technologies office.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="setup">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/about2.jpg" class="full-width" alt="img">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600">First Major Work</h4>
                                                    <p class="text-light-blue">
                                                        www.halbashadi.com marriage portal was one of the biggest client of ocean software technologies at that time. We use all the latest technologies and work techniques. We work with halbashai.com for more than 5 years and fulfill all requirements.
                                                    </p>
                                                    <p class="text-light-white">
                                                        Starting from 150+ profiles the web portal manages more than 10000+ profiles of bride & groom.
                                                    </p>
                                                    <p class="text-light-white">Technologies Use in Project</p>
                                                    <div class="list">
                                                        <ul class="custom">
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">ASP.NET</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">C#, JavaScript</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">MSSQL 2008 Express</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">.Net 3.0</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Pench">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/clients/Pench.png" class="full-width" alt="img">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600">First Government Web Application Project</h4>
                                                    <p class="text-light-blue">
                                                        Pench Tiger Reserve (PTR) Maharashtra is our first government project in this, we develop a website of the Pench Tiger Reserve for the first time. Mr. Uttam Sawant DFO at PTR now Dept. The director in Sahyadri Tiger Reserve gives his full dedication to developing a website.<br><br>
                                                        <a href="https://www.mahapenchtiger.com" target="_blank">Website</a>
                                                    </p>
                                                    <p class="text-light-white">
                                                        
                                                    </p>
                                                    <p class="text-light-white">Technologies Use in Project</p>
                                                    <div class="list">
                                                        <ul class="custom">
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">ASP.NET</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">C#, JavaScript, JSon.</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">MSSQL 2008 Express</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">.Net 3.0</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="I-Bank">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/IBankImg/DashSocietyOnline.jpg" class="full-width" alt="img">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600">Enter In Banking Sector</h4>
                                                    <p class="text-light-blue">
                                                        When some of the directors of Ocean Software Technologies register the Credit Cooperative Society, Ocean SoftTech Works with one offline Company to develop online software, due to a lack of knowledge of the online systems, Ocean SoftTech moves and develops new software for Society. Nowadays it is called "I-Bank 4.0 CBS".<br><br>
                                                        <a href="https://www.oceansofttech.net/i-bank-info" target="_blank">More Info</a>
                                                    </p>
                                                    <p class="text-light-white">
                                                        
                                                    </p>
                                                    <p class="text-light-white">Technologies Use in Project</p>
                                                    <div class="list">
                                                        <ul class="custom">
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">ASP.NET</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">C#, JavaScript, JSon.</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">MSSQL 2019 Express</span>
                                                            </li>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">.Net 4.0</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="I-BankCBS">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/IBankImg/I-Bank-DashBoard.jpg" class="full-width" alt="img"><br>
                                                    
                                                    <img src="assets/images/2016-05-15.jpg" class="full-width" alt="img"><br>
                                                    <b>Team members of Ocean SoftTech that build I-Bank 4.0 Core Banking System</b>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600">Rise of I-Bank 4.0 CBS Core Banking System</h4>
                                                    <p class="text-light-blue" style="text-align:justify;">Before I-Bank 4.0 CBS there is "SoiectyOnline" Web-based software before "I-Bank 4.0 CBS" there is "SoiectyOnline" Web-based software used by our clients. "Society-Online" is not Core Banking Software it is just an online copy of an offline system, with a single database for all branches, CBS transaction & functionality is not available.</p>
                                                    <p class="text-light-white" style="text-align:justify;">In Aug 2016 we convert all our existing 6 clients From "SocietyOnline" To "I-Bank 1.0" to give a new version to our project i-e "I-Bank 4.0". On 10 Oct 2016 we online our new client (Geeta Krishna Credit Cooperative Society, Nagpur) on "I-Bank 4.0" with data conversion of all (Members, Accounts, GL & All Transactions) of 5-year-old Tapi software on their 4 branches within 2 days.</p>
                                                    <div class="list">
                                                        <ul class="custom">
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">Credit Cooperative Society</span>
                                                            </li><br>
                                                            <li class="fs-14 text-light-blue"> <i class="fas fa-check"></i>
                                                                <span class="text-light-white">Multi-State Cooperative Society</span>
                                                            </li>
                                                        </ul>
                                                    </div><a routerLink="/i-bank-info" class="btn-first btn-submit text-light-blue">Learn More</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="advance">
                                    <div class="tab-inner">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="img-sec mb-md-40">
                                                    <img src="assets/images/banner/DC1.jpg" class="full-width" alt="img">
                                                </div>
                                            </div>
                                            <div class="col-lg-6 align-self-center">
                                                <div class="content-box">
                                                    <h4 class="text-custom-black fw-600">Co-location Datacenter At Nagpur & Bangalore</h4>
                                                    <p class="text-light-blue">Ocean Datacenters was established in March 2020</p>
                                                    <p class="text-light-white">Depending on other data hosting services companies since 2007, the major issue is we depend upon US or Singapore data centers, which have time & priority issues.</p>
                                                    <p class="text-light-white">For projects like the banking & finance industry, we are not depending on other data hosting company so in 2019 board decided that our own data center be built, and we start our data center within a year, it is helpful to handle the covid-19 situation.</p>
                                                    <p class="text-light-white">we got 3 VPS orders in covid-19 that we are not expected. After 2020 we focus on data hosting as a service offered by Ocean Software Technologies.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End About -->
        <!--LOANLY-advertisement-->
        <section class="section-padding LOANLY-advertisement parallax">
            <div class="row">
                <div class="col-12">
                    <div class="advertisement-text">
                        <p class=" text-white fs-20">Fast Approval - Less Fee</p>
                        <h3 class="text-white">We are with you every step .</h3>
                        <a routerLink="/service" class="btn-first btn-submit fw-600 mb-xs-20">Our Services</a>
                        <a routerLink="/contactus" class="btn-first btn-submit-fill fw-600 ml-2 mb-xs-20">Start Now</a>
                    </div>
                </div>
            </div>
        </section>
        <!-- LOANLY-advertisement end -->
        <section class="section-padding bg-gradient doctors-team-style-2">
			<div class="container">
				<div class="section-header">
					<div class="section-heading">
						<h3 class="text-custom-white">Our Teams</h3>
						<div class="section-description">
							<p class="text-custom-white" style="text-align: justify;" #OurTeams>
                                We are a disciplinary team of designers, software developers, system administrators, testers, and database developers specializing in Web designing, Core Banking Software & Data Center. Our software is created and characterized by extreme usability & arresting aesthetics and Our deliverables are categorized along those same lines. While delivering software we ensure top quality to clients. As far as timelines are concerned, we have built a reputation for ourselves as a software Development house delivering projects on time.
                            </p>
						</div> 
					</div>
				</div>
				<div class="row">
					<div class="col-12 no-padding">
						<div class="doctors-slider">
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/doctors/doctor3.jpg" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a href="https://www.youtube.com/channel/UCd-sM2ur-X9Nvjs6TO7QWnA" target="_blank" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a href="https://www.linkedin.com/in/yogesh-kedar-65210734/" target="_blank" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a href="https://www.facebook.com/yogeshkedar/" target="_blank" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a href="https://twitter.com/yogeshkedar"target="_blank" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20">Yogesh A. Kedar</a></h4>
											<p class="designation text-light-white">CEO & Founder<br>9423685852</p>
										</div>
									</div>
								</div>
							</div>
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/doctors/doctor2.jpg" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a routerLink="/" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a routerLink="/" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a href="https://www.facebook.com/b.sachin162" target="_blank" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a routerLink="https://www.facebook.com/b.sachin162" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20">Sachin S. Bawane</a></h4>
											<p class="designation text-light-white">Director<br>+91 9766916371</p>
										</div>
									</div>
								</div>
							</div>
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/doctors/doctor3.jpg" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a routerLink="/" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a routerLink="/" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a routerLink="https://www.facebook.com/sagar.wakharia" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a routerLink="/" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20">Sagar Wakhariya</a></h4>
											<p class="designation text-light-white">Director<br>0712-2715852</p>
										</div>
									</div>
								</div>
							</div>
							<div class="slide-item col-12">
								<div class="team-block p-relative">
									<div class="inner-box">
										<div class="image animate-img">
											<img src="assets/images/doctors/doctor4.jpg" alt="img" class="full-width">
											<div class="overlay-box">
												<div class="overlay-inner p-relative full-height">
													<ul class="team-social-box custom">
														<li class="youtube">
															<a routerLink="/" class="fab fa-youtube fs-16"></a>
														</li>
														<li class="linkedin">
															<a routerLink="/" class="fab fa-linkedin fs-16"></a>
														</li>
														<li class="facebook">
															<a routerLink="/" class="fab fa-facebook-f fs-16"></a>
														</li>
														<li class="twitter">
															<a routerLink="/" class="fab fa-twitter fs-16"></a>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="lower-content p-relative text-center">
											<h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20">Sarita N. Warudkar</a></h4>
											<p class="designation text-light-white">Project Manager<br>0712-2715852</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Team Doctors -->
        <app-footer></app-footer>