import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-micro-finance-nidhi',
  templateUrl: './micro-finance-nidhi.component.html',
  styleUrls: ['./micro-finance-nidhi.component.css']
})
export class MicroFinanceNidhiComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Nidhi Finance System Software For Nidhi Pvt. Ltd. Company");
    this.metaService.addTags([
      {name: 'keywords', content: 'nidhi company software, Nidhi Software free, nidhi free Software, Nidhi, Nidhi Company,free Nidhi Finance Company in Hindi Marathi & English,Best Core Banking System,online Nidhi Finance Company software CBS free software, online Nidhi Finance Company CBS free Demo software'},
      {name: 'description', content: 'I-Bank 4.0 CBS Core Banking System Software For Credit Cooperative Society'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Ocean Software Technologies'}
    ]);
  }
}
