import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-internship',
  templateUrl: './internship.component.html',
  styleUrls: ['./internship.component.css']
})
export class InternshipComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("12 Months free internship opportunities at Ocean Software Technologies");
      this.metaService.addTags([
        {name: 'keywords', content: 'Career opportunities at Ocean Software Technologies,Angular developer, asp.net developer, .net Core developer, ionic developer, Sql developer, C# developer, .net core, 6 - 12 months free internship program for mca, bca, bsc, engineering students.,free internship nagpur,free internship'},
        {name: 'description', content: '12 Months free internship opportunities at Ocean Software Technologies Nagpur'},
        {name: 'robots', content: 'index, follow'},
        {name: 'writer', content: 'Yogesh Kedar'}
      ]);
  }
}
