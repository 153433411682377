import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-protection-policy',
  templateUrl: './data-protection-policy.component.html',
  styleUrls: ['./data-protection-policy.component.css']
})
export class DATAPROTECTIONPOLICYComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
