<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-server p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h3 class="text-custom-white lh-default fw-600">EXTREMELY POWER DEDICATED SERVERS</h3>
                        <h4>India's Most Trusted Datacenter</h4>
                        <h4>WINDOWS & LINUX DEDICATED SERVERS</h4>
                        <h4> High Performance, Low Cost Machines, Starting @ ₹7999/month</h4>
                        <h6 style="color: red;">VPS of 4 GB RAM & 50GB M.2 SSD Free for 12 months* New Startups</h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">server provider with 99.90% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Upgradation within minutes for all processors & servers.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Fastest Connectivity</h6>
                                            <p class="text-custom-white">100 GBPS connectivity with fast and Premium bandwidth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Dedicated Server</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<section class="section-padding our_articles">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">Enterprise-Grade Dedicated Server Hosting</h3>
                <div class="section-description">
                    <p class="text-light-white" style="text-align: justify;">Our Dedicated Server Hosting located at Nagpur & Bengaluru in Indian Datacenter is built for those who require complete control, superpower, and high-end security. <b>OceanSoftTech</b> has all high-end <b>M.2 SSD</b> dedicated servers hosting that are your ready solutions to any business critical challenge. These low-priced dedicated server hosting will protect you from noisy applications or websites. Unlike others, we maintain full transparency in our prices and do not apply any kind of hidden fees.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 no-padding">
                <div class="blog-slider" style="text-align: center;">
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-D8</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">7,999/- ₹ Per Month</b><br>
                                    <small>86,390/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>1,72,779/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    6 Cores<br><br>
                                    <b>8 GB DDR4</b><br><br>
                                    <b>480 GB M.2 SSD + 1 TB HDD</b><br><br>
                                    5 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-D16</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">12,222/- ₹ Per Month</b><br>
                                    <small>1,31,999/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>2,63,999/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    6 Cores<br><br>
                                    <b>16 GB DDR4</b><br><br>
                                    <b>480 GB M.2 SSD + 1 TB HDD</b><br><br>
                                    10 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <article class="post col-12">
                        <div class="single-blog">
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">OCN-D32</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">16666/- ₹ Per Month</b><br>
                                    <small>1,79,999/- ₹ 1 Year (10%* off)</small><br>
                                    <small><b>3,59,999/- ₹ 3 Year (40%* off)</b></small>
                                    <br><br>
                                    6 CoreS<br><br>
                                    <b>32 GB DDR4</b><br><br>
                                    <b>480 GB M.2 SSD + 1 TB HDD</b><br><br>
                                    10 TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    1 IP<br><br>
                                    6-48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <article class="post col-12">
                        <div class="single-blog">
                            
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">Custom Servers</h2>
                                <p class="text-light-white" style="text-align: center;">
                                    <b>Intel® CORE™ i5-9400 CPU @ 2.90GHz</b>
                                </p>
                                <hr>
                                <p class="text-light-white" style="text-align: center;">
                                    <b style="font-size:larger;">/- ₹ </b><br>
                                    <small>/- ₹</small><br>
                                    <small><b>/- ₹</b></small>
                                    <br><br>
                                    - Core<br><br>
                                    <b>- GB DDR4</b><br><br>
                                    <b>- GB HDD</b><br><br>
                                    - TB Bandwidth<br><br>
                                    CentOS 7 / Windows 2012*<br><br>
                                    - IP<br><br>
                                    <48 Hrs Delivery Time
                                </p>
                                <a routerLink="#" class="fw-500 fs-14">Read More</a>
                            </div>
                        </div>
                    </article>
                    <!--<article class="post col-12">
                        <div class="single-blog">
                            <div class="post-meta">
                                <div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 13, Nov</a>
                                </div>
                            </div>
                            <div class="blog-photo">
                                <img src="assets/images/blog/blog3.jpg" class="full-width" alt="#">

                            </div>
                            <div class="post-contnet">
                                <h2 class="post-title text-custom-black">How Is Mediation Useful in Home Loan Cases?</h2>
                                <p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
                                <div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Jhone Deo</a></span>
                                </div>
                            </div>
                            <div class="blog-overlay">
                                <div class="right-text-effect post-content">
                                    <h2 class="post-title"><a routerLink="/blog-detail" class="text-white">How Is Mediation Useful in Home Loan Cases?</a></h2>
                                    <p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
                                </div>
                                <div class="blog-read-more-icon">
                                    <div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Jhone Deo</a></span>
                                    </div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
                                </div>
                            </div>
                        </div>
                    </article>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->
<app-footer></app-footer>