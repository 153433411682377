import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contactocean',
  templateUrl: './contactocean.component.html',
  styleUrls: ['./contactocean.component.css']
})
export class ContactoceanComponent implements OnInit {
  Title: string = "Contact Us";
  ApiResult: any;
  emailId="yogeshk_123@yahoo.co.in";
  submitted = false;
  constructor(private fb: FormBuilder, private mainService: MainService,private titleService: Title, private metaService: Meta) { }
  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      Name: [''],
      Email: [''],
      phone_number: [''],
      Subject: [''],
      Message: [''],
    });
    this.titleService.setTitle("Contact Ocean Software Technologies");
    this.metaService.addTags([
      {name: 'keywords', content: 'Contact Ocean Software Technologies,about ocean Softtech, Bank free Software, Credit, Cooperative, Society,free Banking Credit Cooperative Society Software in Hindi Marathi & English,Best Core Banking System,online Credit Cooperative Society CBS free software, online Credit Cooperative Society CBS free Demo software'},
      {name: 'description', content: 'Ocean Software Technologies, About Ocean Software Technologies Nagpur'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

  get f() { return this.form.controls; }
  Email_send() {
    var name = this.form.get("Name").value;
    var Email = this.form.get("Email").value;
    var phone = this.form.get("phone_number").value;
    var Subject = this.form.get("Subject").value;
    var Message = this.form.get("Message").value;
    var EmailBodyMsg = "<table><thead><tr><th>Contact Form</th></tr></thead><tbody><tr><td>Name:</td><td>" + name + "</td></tr><tr><td>Phone-No:</td><td>" + phone + "</td></tr><tr><td>Email:</td><td>" + Email + "</td></tr><tr><td>Subject:</td><td>" + Subject + "</td></tr><tr><td colspan=2>Message:</td></tr><tr><td colspan=2>" + Message + "</td></tr></tbody></table>";
    var Moobj: any = {};
    Moobj.EmailSubject = "Oceansofttech.net Website Enquiry Email";
    Moobj.EmailTo = this.emailId;
    Moobj.PkSystemEmailSetting = 4;
    Moobj.message = EmailBodyMsg;
    console.log(Moobj)
    this.mainService.emailMethod(Moobj).subscribe(value => {
      this.ApiResult = value;
    });
    this.form.reset();
    alert("Mail send successfully");
  }
}