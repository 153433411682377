import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-server-vps',
  templateUrl: './server-vps.component.html',
  styleUrls: ['./server-vps.component.css']
})
export class ServerVpsComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("WINDOWS & LINUX VPS Free");
    this.metaService.addTags([
      {name: 'keywords', content: 'Free CentOS 7 VPS,Cost-effective VPS,VPS in 999 per month,Free VPS,Windows VPS,Linux VPS,Nagpur Data center,VPS in Nagpur, Free VPS in Nagpur, SSD VPS, Free SSD VPS, fast SSD VPS,4 GB Free VPS,'},
      {name: 'description', content: 'Free CentOS VPS By Ocean Software Technlogies'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }
}
