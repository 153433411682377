<div class="animated-banner">
<app-navbar></app-navbar>
<div class="sub-header p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h1 class="text-custom-white lh-default fw-600">Login</h1>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Login</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="login-section ptb-100">
    <div class="container">
        <div class="login-form">
            <div class="login-title">
                <h3>Welcome Back!</h3>
                <p>Please login to your account.</p>
            </div>
            <form [formGroup]="form">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" 
                              [formControl]="form.controls['email']">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <input type="password" class="form-control" placeholder="Password" 
                            [formControl]="form.controls['password']">
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="checkme">
                            <label class="form-check-label" for="checkme">Keep me Log In</label>
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <p class="forgot-password text-right"><a routerLink="#">Forgot Password?</a></p>
                    </div>

                    <div class="col-lg-12">
                        <div class="nav-btn">
                            <a (click)="login('f')" class="default-btn">
                                Log In Now
                                <span></span>
                            </a>
                        </div>
                        <br>
                        <span>Don't have account? <a routerLink="#">Signup!</a></span>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<app-footer></app-footer>
</div>