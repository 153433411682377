import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators ,FormControl} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthrizationService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
 
  @ViewChild('f',{static: false}) loginForm: NgForm;
  errors: string;
  private IsSuccess: boolean;
  submitted = false;
  constructor(private authservice:AuthrizationService, private router:Router,private fb:FormBuilder,private   toaster:ToastrService ) { }

  public form:FormGroup
  
  ngOnInit(): void {
    this.form = this.fb.group({
      email:['',[Validators.required,Validators.email]],
      password:['',[Validators.required,]]
     });
  }
  login(form)
  {
  this.authservice.login(this.form.value.email,this.form.value.password)
    .subscribe(
      result =>{
        //console.log(JSON.stringify(result))
        if(result.result==1){
          // this.toaster.success('login successful');
                this.router.navigate(['/dashboard']);
        }
    
        },
          error =>{
                   // alert('wrong credentials')
                    this.form.controls['email'].setErrors({ 'incorrect': true});
                    this.form.controls['Password'].setErrors({ 'incorrect': true});
      
     });
  }
  logout()
{
  this.authservice.logout();
  this.router.navigate(['/home'])
}
}
