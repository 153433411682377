<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">News</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">News</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Blog -->
        <section class="section-padding bg-gray our-articles">
            <div class="container">
                <div class="row">
                    <aside class="col-lg-4">
                        <div class="sidebar_wrap mb-md-80">
                            <div class="sidebar">
                               <!-- <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Categories</h5>
                                    </div>
                                    <ul class="categories custom">
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">All <span class="text-light-white">(19)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Personal Loan <span class="text-light-white">(1)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Educational LOANLY <span class="text-light-white">(3)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Business Loan <span class="text-light-white">(4)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Car Loan <span class="text-light-white">(2)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">CORPORATE LOAN <span class="text-light-white">(1)</span></a>
                                        </li>
                                    </ul>
                                </div> -->
                                <!-- <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Recent News</h5>
                                    </div>
                                    <ul class="custom popular_post">
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog2.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog3.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Opening Hours</h5>
                                    </div>
                                    <table class="table text-custom-black">
                                        <tbody>
                                            <tr>
                                                <td>Mon – Fri</td>
                                                <td>-</td>
                                                <td class="text-right">10:00 AM - 07:00 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Saturday</td>
                                                <td>-</td>
                                                <td class="text-right">10:00 AM - 01:00 PM</td>
                                            </tr>
                                            <tr class="last-tr">
                                                <td>Sunday</td>
                                                <td>-</td>
                                                <td class="text-right">CLOSED</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Imp No.</h5>
                                    </div>
                                    <ul class="custom popular_post">
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <i class="fa fa-cog fa-spin fa-4x fa-fw" style="color: #0070ba;"></i>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">Service<br>Hello Ocean</p>
                                                        </div>
                                                        <h6><a routerLink="" class="text-custom-black fs-16 fw-600">0712-2715852</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <div class="popular_post_img animate-img">
                                                            <i class="fa fa-laptop fa-4x" style="color: #0070ba;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">For Demo <br>Hello Yogesh</p>
                                                        </div>
                                                        <h6><a routerLink="" class="text-custom-black fs-16 fw-600">+91 9423685852</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <div class="popular_post_img animate-img">
                                                            <i class="fa fa-users fa-4x" style="color: #0070ba;"></i>
                                                        </div>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">HR & Admin <br>Hello Sachin</p>
                                                        </div>
                                                        <h6><a routerLink="" class="text-custom-black fs-16 fw-600">+91 9766916371</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Holiday's For Service Dept.</h5>
                                    </div>
                                    <table class="table text-custom-black">
                                        <tbody>
                                            <tr>
                                                <td>1<sup>st</sup> Jan</td>
                                                <td>-</td>
                                                <td class="text-right">New Year’s Day</td>
                                            </tr>
                                            <tr>
                                                <td>14<sup>th</sup> Jan</td>
                                                <td>-</td>
                                                <td class="text-right">Sankranti</td>
                                            </tr>
                                            <tr>
                                                <td>26<sup>th</sup> Jan</td>
                                                <td>-</td>
                                                <td class="text-right">Republic Day</td>
                                            </tr>
                                            <tr>
                                                <td>19<sup>th</sup> Feb</td>
                                                <td>-</td>
                                                <td class="text-right">Chhatrapati Shivaji Maharaj Jayanti</td>
                                            </tr>
                                            <tr>
                                                <td>8<sup>th</sup> Mar</td>
                                                <td>-</td>
                                                <td class="text-right">Maha Shivratri</td>
                                            </tr>
                                            <tr>
                                                <td>-</td>
                                                <td>-</td>
                                                <td class="text-right">Holi</td>
                                            </tr>
                                            <tr>
                                                <td><sup>th</sup> Mar</td>
                                                <td>-</td>
                                                <td class="text-right">Good Friday</td>
                                            </tr>
                                            <tr>
                                                <td>14<sup>th</sup> Apr</td>
                                                <td>-</td>
                                                <td class="text-right">Dr. Ambedkar Jayanti</td>
                                            </tr>
                                            <tr>
                                                <td>1<sup>st</sup> May</td>
                                                <td>-</td>
                                                <td class="text-right">May Day / Maharashtra Day</td>
                                            </tr>
                                            <tr>
                                                <td>15<sup>th</sup> Aug</td>
                                                <td>-</td>
                                                <td class="text-right">Independence Day</td>
                                            </tr>
                                            <tr>
                                                <td>--<sup>th</sup></td>
                                                <td>-</td>
                                                <td class="text-right">Raksha Bandhan </td>
                                            </tr>
                                            <tr>
                                                <td>--<sup>th</sup> </td>
                                                <td>-</td>
                                                <td class="text-right">Ganesh Chaturthi</td>
                                            </tr>
                                            <tr>
                                                <td>--</td>
                                                <td>-</td>
                                                <td class="text-right">Eid e Milad</td>
                                            </tr>
                                            <tr>
                                                <td>2<sup>nd</sup> Oct</td>
                                                <td>-</td>
                                                <td class="text-right">Gandhi Jayanti</td>
                                            </tr>
                                            <tr>
                                                <td>--<sup>th</sup> </td>
                                                <td>-</td>
                                                <td class="text-right">Vijaya Dashami and Dusshera</td>
                                            </tr>
                                            <tr>
                                                <td>15<sup>th</sup> Nov</td>
                                                <td>-</td>
                                                <td class="text-right">Guru Nanak Jayanti</td>
                                            </tr>
                                            <tr>
                                                <td>25<sup>th</sup> Dec</td>
                                                <td>-</td>
                                                <td class="text-right">Christmas</td>
                                            </tr>
                                            <tr class="last-tr">
                                                <td>Saturday & Sunday</td>
                                                <td>-</td>
                                                <td class="text-right">CLOSED</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <div class="col-lg-8">
                        <div class="row">
                            <!-- <article class="col-lg-6 col-md-6 post mb-xl-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog7.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor12.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article> -->
                            <!-- <article class="col-lg-6 col-md-6 post mb-xl-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog8.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article> -->

                            <article class="col-lg-6 col-md-6 post mb-xl-30" *ngFor="let item of eventList">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="">
                                            <!-- <img src="assets/images/blog/blog10.jpg" class="full-width" alt="blog"> -->
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-content" >
                                            <p class="text-light-white" innerHTML="{{item.newsDec}}">{{item.newsDec}}</p>
                                            <div class="blog-links">
                                                <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/news" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 100+</a></span>
                                                    <span class="fs-14"><a routerLink="/news" class="text-custom-black"><i class="fa fa-calendar text-light-blue"></i>&nbsp;{{item.fdate}}</a></span>
                                                </div> <a routerLink="/news" class="text-light-blue fs-14">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                       
                        <div class="row">
                            <div class="col-12">
                                <nav class="section-padding-top">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">Previous</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">1</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" routerLink="/blog-left-sidebar">2</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">3</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog -->
        <div *ngIf='loading2' id="myModal" class="modal">
            <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>        
        <app-footer></app-footer>