import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-nidhi-company-regitration',
  templateUrl: './nidhi-company-regitration.component.html',
  styleUrls: ['./nidhi-company-regitration.component.css']
})
export class NidhiCompanyRegitrationComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Nidhi Finance  Company Registration");
    this.metaService.addTags([
      {name: 'keywords', content: 'Free Nidhi Finance  Company Registration, Nidhi Finance  Company Registration free Software, Nidhi Finance  Company Registration, Nidhi, Nidhi Company,free Nidhi Finance Company in Hindi Marathi & English,Best Core Banking System,online Nidhi Finance Company software CBS free software, online Nidhi Finance Company CBS free Demo software'},
      {name: 'description', content: 'Nidhi Finance Company Registration in just 24999/- Rs.'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

}
