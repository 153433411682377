<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 class="text-custom-white lh-default fw-600">Nidhi Company Registration</h2>
                        <h6 style="color: white;">in just  24,999/- (All inclusive)</h6>
                        <h6 style="color:white">The World’s Most Power & Popular Free Core Banking Software (CBS) for your organization.</h6>
                        <h6 style="color:white">I-Bank 4.0 CBS support 24 different display language. like English, हिन्दी, मराठी &, etc</h6>
                        <h6 style="color:white">"I-Bank 4.0" CBS is free for New Copoperative Society till <b>"first 999 Accounts or first 9999 Transactions or 6<sup>st</sup> Months"</b></h6>
                        <h6 style="color:white">No Hidden Cost</h6>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">I-Bank 4.0 Info</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
