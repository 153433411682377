<app-navbar></app-navbar>
 <!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Our Team</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Our Team</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Team -->
        <section class="section-padding bg-gray">
            <div class="container">
                <div class="row doctors-team-style-2">
                    <div class="col-lg-3 col-md-6">
                        <div class="team-block p-relative mb-xl-30">
                            <div class="inner-box">
                                <div class="image animate-img">
                                    <img src="assets/images/doctors/doctor7.jpg" alt="img" class="full-width">
                                    <div class="overlay-box">
                                        <div class="overlay-inner p-relative full-height">
                                            <ul class="team-social-box custom">
                                                <li class="youtube">
                                                    <a routerLink="/our-team" class="fab fa-youtube fs-16"></a>
                                                </li>
                                                <li class="linkedin">
                                                    <a routerLink="/our-team" class="fab fa-linkedin fs-16"></a>
                                                </li>
                                                <li class="facebook">
                                                    <a routerLink="/our-team" class="fab fa-facebook-f fs-16"></a>
                                                </li>
                                                <li class="twitter">
                                                    <a routerLink="/our-team" class="fab fa-twitter fs-16"></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="lower-content p-relative text-center">
                                    <h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20">Eachann Jhon</a></h4>
                                    <p class="designation text-light-white">Educational Loan Advisor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="team-block p-relative mb-xl-30">
                            <div class="inner-box">
                                <div class="image animate-img">
                                    <img src="assets/images/doctors/doctor2.jpg" alt="img" class="full-width">
                                    <div class="overlay-box">
                                        <div class="overlay-inner p-relative full-height">
                                            <ul class="team-social-box custom">
                                                <li class="youtube">
                                                    <a routerLink="/our-team" class="fab fa-youtube fs-16"></a>
                                                </li>
                                                <li class="linkedin">
                                                    <a routerLink="/our-team" class="fab fa-linkedin fs-16"></a>
                                                </li>
                                                <li class="facebook">
                                                    <a routerLink="/our-team" class="fab fa-facebook-f fs-16"></a>
                                                </li>
                                                <li class="twitter">
                                                    <a routerLink="/our-team" class="fab fa-twitter fs-16"></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="lower-content p-relative text-center">
                                    <h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20"> Addison Smith</a></h4>
                                    <p class="designation text-light-white">Car Advisor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="team-block p-relative mb-xl-30">
                            <div class="inner-box">
                                <div class="image animate-img">
                                    <img src="assets/images/doctors/doctor3.jpg" alt="img" class="full-width">
                                    <div class="overlay-box">
                                        <div class="overlay-inner p-relative full-height">
                                            <ul class="team-social-box custom">
                                                <li class="youtube">
                                                    <a routerLink="/our-team" class="fab fa-youtube fs-16"></a>
                                                </li>
                                                <li class="linkedin">
                                                    <a routerLink="/our-team" class="fab fa-linkedin fs-16"></a>
                                                </li>
                                                <li class="facebook">
                                                    <a routerLink="/our-team" class="fab fa-facebook-f fs-16"></a>
                                                </li>
                                                <li class="twitter">
                                                    <a routerLink="/our-team" class="fab fa-twitter fs-16"></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="lower-content p-relative text-center">
                                    <h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20"> Sarah Taylor</a></h4>
                                    <p class="designation text-light-white">Home Loan Advisor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="team-block p-relative mb-xl-30">
                            <div class="inner-box">
                                <div class="image animate-img">
                                    <img src="assets/images/doctors/doctor4.jpg" alt="img" class="full-width">
                                    <div class="overlay-box">
                                        <div class="overlay-inner p-relative full-height">
                                            <ul class="team-social-box custom">
                                                <li class="youtube">
                                                    <a routerLink="/our-team" class="fab fa-youtube fs-16"></a>
                                                </li>
                                                <li class="linkedin">
                                                    <a routerLink="/our-team" class="fab fa-linkedin fs-16"></a>
                                                </li>
                                                <li class="facebook">
                                                    <a routerLink="/our-team" class="fab fa-facebook-f fs-16"></a>
                                                </li>
                                                <li class="twitter">
                                                    <a routerLink="/our-team" class="fab fa-twitter fs-16"></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="lower-content p-relative text-center">
                                    <h4><a routerLink="/our-team" class="text-custom-black fw-600 fs-20"> Aiken Ward</a></h4>
                                    <p class="designation text-light-white">Home Loan Advisor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row doctors-team darkblue">
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-xl-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor9.jpg" class="rounded-circle full-width" alt="image"> <a routerLink="/our-team" class="detail-btn"><i class="fas fa-plus"></i></a>
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700"> Addison Smith</a></h6>
                                <p class="designation text-light-white">Home Loan Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-xl-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor10.jpg" class="rounded-circle full-width" alt="image"> <a routerLink="/our-team" class="detail-btn"><i class="fas fa-plus"></i></a>
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700"> Sarah Taylor</a></h6>
                                <p class="designation text-light-white">Corporate Loan Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-xl-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor8.jpg" class="rounded-circle full-width" alt="image"> <a routerLink="/our-team" class="detail-btn"><i class="fas fa-plus"></i></a>
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700"> Aiken Ward</a></h6>
                                <p class="designation text-light-white">Home Loan Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-xl-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor7.jpg" class="rounded-circle full-width" alt="image"> <a routerLink="/our-team" class="detail-btn"><i class="fas fa-plus"></i></a>
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700">Babatunde Jon</a></h6>
                                <p class="designation text-light-white">Real State Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row doctors-team style-2">
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-md-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor5.jpg" class="full-width" alt="image">
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700"> Addison Smith</a></h6>
                                <p class="designation text-light-white">Home Loan Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-md-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor6.jpg" class="full-width" alt="image">
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700"> Sarah Taylor</a></h6>
                                <p class="text-light-white">Educational Loan Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item mb-sm-30">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor7.jpg" class="full-width" alt="image">
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700"> Aiken Ward</a></h6>
                                <p class="text-light-white">Business Loan Advisor</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="doctor-item">
                            <div class="doctor-img mb-xl-20">
                                <img src="assets/images/doctors/doctor2.jpg" class="full-width" alt="image">
                            </div>
                            <div class="doctor-content">
                                <h6><a routerLink="/our-team" class="text-custom-black fw-700">Babatunde Jon</a></h6>
                                <p class="text-light-white">PERSONAL LOANyer</p>
                                <ul class="custom">
                                    <li><a routerLink="/our-team"><i class="fab fa-facebook-f"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-twitter"></i></a>
                                    </li>
                                    <li><a routerLink="/our-team"><i class="fab fa-pinterest-p"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Team -->
        <app-footer></app-footer>