import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-server-dedicated',
  templateUrl: './server-dedicated.component.html',
  styleUrls: ['./server-dedicated.component.css']
})
export class ServerDedicatedComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("Dedicated Server");
    this.metaService.addTags([
      {name: 'keywords', content: 'Free Software For Credit Cooperative Society, Credit Cooperative Society free Software, Bank free Software, Credit, Cooperative, Society,free Banking Credit Cooperative Society Software in Hindi Marathi & English,Best Core Banking System,online Credit Cooperative Society CBS free software, online Credit Cooperative Society CBS free Demo software मोफत बँकिंग क्रेडिट कोऑपरेटिव्ह सोसायटी सॉफ्टवेअर हिंदी मराठी आणि इंग्रजीमध्ये'},
      {name: 'description', content: 'I-Bank 4.0 CBS Core Banking System Software For Credit Cooperative Society'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }

}
