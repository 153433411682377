import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import './../assets/smtp.js';
import { Meta } from '@angular/platform-browser';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;

  constructor(private router: Router,private metaTagService: Meta) {
      
  }

  ngOnInit(){
    this.metaTagService.addTags([
        { name: 'keywords', content: 'Ocean Software Technologies,I-Bank 4.0,Core Banking System,Credit Cooperative Society software,Society software,Multi-State Society Software,Nidhi software,banking Software,free Society software,Best Banking Software Services,finance Technologies,dynamic website design development,IT Company,mobile banking,banking financial,software companies in nagpur' },
        { name: 'description', content:'Ocean Software Technologies is leading provider of I-Bank 4.0 web based Core Banking Software (CBS) in India, We provide fully integrated web based core banking solution(CBS), Web Based top ranked core banking software solution with best features of credit cooperative society,Nidhi Company,Credit Union,Retail banking,Any Branch Banking,ATM & CDM banking,SMS Banking,Internet Banking,Mobile banking,Mobile APP Banking,Central Bank Reporting and Credit Appraisal.'},
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Yogesh Kedar' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: '2021-07-30', scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);

    this.recallJsFuntions();
  }

  recallJsFuntions() {
      this.router.events
      .subscribe((event) => {
          if ( event instanceof NavigationStart ) {
              $('#preloaders').fadeIn('slow');
          }
      });
      this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
          $.getScript('assets/js/custom.js');
          $('#preloader').fadeOut('slow');
          this.location = this.router.url;
          if (!(event instanceof NavigationEnd)) {
              return;
          }
          window.scrollTo(0, 0);
      });
  }
}