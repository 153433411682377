<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header-server p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h3 class="text-custom-white lh-default fw-600">Windows Hosting Plans with Powerful Windows Infrastructure</h3>
                                <h4>India's Most Trusted Datacenter</h4>
                                <h4>Run Windows Server 2019 and the latest versions of ASP.NET and .NET Core on reliable web hosting.</h4>
                                <h4>High Performance, Low Cost Windows Hosting Starting @ ₹999/Year</h4>
                                <h6 style="color: red;">1 Year Free SSL, M.2 SSD, 32 GB Tower Server Hosting</h6>
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-3  col-sm-6">
                                            <div class="mb-md-20 wow">
                                                <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                                    <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                                    <p class="text-custom-white">server provider with 99% uptime.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  col-sm-6">
                                            <div class="mb-md-20 wow">
                                                <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                                    <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                                    <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3  col-sm-6">
                                            <div class="mb-xs-20 wow">
                                                <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                                    <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                                    <p class="text-custom-white">Upgradation within minutes for all processors & servers.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6">
                                            <div class="mb-xs-20 wow">
                                                <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                                    <h6 class="text-custom-white fw-700">Fastest Connectivity</h6>
                                                    <p class="text-custom-white">100 GBPS connectivity with fast and Premium bandwidth.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">windows Hosting</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <section class="section-padding our_articles">
            <div class="container">
                <div class="section-header">
                    <div class="section-heading">
                        <h3 class="text-custom-black fw-700">Windows Hosting</h3>
                        <div class="section-description">
                            <p class="text-light-white" style="text-align: justify;">Our Server Hosting located at Nagpur & Bengaluru in Indian Datacenter is built for those who require complete control, superpower, and high-end security. OceanSoftTech has all high-end M.2 SSD shared servers hosting that are your ready solutions to any business-critical challenge. our low-cost VPS servers always come with Tireless Support.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 no-padding">
                        <div class="blog-slider" style="text-align: center;">
                            <article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Starter</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>Host a single website.</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">999/- ₹ Per Year</b><br>
                                            <small><b>2400/- ₹ 3 Year (20%* off)</b></small>
                                            <br><br>
                                            1 Website<br><br>
                                            <b>2 GB M.2 SSD</b> storage<br><br>
                                            1 TB Bandwidth Per Month<br><br>
                                            <small>500/- ₹ Per Year for Unlimited Bandwidth<br><br></small>
                                            0-48 Hrs Delivery Time
                                        </p>
                                        <a routerLink="" class="fw-500 fs-14">Read More</a>
                                    </div>
                                </div>
                            </article>
                            <article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Economy</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>Best price for a basic site.</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">1500/- ₹ Per Year</b><br>
                                            <small><b>3600/- ₹ 3 Year (20%* off)</b></small>
                                            <br><br>
                                            1 Website<br><br>
                                            <b>10 GB M.2 SSD</b> storage<br><br>
                                            5 TB Bandwidth Per Month<br><br>
                                            <small>500/- ₹ Per Year for Unlimited Bandwidth<br><br></small>
                                            0-48 Hrs Delivery Time
                                        </p>
                                        <a routerLink="" class="fw-500 fs-14">Read More</a>
                                    </div>
                                </div>
                            </article>
                            <article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Deluxe</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>More space and flexibility.</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">1999/- ₹ Per Year</b><br>
                                            <small><b>4800/- ₹ 3 Year (20%* off)</b></small>
                                            <br><br>
                                            1 Website<br><br>
                                            <b>20 GB M.2 SSD</b>storage<br><br>
                                            5 TB Bandwidth Per Month<br><br>
                                            <small>500/- ₹ Per Year for Unlimited Bandwidth<br><br></small>
                                            MSSQL (200MB)<br><br>
                                            Free Professional Email<br><br>
                                            Free SSL 1 Year<br>
                                            <small>₹ 2400/Yr</small><br><br>
                                            0-48 Hrs Delivery Time
                                        </p>
                                        <a routerLink="" class="fw-500 fs-14">Read More</a>
                                    </div>
                                </div>
                            </article>
                            <article class="post col-12">
                                <div class="single-blog">
                                    
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Enterprise</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>complex sites and heavy traffic.</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">7999/- ₹ Per Year</b><br>
                                            <small><b>19500/- ₹ 3 Year (20%* off)</b></small>
                                            <br><br>
                                            4 websites<br><br>
                                            <b>40 GB M.2 SSD</b> storage<br><br>
                                            5 TB Bandwidth Per Month<br><br>
                                            <small>500/- ₹ Per Year for Unlimited Bandwidth<br><br></small>
                                            MSSQL (400MB) Per Website<br><br>
                                            Free Professional Email<br><br>
                                            Free SSL 1 Year<br>
                                            <small>₹ 2400/Yr/Domain on next renewal</small><br><br>
                                            0-48 Hrs Delivery Time
                                        </p>
                                        <a routerLink="" class="fw-500 fs-14">Read More</a>
                                    </div>
                                </div>
                            </article>
                            <!--<article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-meta">
                                        <div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 13, Nov</a>
                                        </div>
                                    </div>
                                    <div class="blog-photo">
                                        <img src="assets/images/blog/blog3.jpg" class="full-width" alt="#">
        
                                    </div>
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">How Is Mediation Useful in Home Loan Cases?</h2>
                                        <p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
                                        <div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Jhone Deo</a></span>
                                        </div>
                                    </div>
                                    <div class="blog-overlay">
                                        <div class="right-text-effect post-content">
                                            <h2 class="post-title"><a routerLink="/blog-detail" class="text-white">How Is Mediation Useful in Home Loan Cases?</a></h2>
                                            <p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
                                        </div>
                                        <div class="blog-read-more-icon">
                                            <div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Jhone Deo</a></span>
                                            </div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog -->
        <app-footer></app-footer>