<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 class="text-custom-white lh-default fw-600">CORE BANKING SYSTEM SOFTWARE FOR</h2>
                        <h4 style="color:white">Multi-State Credit Cooperative Society Pvt. Ltd.</h4>
                        <h4 style="color:white">High Performance, Low Cost, Starting @ ₹19900/Year/Branch</h4>
                        <h6 style="color:white">"I-Bank 4.0" CBS is free for New Multi State Copoperative Society till <b style="color: red;">"first 4999 Accounts or first 10000 Transactions or 6<sup>st</sup> Months"</b></h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">I-Bank 4.0 CBS SaaS provider with 98% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Json, Javascript, HTML, ASP.NET 4.5 & SqlServer 2012 R2</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Security & Authentication</h6>
                                            <p class="text-custom-white">Static IP & Two-Step Authentication</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Multi-State Credit Cooperative Society Pvt. Ltd.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
 <!-- Start About Us Intro -->
 <section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta">
                    <h3><a routerLink="#" class="text-custom-black fw-600">Multi State Credit Cooperative Society Pvt. Ltd.</a></h3>
                    <p class="text-light-white">A Multi State Credit Cooperative Society Pvt. Ltd. is a member-owned financial cooperative, controlled by its members and operated on the principle of people helping people, providing its member's credit at competitive rates as well as other financial services across many states. Cooperative banking is retail and commercial banking organized on a cooperative basis.</p>
                    <p class="text-light-white">"I-Bank 4.0" Web-Based Core Banking System is the best software for A Multi State Credit Cooperative Society Pvt. Ltd. to manage their single & Multiple branches across many states account, transactions & reports.</p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<!-- End About Us Intro -->
<!-- Start Main Services -->
<section class="main-services p-relative section-padding" >
    <div class="side-lines"> <span class="box"></span>
        <span class="text">I-Bank</span>
        <span class="line"></span>
    </div>
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">Some of the Most Important Features</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-hdd-stack"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">CBS Branch to Branch</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Branch to Branch operation in Multi-State Cooperative Society is main because society has more than 2-3 states working areas, "I-Bank 4.0 CBS" is very efficiently used in this scenario it reduces time and working hours of employees and improves the productivity of all organization. In CBS any customer can access any branch to deposit this amount, check balances, & fund transfer to any other accounts. In this software we can create <b>899 branches</b> under one Multi-State credit cooperative society Pvt. Ltd.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-hdd-rack"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">CBS Transactions</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            In "I-Bank 4.0" we manage 15 Types of different transactions, Like Voucher, Transfer, CBS Entry, RTGS/NEFT. CBS or Branch to Branch Transactions is very easy & Real-Time for all branches. In "I-Bank 4.0" customers of society make deposits in any branch, it is checked balances of there all accounts, make payments to other accounts. A mobile App & Collection Machine for Pigmy & Loan Collection for Agents. "I-Bank 4.0" comes with an Online Banking feature for the customer through a mobile App & Website.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-ui-checks"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">CBS Reports</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            "I-Bank 4.0" has all standard reports like Cash-book I & II, Day-book 5 Types, Cut-book, Subdaybook, Account Statement, & G/L Statement come with default branch, but operators can see other branch transactions if he/she is done CBS transactions. Audit Reports like MIS Report, Trial Balance, Receipt Payment, Balance Sheet, Profit Loss, GL & Cutbook, CBS Transaction, ATM Transaction, App Transaction, and RTGS/NEFT Transaction. In "I-Bank 4.0" CBS we have 250+ reports all reports are also available in CBS format.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xs-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-file-spreadsheet"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Uitilities</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            "I-Bank 4.0" CBS provides a wide range of utilities Like SMS (Account. Opening & Transaction), Transaction Delete utility for same day, Transaction Correction, Backdate Transaction entry, Standing Instructions Execution, Stop Transaction, Previous / Current Transaction Voucher printing, passbook printing, FD / Share certificate printing, Branch / Society year-end Remote Account Opening.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20" style="text-align: justify;">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Share Accounts</a></h5>
                        <p class="text-light-white no-margin">In the <b>Share account section</b>, we have three types Regular Share, Nominal Share, & Associates Share. Share accounts are permanent members of society and it is used for loan guarantee & director creation. Cooperative society is a financial entity that belongs to its Share members, who are at the same time the owners and the customers of their Society.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Deposit Accounts</a></h5>
                        <p style="text-align: justify;" class="text-light-white no-margin">In <b>Deposit account section</b> of the software we have, all types of deposit account create & manage (Saving, Current, Fixed Deposit FD, Recurring Deposit RD, Pigmy/Daily Deposit, Locker & ETC). A single form is used for creating & editing deposit account. In a deposit account, you can add Agent & director reference when an account is created.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">Loan Accounts</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">In the <b>Loan account section</b> of the software we have, all types of loan account create & manage (Regular Loan, Emergency Loan, Housing Loan, Vehicle Loan, CC Loan, deposit Loan & Etc). A single form is used for creating & editing a loan account. You can add photos of all loan documents in the loan account, link the deposit when the account is created.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/service-detail" class="text-custom-black">General Accounts</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            In <b>General Accounts section</b> of the software we have, all types of general accounts create & manage (Agent Suspense, TDS, Bank Account, Employees Salary Account, & other suspense accounts). A single form is used for creating & editing a general account. The general account section is used for creating an account which is not managed in other section.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Main Services -->
	<!-- About One section-->
    <section class="section-padding about-us-sec p-relative">
        <div class="side-lines right-side"> <span class="box"></span>
            <span class="text">Clients</span>
            <span class="line"></span>
        </div>
        <h3 class="text-custom-black fw-700" style="text-align:center;">Multi-State Cooperative Society used our I-Bank 4.0 CBS</h3>
        <div class="container">
            <div class="row no-gutters">
                <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                    <div class="about-left-side-1">
                        <div class="section-header">
                            <div class="section-heading">
                                <h4 class=" fw-700 text-dark">Multi-State Credit Cooperative Society Pvt. Ltd.</h4>
                            </div>
                        </div>
                        <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                         <b>
                            Giants Multistate Co-operative Credit Society Ltd.<br>
                            <small>(I-Bank 4.0) 4 Branches</small><br>
                             OHROH Employee Organization 3 states in India<br>
                           <small>(I-Bank 4.0) 36 Branches</small><br>
                             Yalsco Multi-State Cooperative Society Pvt. Ltd.<br>
                            <small>(I-Bank 4.0) 16 Branches</small><br>
                            Gramin Bhart Multi-State Cooperative Society<br>
                            <small>(I-Bank 4.0) 11 Branches</small><br>
                            Aditya Anagha Multi-State Cooperative Society.<br>
                            <small>(Web Site)</small> </b>
                        </p>
                        <a routerLink="/i-bank-price" class="btn-first btn-submit-fill fw-600 mb-xl-20"><i class="fas fa-rupee-sign mr-2 fs-16"></i>I-Bank 4.0 CBS Price</a>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="full-height">
                        <img src="assets/images/i-Bank-4.0-Logo.jpg" style="display: block; margin: auto; width: 90%;" alt="about us">
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--About-two-section-end-->
<app-footer></app-footer>