import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homepage1',
  templateUrl: './homepage1.component.html',
  styleUrls: ['./homepage1.component.css']
})
export class Homepage1Component implements OnInit {
  ApiResult: any;
  emailId="help@oceansofttech.net";
  constructor(private fb: FormBuilder, private mainService: MainService, private titleService: Title, private metaService: Meta ) 
  { }
  form: FormGroup;
  ngOnInit() {
    this.form = this.fb.group({
      Name: [''],
      Email: [''],
      phone_number: [''],
      Subject: [''],
    });
    
    this.titleService.setTitle("Ocean Software Technologies | I-Bank Core Banking Software CBS | Credit Society & Nidhi Company");
    this.metaService.addTags([
      {name: 'keywords', content: 'Free Software For Credit Cooperative Society SACCOS, Credit Cooperative Society free Software, Bank free Software, Credit, Cooperative, Society,free Banking Credit Cooperative Society Software,Free SACCOS for Zimbabwe Africa,Free SACCOS for Tanzania Africa, Free SACCOS for Nigeria Ghana Kenya Uganda Zambia Zimbabwe Sierra Leone Liberia South Sudan South Africa,Best Core Banking System,online Credit Cooperative Society CBS free software, online Credit Cooperative Society CBS free Demo software'},
      {name: 'description', content: 'Ocean Software Technologies, I-Bank 4.0 CBS Core Banking System Software For Credit Cooperative Society SACCOS'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }
  get f() { return this.form.controls; }
  Email_send() {
    var name = this.form.get("Name").value;
    var Email = this.form.get("Email").value;
    var phone = this.form.get("phone_number").value;
    var Subject = this.form.get("Subject").value;
    var EmailBodyMsg = "<table><thead><tr><th>Home Page</th></tr></thead><tbody><tr><td>Name:</td><td>" + name + "</td></tr><tr><td>Phone-No:</td><td>" + phone + "</td></tr><tr><td>Email:</td><td>" + Email + "</td></tr><tr><td>Subject:</td><td>" + Subject + "</td></tr></tbody></table>";
    var Moobj: any = {};
    Moobj.EmailSubject = "Oceansofttech.net Website Enquiry Email";
    Moobj.EmailTo = this.emailId;
    Moobj.PkSystemEmailSetting = 4;
    Moobj.message = EmailBodyMsg;
    console.log(Moobj)
    this.mainService.emailMethod(Moobj).subscribe(value => {
      this.ApiResult = value;
    });
    this.form.reset();
    alert("Mail send successfully");
  }
}
