import { BrowserModule,Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import{ReactiveFormsModule,FormsModule} from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import{HttpModule} from '@angular/http';
import{AuthGuard} from './services/auth-guard.service';
import{AuthrizationService} from './services/auth.service';
import{MainService} from './services/main.service';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { Homepage1Component } from './components/pages/homepage1/homepage1.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { FinanceComponent } from './components/pages/finance/finance.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogLeftSidebarComponent } from './components/pages/blog-left-sidebar/blog-left-sidebar.component';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { OurTeamComponent } from './components/pages/our-team/our-team.component';
import { LoanCalculatorComponent } from './components/pages/loan-calculator/loan-calculator.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceDetailComponent } from './components/pages/service-detail/service-detail.component';
import { UserDashboardComponent } from './components/pages/user-dashboard/user-dashboard.component';
import { LoanStepsComponent } from './components/pages/loan-steps/loan-steps.component';
import { IBankInfoComponent } from './components/pages/i-bank-info/i-bank-info.component';
import { IBankPriceComponent } from './components/pages/i-bank-price/i-bank-price.component';
import { ServerVpsComponent } from './components/pages/server-vps/server-vps.component';
import { ServerDedicatedComponent } from './components/pages/server-dedicated/server-dedicated.component';
import { CreditCooperativeSocietyComponent } from './components/pages/credit-cooperative-society/credit-cooperative-society.component';
import { MicroFinanceNidhiComponent } from './components/pages/micro-finance-nidhi/micro-finance-nidhi.component';
import { MobileBankingComponent } from './components/pages/mobile-banking/mobile-banking.component';
import { MultistateSocietyComponent } from './components/pages/multistate-society/multistate-society.component';
import { WebsiteDesignComponent } from './components/pages/website-design/website-design.component';
import { WebsiteHostingComponent } from './components/pages/website-hosting/website-hosting.component';
import { CareerComponent } from './components/pages/career/career.component';
import { VacanciesComponent } from './components/pages/vacancies/vacancies.component';
import { Covid19Component } from './components/pages/covid19/covid19.component';
import { NewsComponent } from './components/pages/news/news.component';
import { MobileAppComponent } from './components/pages/mobile-app/mobile-app.component';
import { LegalDisclaimerComponent } from './components/pages/legal-disclaimer/legal-disclaimer.component';
import { DATAPROTECTIONPOLICYComponent } from './components/pages/data-protection-policy/data-protection-policy.component';
import { LoginComponent } from './components/pages/login/login.component';
import { TestingComponent } from './components/pages/testing/testing.component';
import { BloggComponent } from './components/pages/blogg/blogg.component';
import { ContactoceanComponent } from './components/pages/contactocean/contactocean.component';
import { importExpr } from '@angular/compiler/src/output/output_ast';
import { ClientsComponent } from './components/pages/clients/clients.component';
import { NidhiCompanyRegitrationComponent } from './components/pages/nidhi-company-regitration/nidhi-company-regitration.component';
import { TermConditionsComponent } from './components/pages/term-conditions/term-conditions.component';
import { PrivacyPoliciesComponent } from './components/pages/privacy-policies/privacy-policies.component';
import { RefundCancellationPoliciesComponent } from './components/pages/refund-cancellation-policies/refund-cancellation-policies.component';
import { FinanceUitilityComponent } from './components/pages/finance-uitility/finance-uitility.component';
import { InternshipComponent } from './components/pages/internship/internship.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    Homepage1Component,
    Homepage2Component,
    FinanceComponent,
    BlogComponent,
    BlogLeftSidebarComponent,
    BlogDetailComponent,
    Error404Component,
    ComingSoonComponent,
    OurTeamComponent,
    LoanCalculatorComponent,
    AboutUsComponent,
    ServiceComponent,
    ServiceDetailComponent,
    UserDashboardComponent,
    LoanStepsComponent,
    IBankInfoComponent,
    IBankPriceComponent,
    ServerVpsComponent,
    ServerDedicatedComponent,
    CreditCooperativeSocietyComponent,
    MicroFinanceNidhiComponent,
    MobileBankingComponent,
    MultistateSocietyComponent,
    WebsiteDesignComponent,
    WebsiteHostingComponent,
    CareerComponent,
    VacanciesComponent,
    Covid19Component,
    NewsComponent,
    MobileAppComponent,
    LegalDisclaimerComponent,
    DATAPROTECTIONPOLICYComponent,
    LoginComponent,
    TestingComponent,
    BloggComponent,
    ContactoceanComponent,
    ClientsComponent,
    NidhiCompanyRegitrationComponent,
    TermConditionsComponent,
    PrivacyPoliciesComponent,
    RefundCancellationPoliciesComponent,
    FinanceUitilityComponent,
    InternshipComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [
      Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
