import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit(): void {
      this.titleService.setTitle("Career opportunities at Ocean Software Technologies");
      this.metaService.addTags([
        {name: 'keywords', content: 'Career opportunities at Ocean Software Technologies,Angular developer, asp.net developer, .net Core developer, ionic developer, Sql developer, C# developer, .net core, 6 - 12 months free internship program for mca, bca, bsc, engineering students.'},
        {name: 'description', content: 'Career opportunities at Ocean Software Technologies Nagpur'},
        {name: 'robots', content: 'index, follow'},
        {name: 'writer', content: 'Yogesh Kedar'}
      ]);
  }

}
