import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';


@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {
  constructor(private mainservice: MainService, ) { }
  CategoryList: any;
  SpResult: any;
  response: any = {};

  html: '';
  addTest = new FormGroup({
    name: new FormControl(''),
    mobno: new FormControl('')
  });
  ngOnInit() { 

  }

  Insert() {
    var Moobj: any = {};
    Moobj.Pid = '7dcbb7cd-70f0-4fad-b89a-49499b9df7d4';
    Moobj.Name = this.addTest.value.name;
    Moobj.Mobno = this.addTest.value.mobno;
    this.mainservice.TwoMethod(Moobj).then(value => {
      this.SpResult = value;
      this.response.column1 = this.SpResult.table[0].column1;
      this.response.column2 = this.SpResult.table[0].column2;
      //alert(JSON.stringify(this.SpResult.table[0].column2));
    });
    this.Reset()
  }
  Reset() {
    this.addTest.reset();
  }
  Select(){ 
    var Moobj:any = {}; 
    Moobj.Pid='cf7ee5df-30b7-4f77-9514-31f4d2c22f7b';
    this.mainservice.TwoMethod(Moobj).then(value=>{
      this.CategoryList=value;
      this.CategoryList=this.CategoryList.table;
      //alert(this.CategoryList)
     });
  } 
}