import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-i-bank-price',
  templateUrl: './i-bank-price.component.html',
  styleUrls: ['./i-bank-price.component.css']
})
export class IBankPriceComponent implements OnInit {
  ApiResult: any;
  IntPrice='5000/- Rs';
  emailId="yogeshk_123@yahoo.co.in";
  constructor(private fb: FormBuilder, private mainService: MainService, private titleService: Title, private metaService: Meta) { }
  form: FormGroup;

  ngOnInit() {
    this.form = this.fb.group({
      Name: [''],
      Email: [''],
      phone_number: [''],
      Subject: [''],
      Message: [''],
    });
    
    this.titleService.setTitle("I-BANK 4.0 CBS PRICING PLAN");
    this.metaService.addTags([
      {name: 'keywords', content: 'cooperative society software free, free society software, credit cooperative software CBS free download, I-Bank 4.0 Price plan, I-bank Price Plan, best society software in nagpur, best society software in vidharbha, best society software in maharashtra'},
      {name: 'description', content: 'I-Bank 4.0 CBS Core Banking System Software Top Features of I-Bank 4.0 (CBS)'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
  }
  get f() { return this.form.controls; }
  Email_send() {
    var name = this.form.get("Name").value;
    var Email = this.form.get("Email").value;
    var phone = this.form.get("phone_number").value;
    var Subject = this.form.get("Subject").value;
    var Message = this.form.get("Message").value;
    var EmailBodyMsg = "<table><thead><tr><th>Contact Form</th></tr></thead><tbody><tr><td>Name:</td><td>" + name + "</td></tr><tr><td>Phone-No:</td><td>" + phone + "</td></tr><tr><td>Email:</td><td>" + Email + "</td></tr><tr><td>Subject:</td><td>" + Subject + "</td></tr><tr><td colspan=2>Message:</td></tr><tr><td colspan=2>" + Message + "</td></tr></tbody></table>";
    var Moobj: any = {};
    Moobj.EmailSubject = "Oceansofttech.net Website Enquiry Email";
    Moobj.EmailTo = this.emailId;
    Moobj.PkSystemEmailSetting = 4;
    Moobj.message = EmailBodyMsg;
    console.log(Moobj)
    this.mainService.emailMethod(Moobj).subscribe(value => {
      this.ApiResult = value;
    });
    this.form.reset();
    alert("Mail send successfully");
  }

}
