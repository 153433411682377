<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h2 class="text-custom-white lh-default fw-600">URGENTLY HIRING IN OCEAN SOFTWARE TECHNOLOGIES</h2><br>
                                Become A OCEANER
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Vacancies</li>
                                </ul>
                            </div><br>
                        </div><br>
                    </div><br>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h4><a routerLink="" class="text-custom-black fw-600">URGENTLY HIRING :- Freshers</a></h4>
                            <p class="text-light-white">
                                Location:- Nagpur & Bengaluru<br>
                                Diploma in Computer Science / Diploma in Information Technology, Diploma in Electronics or equivalent, BE, B. Sc., BCA, MCA & MCM.<br>
                                <b>Post</b><br>
                                Python Developer :- 02 (Freshers or 6 Months Exp.)<br>
                                Django Developer :- 02 (Freshers or 6 Months Exp.)<br>
                                PostgreSql Developer :- 02 (Freshers or 6 Months Exp.)<br>
                                <br>
                                Angular Developer :- 04 (Freshers or 6 Months Exp.)<br>
                                <b>Android Developer :- 02 (Freshers or 1 Year Exp.)<br></b>
                                PL-SQL Developer :- 01 (Freshers or 6 Months Exp.)<br>
                                
                            </p>
                            <hr>
                            <h4><a routerLink="" class="text-custom-black fw-600">URGENTLY HIRING :- Expertise’</a></h4>
                            <p class="text-light-white">
                                Location:- Nagpur & Bengaluru<br>
                                Diploma in Computer Science / Diploma in Information Technology, Diploma in Electronics or equivalent, BE, B. Sc., BCA, MCA & MCM.<br>
                                <b>Post</b><br>
                                Python Developer :- 02 (Freshers or 6 Months Exp.)<br>
                                Django Developer :- 02 (Freshers or 6 Months Exp.)<br>
                                PostgreSql Developer :- 02 (Freshers or 6 Months Exp.)<br>
                                <br>
                                Angular Developer :- 1 Post (1 Years Exp.)<br>
                                <!--<b>Android Developer :- 00 (Freshers or 1 Year Exp.)<br></b>-->
                                PL-SQL Developer :- 1 Post (1 Year Exp.)<br>
                                <b>Asp.Net C# Developer :- 2 Post (Freshers or 2 Year Exp.)<br></b>
                                help@oceansofttech.net
                            </p>
                        </div>
                    </div>
                </article>
                <!-- comments -->
                <div class="comment-box section-padding-top p-relative">
                    <div id="comment-box">
                        <div class="comment-respond" id="respond">
                            <h4 class="text-custom-black fw-600">Upload your Resume</h4>
                            <form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Full Name</label>
                                            <input type="text" name="#" class="form-control form-control-custom" placeholder="Full Name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Email I'd</label>
                                            <input type="email" name="#" class="form-control form-control-custom" placeholder="Email I'd">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Mobile No.</label>
                                            <input type="email" name="#" class="form-control form-control-custom" placeholder="Mobile No">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">College / Last Company</label>
                                            <input type="email" name="#" class="form-control form-control-custom" placeholder="College / Company Name">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Subject</label>
                                            <textarea rows="4" name="#" class="form-control form-control-custom" placeholder="Info Your Required"></textarea>
                                        </div>
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Upload Resume</label><br>
                                            <input type="file">
                                        </div>
                                        <button type="submit" class="btn-first btn-submit text-light-blue">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- comments -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <section class="download-app parallax">
            <div class="side-lines right-side style-2"> <span class="box"></span>
                <span class="text">Careers</span>
                <span class="line"></span>
            </div>
        </section>
        <app-footer></app-footer>