import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';
import { Duplex } from 'stream';

@Component({
  selector: 'app-credit-cooperative-society',
  templateUrl: './credit-cooperative-society.component.html',
  styleUrls: ['./credit-cooperative-society.component.css']
})
export class CreditCooperativeSocietyComponent implements OnInit {

  SpResult: any;
  eventList: any = [];
  eventList1: any = [];
  table1: any;
  table2: any;
//  y: any;
  ext: any;
  loading2=false;

  constructor(private titleService: Title, private metaService: Meta, private fb: FormBuilder, private mainService: MainService ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Core Banking System Software For Credit Cooperative Society");
    this.metaService.addTags([
      {name: 'keywords', content: 'Credit Cooperative Society software free download, Credit Cooperative Society Software price free, society free Software, Credit, Cooperative, Society,Credit Cooperative Society Software free in Hindi Marathi & English,Free SACCOS for Zimbabwe Africa,Free SACCOS for Tanzania Africa, Free SACCOS for Nigeria Ghana Kenya Uganda Zambia Zimbabwe Sierra Leone Liberia South Sudan South Africa,Best Core Banking System,online Credit Cooperative Society CBS free software, online Credit Cooperative Society CBS free Demo software, Co-operative Society free software, मोफत बँकिंग क्रेडिट कोऑपरेटिव्ह सोसायटी सॉफ्टवेअर हिंदी मराठी आणि इंग्रजीमध्ये'},
      {name: 'description', content: 'Core Banking System Software For Credit Cooperative Society free, Employee Cooperative Society free download, Credit Unions, SACCOS software free download'},
      {name: 'robots', content: 'index, follow'},
      {name: 'writer', content: 'Yogesh Kedar'}
    ]);
    this.ShowClient();

  }
  ShowClient()
  {
    this.loading2 = true;
    const MoObj: any = {};
    MoObj.Pid = "a8f877fd-985a-4b96-970a-f540c74b3679";
    MoObj.Pk_WebSite_ClientLogoImg = 0;

    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      console.log(this.SpResult)
      this.table1 = this.SpResult.table
      this.table2 = this.SpResult.table1
      this.loading2 = false;
      for (let i = 0; i < this.table1.length; i++) {
        var Pk_WebSite_ClientLogoImg = this.table1[i].pk_WebSite_ClientLogoImg;
        let FolderPath = this.table1[i].folderPath;
        let NAME = this.table1[i].name;

        this.table1[i]['i_path'] = FolderPath;
        this.table1[i]['fdate'] = NAME;
        this.eventList.push(this.table1[i]);
      }
    });
  }
}
