<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header-Webdesign p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Website Design & Development</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Website Design & Development</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container" style="text-align: justify;">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Website Design & Development in Angular</a></h2>
                            <p class="text-light-white">Web Development has always been our key strength. We have equally focused in the areas of Website Design, Website Development, Web Promotion, and Software Development over these years delivering professional and cost effective solutions to our clients globally. Ocean Software Technologies is a web design company based in India providing website design services and custom website designing. Our web designing services also include Angular, Bootstrap, HTML 5 & CSS 3 development and website redesigning thereby making us a premier web designing company in world. 
                                We aim high and time and again to surprise our customers with leading-edge solutions. We act quickly, are dynamic, and drive our agile solutions ahead. We provide consistent solutions that are simply better. Our customers expect us to always be a step ahead, which is why we show our capabilities in both technology, and design equally.
                            </p>
                            <p class="text-light-white">Ocean Software Technologies has more than a decade of experience in website development we design & develop more than 200+ websites, in 2008 we start making websites in HTML nowadays we move to Angular from ASP.NET.<br><br><i> We offer majorly three different types of websites.</i><br><b> 1) Static Website <br> 2) Dynamic Website <br> 3) Custome build Web Applications.</b></p>
                            <p class="text-light-white"><b>Client peace of mind</b><br>
                                For client peace of mind, all websites are made available to view online during our production process allowing clients to view progress and to give us feedback reports. This is a similar process to producing proofs of traditional printed information.</p>
<hr>
                            <h5 class="text-custom-black fw-600">Phase in Website Development</h5>
                            <p class="text-light-white">
                              <b><i>We implement your website in 7 phases.</i></b><br>
                                1) Analysis of your current working situation.<br>
                                2) Specification<br>
                                3) Design & Development<br>
                                4) Content Writing<br>
                                5) Coding & Testing<br>
                                6) SEO & Promotion<br>
                                7) Maintenance & Upgrades.<br>
                            </p>
                            <!--<blockquote class="mb-xl-20">
                                <p class="text-custom-black fw-600">@Sometimes by accident, sometimes on purpose with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page maker including versions of lorem ipsum. Sometimes by accident, sometimes on purpose."</p>
                            </blockquote>-->
                        </div>
                    </div>
                </article>
                <!-- article -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <!-- About One section-->
        <section class="section-padding about-us-sec p-relative">
            <div class="side-lines right-side"> <span class="box"></span>
                <span class="text">Website</span>
                <span class="line"></span>
            </div>
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                        <div class="about-left-side-1">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h3 class=" fw-700 text-dark">FIRST CLIENT OF OCEAN SOFTTECH</h3>
                                </div>
                            </div>
                            <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                                Tech-Emerging Engineering Nagpur is our first website client, it also a startupp company like us in 2009. we developed this website in .NET 3.5 framework & it takes 3 months to online.<br><br>
                                <a href="https://www.tech-emergingengg.com/" target="_blank">Tech-Emerging Engineering Nagpur</a>
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height">
                            <a href="https://www.tech-emergingengg.com/" target="_blank"><img src="assets/images/clients/TechEmg.png" class="img-fluid image-fit" alt="First Client"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container about-sec-second">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height ">
                        <a href="https://bs2solutions.com/" target="_blank"><img src="assets/images/clients/BS2Web.png" alt="New ocean Client"></a>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg">
                        <div class="about-left-side-1 p-relative">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h3 class=" fw-700 text-dark">SKILLS</h3>
                                </div>
                            </div>
                            <div class="company-progress">
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">HTML <span>95%</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Responsive<span>95%</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Happy Customers<span>96%</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                                <div class="progress-item">
                                    <label class="text-custom-black fs-16 fw-600">Team Size Department<span>10</span>
                                    </label>
                                    <div class="progress">
                                        <div class="progress-bar progress-bar-striped active"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--About-two-section-end-->

        <!--LOANLY-advertisement-->
        <section class="section-padding LOANLY-advertisement parallax">
            <div class="row">
                <div class="col-12">
                    <div class="advertisement-text">
                        <p class=" text-white fs-20">Fast Approval - Less Fee</p>
                        <h3 class="text-white">We are with you every step .</h3>
                        <a routerLink="/service" class="btn-first btn-submit fw-600 mb-xs-20">Our Services</a>
                        <a routerLink="/contactus" class="btn-first btn-submit-fill fw-600 ml-2 mb-xs-20">Start Now</a>
                    </div>
                </div>
            </div>
        </section>
        <!-- LOANLY-advertisement end -->
        <section class="section-padding our_articles">
            <div class="container">
                <div class="section-header">
                    <div class="section-heading">
                        <h3 class="text-custom-black fw-700">Website Design Price Plans</h3>
                        <div class="section-description">
                            <p class="text-light-white" style="text-align: justify;">Our Server Hosting located at Nagpur & Bengaluru in Indian Datacenter is built for those who require complete control, superpower, and high-end security. OceanSoftTech has all high-end M.2 SSD shared servers hosting that are your ready solutions to any business-critical challenge. our low-cost VPS servers always come with Tireless Support.</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 no-padding">
                        <div class="blog-slider" style="text-align: center;">
                            <article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Starter</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>Single Page Responsive Website</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">3999/- ₹</b><br>
                                            Single Page, Mobile Responsive Layout
                                            Contact Form, Google Map, SEO Friendly
                                            Social Media Integration.<br><br>
                                            <b>Angular 10</b><br><br>
                                            Node.js<br><br>
                                            MSSQL 2019 R2<br><br>
                                            5-10 Working Days Delivery Time
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Corporate</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>Five Page Responsive Website</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">5999/- ₹</b><br>
                                            5 Pages, Mobile Responsive Layout
                                            Image Slider, Image Gallery
                                            Contact Form, Newsletter Subscription
                                            Google map, SEO Friendly
                                            Social Media Integration.<br><br>
                                            <b>Angular 10</b><br><br>
                                            Node.js<br><br>
                                            MSSQL 2019 R2<br><br>
                                            8-15 Working Days Delivery Time
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Premium</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>Eight Page Responsive Website</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">8999/- ₹</b><br>
                                            8 Pages, Mobile Responsive Layout,
                                            Image Slider, Image Gallery, Video Gallery,
                                            Testimonials, Blog, Contact Form, Live Chat,
                                            Newsletter Subscription, Google map,
                                            SEO Friendly, Social Media Integration,
                                            Google Analytics, SSL For 1 Year Free,
                                            Free Logo, Free Basic SEO Submission,
                                            Editable Admin Section for 5 Dynamic Pages
                                            <br><br>
                                            <b>Angular 10</b><br>
                                            Node.js<br>
                                            MSSQL 2019 R2<br>
                                            10-20 Working Days Delivery Time
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article class="post col-12">
                                <div class="single-blog">
                                    
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">Custom Web Application</h2>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b>Custom Web Application</b>
                                        </p>
                                        <hr>
                                        <p class="text-light-white" style="text-align: center;">
                                            <b style="font-size:larger;">-/- ₹ </b><br>
                                            Matrimonial Website Application,
                                            Government & Private Org Web Application,
                                            Online Event Web Application,
                                            Online Booking Web Application,
                                            Media, Press News & TV News Channel Application,
                                            Website Application With Payments Gateway<br><br>
                                            <b>Angular 10</b><br><br>
                                            Node.js<br><br>
                                            MSSQL 2019 R2<br><br>
                                            Depends Upon Project Delivery Time
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <!--<article class="post col-12">
                                <div class="single-blog">
                                    <div class="post-meta">
                                        <div class="post-date"><a routerLink="/blog-detail" class="text-custom-white"> 13, Nov</a>
                                        </div>
                                    </div>
                                    <div class="blog-photo">
                                        <img src="assets/images/blog/blog3.jpg" class="full-width" alt="#">
        
                                    </div>
                                    <div class="post-contnet">
                                        <h2 class="post-title text-custom-black">How Is Mediation Useful in Home Loan Cases?</h2>
                                        <p class="text-light-white">Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis.</p>
                                        <div class="post-author"> <span class="text-custom-black fw-500 fs-14">Post by <a routerLink="/blog-detail" class="text-light-blue">Jhone Deo</a></span>
                                        </div>
                                    </div>
                                    <div class="blog-overlay">
                                        <div class="right-text-effect post-content">
                                            <h2 class="post-title"><a routerLink="/blog-detail" class="text-white">How Is Mediation Useful in Home Loan Cases?</a></h2>
                                            <p>Lorem ipsum dolor sit in faucibus ipsum. Cras euismod faucibus sagittis. Cras euismod faucibus sagittis.</p>
                                        </div>
                                        <div class="blog-read-more-icon">
                                            <div class="post-author"> <span class="text-custom-white fw-600 fs-14">Post by <a routerLink="/blog-detail" class="text-white">Jhone Deo</a></span>
                                            </div> <a routerLink="/blog-detail" class="fw-500 fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Start Website Clients -->
        <section class="section-padding doctors-team  style-2 p-relative">
            <div class="side-lines"> <span class="box"></span>
                <span class="text">Clients</span>
                <span class="line"></span>
            </div>
            <div class="container">
                <div class="section-header">
                    <div class="section-heading">
                        <h3 class="text-custom-black fw-700">Some of Our Website Clients</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 no-padding">
                        <div class="doctors-slider">
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Tech.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://www.tech-emergingengg.com" target="_blank" class="text-custom-black fw-700">Tech-Emerging Engineering</a></h6>
                                        <p class="text-light-white">2009</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Pench.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://www.mahapenchtiger.com" target="_blank" class="text-custom-black fw-700">Pench Tiger Reserve</a></h6>
                                        <p class="text-light-white">2011</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Kend.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://kendriyamanavadhikar.com/" target="_blank" class="text-custom-black fw-700">Kendriya Manavadhikar</a></h6>
                                        <p class="text-light-white">2013</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/AdityaAnagha.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://www.adityaanagha.com/" target="_blank" class="text-custom-black fw-700">Aditya-Anagha</a></h6>
                                        <p class="text-light-white">2014</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Aeit.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="/website-design" target="_blank" class="text-custom-black fw-700">AEIT</a></h6>
                                        <p class="text-light-white">2015</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/sonekarlogo.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="http://sonekarcop.org.in/" target="_blank" class="text-custom-black fw-700">Sonekar College of Pharmacy</a></h6>
                                        <p class="text-light-white">2016</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/NNTR.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://www.mahanntr.com" target="_blank" class="text-custom-black fw-700">Nagzira Tiger Reserve</a></h6>
                                        <p class="text-light-white">2017</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Ggeneralavari.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://www.generalavari.com/" target="_blank" class="text-custom-black fw-700">Mancharsha Rustomji Avari</a></h6>
                                        <p class="text-light-white">2018</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Omsatya.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="http://omsatyasai.com/" target="_blank" class="text-custom-black fw-700">Om Satyasai College</a></h6>
                                        <p class="text-light-white">2019</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/Bs2.png" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://bs2solutions.in" target="_blank" class="text-custom-black fw-700">BS2 Solution's</a></h6>
                                        <p class="text-light-white">2020</p>
                                    </div>
                                </div>
                            </div>
                            <div class="slide-item col-12">
                                <div class="doctor-item">
                                    <div class="doctor-img mb-xl-20">
                                        <img src="assets/images/clients/preLogo.jpg" class="full-width" alt="image">
                                    </div>
                                    <div class="doctor-content">
                                        <h6><a href="https://prestylengineering.com/" target="_blank" class="text-custom-black fw-700">Prestyl Engineering Services</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Website Clients -->
        <app-footer></app-footer>