<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 class="text-custom-white lh-default fw-600">I-BANK 4.0 CBS PRICING PLAN</h2>
                        <h6 style="color:white;">B2B2C Application For Multi-State Society, Credit Cooperative Society, Employee Cooperative Society, Nidhi Company, Micro Fin & NBFC.</h6>
                        <h6 style="color:white">The World’s Most Power & Popular Free Core Banking Software (CBS) for your organization.</h6>
                        <h6 style="color:white">"I-Bank 4.0" CBS is free for New Copoperative Society till <b>"first 999 Accounts or first 9999 Transactions or 6<sup>st</sup> Months"</b></h6>
                        <h6 style="color:white">No Hidden Cost</h6>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">I-Bank 4.0 Price Plan</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper" style="color: black;">
                <div class="blog-meta">
                    <!-- <div class="language-box">
                    <div class="language p-relative">
                    <select >
                        <option>For India & SAARC Countries</option>
                        <option>For USA, UK, Canada, Australia, & New Zealand</option>
                        <option>For the Middle East</option>
                        <option>For European union</option>
                        <option>For African countries</option>
                    </select>
                    </div>
                    </div> -->
                    <table id="customers" style="font: bold; font-family: Verdana; font-size: small; text-align: center">
                        <tbody>
                            <tr>
                                <td colspan="4"><b id="TableTitle">Price Plan For India</b></td>
                            </tr>
                        <tr style="font-weight: bold; color: red; font-size: small">
                        <td>I-Bank 4.0 Plan - 1</td>
                        <td>I-Bank 4.0 Plan - 2</td>
                        <td>I-Bank 4.0 Plan - 3</td>
                        <td>I-Bank 4.0 Plan - 4</td>
                        </tr>
                        <tr>
                        <td>(B2B) Application</td>
                        <td>(B2B) Application</td>
                        <td>(B2B) Application</td>
                        <td>(B2B) Application</td>
                        </tr> <tr style="font-weight: bold; color: blue; font-size: smaller">
                            <td>10 to 1000 Member's</td>
                            <td>1001 to 5000 Member's</td>
                            <td>5001 to 10000 Member's</td>
                            <td>10001 to 50000 Member's</td>
                            </tr>
                        <tr style="font-weight: bold; color: red; font-size: smaller">
                        <td>Price</td>
                        <td>Price</td>
                        <td>Price</td>
                        <td>Price</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                            <td colspan="4"><b id="TableTitle"><h4 style="color: red;">Yearly Price</h4></b></td>
                        </tr>
                        <tr style="font-size: smaller">
                        <td><b>15,000/-Rs.<br>
                        Per Year</b></td>
                        <td><b>22,000/-Rs.<br>
                        Per Year</b></td>
                        <td><b>24,000/-Rs.<br>
                        Per Year</b></td>
                        <td><b>28,000/-Rs.<br>
                        Per Year</b></td>
                        </tr>
                        <tr>
                            <td colspan="4"><h5>OR</h5></td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                            <td colspan="4"><b><h4 style="color: red;">Monthly Price</h4></b></td>
                        </tr>
                        <tr style="font-size: smaller">
                            <td><b>1,500/-Rs.<br>
                            Per Month</b></td>
                            <td><b>2,200/-Rs.<br>
                            Per Month</b></td>
                            <td><b>2,400/-Rs.<br>
                            Per Month</b></td>
                            <td><b>2,800/-Rs.<br>
                            Per Month</b></td>
                            </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                            <td colspan="4"><b id="TableTitle">Price for SACCOS In Africa</b></td>
                        </tr>
                        <tr style="font-size: smaller">
                            <td><b>25 $<br>
                            Per Month</b></td>
                            <td><b>50 $<br>
                            Per Month</b></td>
                            <td><b>60 $<br>
                            Per Month</b></td>
                            <td><b>900 $<br>
                            Per Year</b></td>
                            </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Application Master</td>
                        <td>Application Master</td>
                        <td>Application Master</td>
                        <td>Application Master</td>
                        </tr>
                        <tr>
                        <td>Schedule, Director, Agent Master, KYC Master</td>
                        <td>Schedule, Director, Agent Master, KYC Master</td>
                        <td>Schedule, Director, Agent Master, KYC Master</td>
                        <td>Schedule, Director, Agent Master, KYC Master</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Different Type of Accounts</td>
                        <td>Different Type of Accounts</td>
                        <td>Different Type of Accounts</td>
                        <td>Different Type of Accounts</td>
                        </tr>
                        <tr>
                        <td>(Share, Deposit, Loan, General, Bank)</td>
                        <td>(Share, Deposit, Loan, General, Bank)</td>
                        <td>(Share, Deposit, Loan, General, Bank)</td>
                        <td>(Share, Deposit, Loan, General, Bank)</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Transaction</td>
                        <td>Transaction</td>
                        <td>Transaction</td>
                        <td>Transaction</td>
                        </tr>
                        <tr>
                        <td>(Voucher/Transfer Entry)</td>
                        <td>(Voucher/Transfer Entry)</td>
                        <td>(Voucher/Transfer Entry)</td>
                        <td>(Voucher/Transfer Entry)</td>
                        </tr>
                        <tr>
                            <td>Transaction Correction</td>
                            <td>Transaction Correction</td>
                            <td>Transaction Correction</td>
                            <td>Transaction Correction</td>
                            </tr>
                        <tr>
                        <td>-</td>
                        <td>(Branch CBS - Receipt)</td>
                        <td>(Branch CBS - Receipt)</td>
                        <td>(Branch CBS - Receipt)</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>Branch CBS By H/O to any Account (Receipt/Payment)</td>
                        <td>Branch CBS By H/O to any Account (Receipt/Payment)</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>Standing Instruction Execution</td>
                        <td>Standing Instruction Execution</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>NEFT/RTGS*</td>
                        <td>NEFT/RTGS*</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Daily Reports</td>
                        <td>Daily Reports</td>
                        <td>Daily Reports</td>
                        <td>Daily Reports</td>
                        </tr>
                        <tr>
                        <td>Day Book, Sub Daybook</td>
                        <td>Cash Book, Day Book, Sub Daybook</td>
                        <td>Cash Book, Day Book, Sub Daybook</td>
                        <td>Cash Book, Day Book, Sub Daybook</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Reports</td>
                        <td>Reports</td>
                        <td>Reports</td>
                        <td>Reports</td>
                        </tr>
                        <tr>
                        <td>25 Difference Types of Report</td>
                        <td>100 Difference Types of Report</td>
                        <td>165 Difference Types of Report</td>
                        <td>165 Difference Types of Report</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>User Dynamic Report</td>
                        <td>User Dynamic Report</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>MIS Reports (N-Type)</td>
                        <td>MIS Reports (N-Type &amp; N-ScheduleWise)</td>
                        <td>MIS Reports (N-Type &amp; N-ScheduleWise)</td>
                        <td>MIS Reports (N-Type &amp; N-ScheduleWise)</td>
                        </tr>
                        <tr>
                        <td>(N-Type Balance Sheet, Receipt &amp; Payment, Profit &amp; Loss, NPA)</td>
                        <td>(N-Type &amp; N-ScheduleWise Balance Sheet, Receipt &amp; Payment, Profit &amp; Loss, NPA)</td>
                        <td>(N-Type &amp; N-ScheduleWise Balance Sheet, Receipt &amp; Payment, Profit &amp; Loss, NPA)</td>
                        <td>(N-Type &amp; N-ScheduleWise Balance Sheet, Receipt &amp; Payment, Profit &amp; Loss, NPA)</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>Graphical Reports</td>
                        <td>Graphical Reports</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Interest Posting</td>
                        <td>Interest Posting</td>
                        <td>Interest Posting</td>
                        <td>Interest Posting</td>
                        </tr>
                        <tr>
                        <td>Deposits, Loan, Dividend Interst Calculation</td>
                        <td>Deposits, Loan, Dividend Interst Calculation</td>
                        <td>Deposits, Loan, Dividend Interst Calculation</td>
                        <td>Deposits, Loan, Dividend Interst Calculation</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Manager Level Control</td>
                        <td>Manager Level Control</td>
                        <td>Manager Level Control</td>
                        <td>Manager Level Control</td>
                        </tr>
                        <tr>
                        <td>1 Users In Branch</td>
                        <td>4 Users In Branch</td>
                        <td>5 Users In Branch</td>
                        <td>6 Users In Branch</td>
                        </tr>
                        <tr>
                        <td>1 Operator Login</td>
                        <td>2 Operator Login</td>
                        <td>3 Operator Login</td>
                        <td>4 Operator Login</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>1 Passing Login</td>
                        <td>1 Passing Login</td>
                        <td>1 Passing Login</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>1 Manager Login</td>
                        <td>1 Manager Login</td>
                        <td>1 Manager Login</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Utility</td>
                        <td>Utility</td>
                        <td>Utility</td>
                        <td>Utility</td>
                        </tr>
                        <tr>
                        <td>Year End Procedure</td>
                        <td>Year End Procedure</td>
                        <td>Year End Procedure</td>
                        <td>Year End Procedure</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>SMS*</td>
                        <td>SMS*</td>
                        <td>SMS*</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>EMail Statement</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Print</td>
                        <td>Print</td>
                        <td>Print</td>
                        <td>Print</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td rowspan="2">Passbook or FD Certificate</td>
                        <td>Passbook</td>
                        <td>Passbook</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        
                        <td>FD Certificate</td>
                        <td>FD Certificate <br> Share Certificate</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Loan NOC Letter</td>
                        </tr>
                        <tr>
                            <td colspan="4"><b>Daily / Pigmy (Deposit) Collection App </b> <a routerLink="/i-bank-price" fragment="AgentApp" class="fw-500 fs-14"> Read More</a></td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>2 App</td>
                        <td>4 App</td>
                        <td>10 App</td>
                        </tr>
                        <tr>
                            <td colspan="4"><b>Customer Android App </b> <a routerLink="/i-bank-price" fragment="AgentApp" class="fw-500 fs-14"> Read More</a></td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>50 App</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Database Size</td>
                        <td>Database Size</td>
                        <td>Database Size</td>
                        <td>Database Size</td>
                        </tr>
                        <tr>
                        <td>1 GB<br>
                        (DB + Documents)</td>
                        <td>2 GB<br>
                        (DB + Documents)</td>
                        <td>3 GB<br>
                        (DB + Documents)</td>
                        <td>4 GB<br>
                        (DB + Documents)</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>Database extended up to 10 GB</td>
                        <td>Database extended up to 10 GB</td>
                        <td>Database extended up to 10 GB</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>Bandwidth</td>
                        <td>Bandwidth</td>
                        <td>Bandwidth</td>
                        <td>Bandwidth</td>
                        </tr>
                        <tr>
                        <td>10 GB/Month</td>
                        <td>20 GB/Month</td>
                        <td>40 GB/Month</td>
                        <td>Unlimited</td>
                        </tr>
                        <tr>
                        <td>-</td>
                        <td>1000 Rs/year for extra 1 GB Database</td>
                        <td>1000 Rs/year for extra 1 GB Database</td>
                        <td>1000 Rs/year for extra 1 GB Database</td>
                        </tr>
                        </tbody>
                    </table>
                    <b>* Extra transaction SMS & WhatApp pack is required<br>
                    ** Static IP required<br>
                    ** 2 MBPS broadband plan required</b><br>
                    <hr id="AgentApp">
                    <h4 style="text-align: center;"><a routerLink="/mobile-app">Agents / Customers Mobile App Provide in I-Bank 4.0 CBS</a></h4>
                    <div>
                        <b>Daily / Pigmy + RD (Deposit) Agent Collection App</b>
                        <a routerLink="/mobile-app" class="fw-500 fs-14"> Read More...</a>
                    </div>
                    <table  class="uvc-main-heading ult-responsive" style="font: bold; font-family: Verdana; font-size: small; text-align: center">
                        <tbody><tr style="font-weight: bold; color: red; font-size: small">
                        <td>Sr. No.</td>
                        <td>Description</td>
                        <td>Users</td>
                        <td>Cost</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>1</td>
                        <td>Android App For Pigmy Collection *</td>
                        <td>1</td>
                        <td>
                            1200/- Rs. Per User Per Year<br>
                            15 $ Per User Per Year
                        </td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>2</td>
                        <td>Android App For Customer **</td>
                        <td>1</td>
                        <td>
                            75/- Rs. Per User Per Year<br>
                            1.5 $ Per User Per Year
                        </td>
                        </tr>
                        </tbody>
                    </table>

                    <div>
                        <b>(Daily / Pigmy + RD) Deposit & (Regular Loan) Loan <br> (Multi GL) Agent Collection App</b>
                    </div>
                    <table  class="uvc-main-heading ult-responsive" style="font: bold; font-family: Verdana; font-size: small; text-align: center">
                        <tbody><tr style="font-weight: bold; color: red; font-size: small">
                        <td>Sr. No.</td>
                        <td>Description</td>
                        <td>Users</td>
                        <td>Cost</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>1</td>
                        <td>Android App For Deposit & Loan Collection *</td>
                        <td>1</td>
                        <td>
                            1800/- Rs. Per User Per Year<br>
                            22 $ Per User Per Year
                        </td>
                        </tr>
                        </tbody>
                    </table>
                    <br>
                   <b> * Minimum 10 Agents required<br>
                    ** Minimum 200 Users required<br>
                    Implementation Period 15 Working Days<br></b>
                    <hr>
                    <div>
                        <b>NEFT/RTGS Implementation Cost</b>
                    </div>
                    <table id="customers" class="uvc-main-heading ult-responsive" style="font: bold; font-family: Verdana; font-size: small; text-align: center">
                        <tbody><tr style="font-weight: bold; color: red; font-size: small">
                        <td colspan="2">Charges for (Bank/Society/Nidhi Comany) to Banker/Provider</td>
                        <td colspan="2">Charges for (Bank/Society/Nidhi Comany) to Ocean</td>
                        </tr><tr style="font-weight: bold; font-size: smaller">
                        <td rowspan="2">Minimum Average Balance</td>
                        <td>Charges For Per Transaction</td>
                        <td colspan="2">Monthly Charges</td>
                        </tr>
                        <tr style="font-weight: bold; font-size: smaller">
                        <td>RTGS/NEFT</td>
                        <td colspan="2">RTGS/NEFT</td>
                        </tr>
                        <tr>
                        <td>As Per Sponsor Bank Rules</td>
                        <td>As Per Sponsor Bank Rules</td>
                        <td colspan="2">500/- Per Month Per Branch</td>
                        </tr>
                        </tbody>
                    </table>
                    <br>
                    * For more details about RTGS/NEFT please concern with sponsor bank.<br>
                    * RTGS/NEFT transactions as per the terms and agreement of Sponsor Bank.<br>
                    * Implementation Period 90 Working Days.<br>
                    * KYC Norms As Per RBI is Mandatory for these Services.<br>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Subheader -->
<!-- Start About Us Intro -->
<section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta">
                    <div style="text-align: center;">
                        <h2><a routerLink="#" class="text-custom-black fw-600">Modules of Ocean I-Bank 4.0 (CBS)</a></h2>
                        <h4>Help to understand "I-Bank 4.0" CBS better</h4>
                        
                    </div>
                    <div class="lw-about-right-list">
                    <div >
                        <h4>Master Entry</h4>
                        <ul >
                        <li> <i class="fas fa-check"></i>
                            <b>BRANCH MASTER</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Create/Update Branch Data)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>GENERAL LEDGER</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Create 899 GL)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>PARTY/MEMBER MASTER</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(KYC DETAILS)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>AGENT MASTER</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For Daily/RD Collection)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>DIRECTOR MASTER</b><br>&nbsp;</li>
                        <li> <i class="fas fa-check"></i>
                            <b>SCHEDULE MASTER</b><br>&nbsp;</li>
                        <li> <i class="fas fa-check"></i>
                            <b>EMPLOYEE MASTER</b><br>&nbsp;</li>
                        <li> <i class="fas fa-check"></i>
                            <b>IP MASTER</b><br>&nbsp;</li>
                        <li> <i class="fas fa-check"></i>
                            <b>USER MENU AUTHORIZATION</b><br>&nbsp;</li>
                        </ul>
                    </div>
                    <hr>
                    <div >
                        <h4>All Accounts In I-Bank 4.0</h4>
                        <ul >
                        <li> <i class="fas fa-check"></i>
                            <b>SHARE ACCOUNT OPENING</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(REGULAR/NOMINAL SHARE)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>DEPOSIT ACCOUNT OPENING</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(SAVING/CURRENT/RD/FD/DAILY/LOCKER/FD-MIS)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>LOAN ACCOUNT OPENING</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(REGULAR/EMERGENCY/GOLD/HOUSING/VEHICLE/BUSINESS)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>AGENT MASTER</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For Daily/RD Collection)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>GENERAL ACCOUNT OPENING</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(DIVIDENET/AGENT/SUSPENSE/SECURITY/SALARY/WAGES)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>BANK ACCOUNT OPENING</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(BANK FD/OVERDRAFT/BANK LOAN/BANK RD)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>ALL ACCOUNT PASSING</b><br>&nbsp;</li>
                        <li> <i class="fas fa-check"></i>
                            <b>STANDING INSTRUCTION</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Saving Acc. >> RD Acc. / Saving Acc. >> Laon Acc. / FD Intt. >> Saving Acc.)</li>
                        </ul>
                    </div>
                    <hr>
                    <div>
                        <h4>Daily Account / Pigmy Account in I-Bank 4.0</h4>
                        <ul >
                            <li> <i class="fas fa-check"></i>
                                <b>PIGMY ACCOUNT OPENING BY AGENT WISE</b>
                            </li>
                            <li> <i class="fas fa-check"></i>
                                <b>PIGMY AGENT TRANSACTION</b></li>
                            <li> <i class="fas fa-check"></i>
                                <b>POST AGENT TRANSACTION</b></li>
                            <li> <i class="fas fa-check"></i>
                                <b>MOBILE APP FOR AGENT</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For Daily/RD Collection)</li>
                            <li> <i class="fas fa-check"></i>
                                <b>AGENT CUT BOOK FOR PIGMY ACOOUNT</b></li>
                            <li> <i class="fas fa-check"></i>
                                <b>AGENT COMMISSION POSTING</b></li>
                            <li> <i class="fas fa-check"></i>
                                <b>MOBILE APP FOR AGENT (Multi GL)</b></li>
                            </ul>
                    </div>
                    <hr>
                    <div >
                        <h4>All Transactions In I-Bank 4.0</h4>
                        <ul >
                        <li> <i class="fas fa-check"></i>
                            <b>BRANCH DAY BIGEN (MANUAL/AUTOMATIC)</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CASH OPENING & USER TRANS. AUTHORITY</li>
                        <li> <i class="fas fa-check"></i>
                            <b>VOUCHER TRANSFER ENTRY</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(VOUCHER / TRANSFER / CBS RECEIPT / HO-CBS RECEIPT-PAYMENT / &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NEFT / RTGS / CASH RECONCILIATION)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>PASSING TRANSACTION</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(VOUCHER / TRANSFER / CBS RECEIPT / HO-CBS RECEIPT-PAYMENT / &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NEFT / RTGS / CASH RECONCILIATION) FOR PASSING OFFICER</li>
                        <li> <i class="fas fa-check"></i>
                            <b>CASH RECONCILIATION</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(MOVE CASH TO ALL UESRS)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>CASHIER PASSING</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(VOUCHER / TRANSFER / CBS RECEIPT / HO-CBS RECEIPT-PAYMENT / &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NEFT / RTGS / CASH RECONCILIATION) FOR PASSING BY CASHIER</li>
                        <li> <i class="fas fa-check"></i>
                            <b>BRANCH DAY END</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(CHECK ALL PENDING TRANSACTION / STANDING INSTRUCTION / &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CLOSING BALANCE CASH TO ALL UESRS & BRANCH)</li>
                        </ul>
                    </div>
                    <hr>
                    <div >
                        <h4>Daily & MIS Reports</h4>
                        <ul >
                        <li> <i class="fas fa-check"></i>
                            <b>CASH BOOK</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Types I, II, III, IV & V (Branchwise & All Branches)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>DAY BOOK</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Types I, II, III, IV & V (Branchwise & All Branches)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>SUBDAY BOOK</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Branchwise & All Branches)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>CUT BOOK</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Branchwise & All Branches)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>LOAN REPORTS</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There are 25+ different Loan reports in this section.</li>
                        <li> <i class="fas fa-check"></i>
                            <b>DEPOSIT REPORTS</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There are 20+ different Deposit reports in this section.</li>
                        <li> <i class="fas fa-check"></i>
                            <b>ACCOUNT STATEMENT</b><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Generate Any Account Statment.</li>
                        <li> <i class="fas fa-check"></i>
                            <b>PARTYWISE REPORT</b><br>Show All Accounts & Closing Balance.</li>
                        <li> <i class="fas fa-check"></i>
                            <b>SHARE REPORTS</b><br>There are 10+ different Share reports in this section.</li>
                        <li> <i class="fas fa-check"></i>
                            <b>MIS REPORTS</b><br>TRIAL BALANCE, RECEIPT PAYMENT, BALANCESHEET, PROFIT LOSS, TERIJ PATRAK, CD RATIO.
                            All Reports Comewith (N-TYPE & Shedulewise, Branch Wise & Consolidated report)</li>
                        <li> <i class="fas fa-check"></i>
                            <b>GENERAL REPORTS</b><br>ELECTION LIST, FUND PERFORMANCE REPORT, DEAD STOCK REGISTER, STOP PAYMENT REPORT & ETC.</li>
                        <li> <i class="fas fa-check"></i>
                            <b>ACCOUNT OPEN / CLOSE REGISTER</b></li>
                        <li> <i class="fas fa-check"></i>
                            <b>TRANSACTION REPORT BRANCH & USERWISE</b></li>
                        <li> <i class="fas fa-check"></i>
                            <b>GENERAL LEDGER STATEMENT</b><br>GENERALLEDGER STATEMENT DETAILS (Branchwise & All Branches)</li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </article>
        <!-- article -->

        <hr class="mt-0">
                        <!-- comments -->
                        <div class="comment-box section-padding-top p-relative">
                            <div id="comment-box">
                                <div class="comment-respond" id="respond">
                                    <h4 class="text-custom-black fw-600">E-Mail For Demo</h4>
                                    <form [formGroup]="form" id="contactForm">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-custom-black fw-600 fs-14">Full Name</label>
                                                    <input type="text" name="name" id="name" class="form-control" placeholder="Your Name"formControlName="Name">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-custom-black fw-600 fs-14">Email I'd</label>
                                                    <input type="email" name="email" id="email" class="form-control" placeholder="Your Email" formControlName="Email">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-custom-black fw-600 fs-14">Name of Company</label>
                                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control"placeholder="Your Subject" formControlName="Subject">
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="text-custom-black fw-600 fs-14">Contact No. / Mob. No.</label>
                                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone" formControlName="phone_number">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label class="text-custom-black fw-600 fs-14">Subject</label>
                                                    <textarea rows="4" class="form-control form-control-custom" name="msg_subject" id="msg_subject" placeholder="Your Subject" formControlName="Subject"></textarea>
                                                </div>
                                                <button type="submit" (click)="Email_send()" class="btn-first btn-submit text-light-blue">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- comments -->
    </div>
</section>

<!-- End About Us Intro -->
<app-footer></app-footer>