import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  SpResult: any;
  eventList: any = [];
  eventList1: any = [];
  table1: any;
  table2: any;
//  y: any;
  ext: any;
  loading2=false;

  constructor(private fb: FormBuilder, private mainService: MainService) { }

  ngOnInit(): void {
    this.publicList();
  }

  publicList() {
    this.loading2 = true;
    const MoObj: any = {};
    MoObj.Pid = "9a45aa5a-b901-416d-b760-1401b901384b";
    MoObj.PkWebSite_News = 0;
    this.mainService.TwoMethod(MoObj).then(value => {
      this.SpResult = value;
      console.log(this.SpResult)
      this.table1 = this.SpResult.table
      this.table2 = this.SpResult.table1
      this.loading2 = false;
      for (let i = 0; i < this.table1.length; i++) {
        var newspk = this.table1[i].pkWebSite_News;
        let newsdesc = this.table1[i].newsDec;
        let fromdate = this.table1[i].fromDate;
        let todate = this.table1[i].toDate;
        this.table1[i]['desc'] = newsdesc;
        this.table1[i]['fdate'] = fromdate;
        this.table1[i]['tdate'] = todate;
        this.eventList.push(this.table1[i]);
        for (let j = 0; j < this.table2.length; j++) {
          let imgfk = this.table2[j].fkWebSite_News;
          let img = this.table2[j].folderPath;
          let x = this.table2[j].newsPhotoPath;
          this.ext = x.split('.').pop();
          this.table2[j]['iext'] = this.ext;
          if (newspk == imgfk) {
            this.table2[j]['image'] = img;
            this.eventList1.push(this.table2[j]);
          }
        }
      }
      // alert(this.ext)
    });
  }
}
