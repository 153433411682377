import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
// import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
// import { map, Observable } from 'rxjs';
// import { Post } from '../services/post';
// import { PostserviceService } from '../services/postservice.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
