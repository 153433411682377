import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Homepage1Component } from './components/pages/homepage1/homepage1.component';
import { Homepage2Component } from './components/pages/homepage2/homepage2.component';
import { FinanceComponent } from './components/pages/finance/finance.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogLeftSidebarComponent } from './components/pages/blog-left-sidebar/blog-left-sidebar.component';
import { BlogDetailComponent } from './components/pages/blog-detail/blog-detail.component';
import { Error404Component } from './components/pages/error404/error404.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { OurTeamComponent } from './components/pages/our-team/our-team.component';
import { LoanCalculatorComponent } from './components/pages/loan-calculator/loan-calculator.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ContactoceanComponent} from './components/pages/contactocean/contactocean.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceDetailComponent } from './components/pages/service-detail/service-detail.component';
import { UserDashboardComponent } from './components/pages/user-dashboard/user-dashboard.component';
import { LoanStepsComponent } from './components/pages/loan-steps/loan-steps.component';
import { IBankInfoComponent} from './components/pages/i-bank-info/i-bank-info.component';
import { IBankPriceComponent} from './components/pages/i-bank-price/i-bank-price.component';
import { ServerVpsComponent} from './components/pages/server-vps/server-vps.component';
import { ServerDedicatedComponent } from './components/pages/server-dedicated/server-dedicated.component';
import { CreditCooperativeSocietyComponent} from './components/pages/credit-cooperative-society/credit-cooperative-society.component';
import { MultistateSocietyComponent } from './components/pages/multistate-society/multistate-society.component';
import { MicroFinanceNidhiComponent} from './components/pages/micro-finance-nidhi/micro-finance-nidhi.component';
import { MobileBankingComponent} from './components/pages/mobile-banking/mobile-banking.component';
import { WebsiteDesignComponent } from './components/pages/website-design/website-design.component';
import { WebsiteHostingComponent } from './components/pages/website-hosting/website-hosting.component';
import { CareerComponent} from './components/pages/career/career.component';
import { VacanciesComponent} from './components/pages/vacancies/vacancies.component';
import { Covid19Component } from './components/pages/covid19/covid19.component';
import { NewsComponent } from './components/pages/news/news.component';
import { LoginComponent } from './components/pages/login/login.component';
import { TestingComponent } from './components/pages/testing/testing.component';
import { NidhiCompanyRegitrationComponent } from './components/pages/nidhi-company-regitration/nidhi-company-regitration.component';
import { LegalDisclaimerComponent } from './components/pages/legal-disclaimer/legal-disclaimer.component';
import { DATAPROTECTIONPOLICYComponent } from './components/pages/data-protection-policy/data-protection-policy.component';
import { MobileAppComponent } from './components/pages/mobile-app/mobile-app.component';
import { FinanceUitilityComponent } from './components/pages/finance-uitility/finance-uitility.component';
import { InternshipComponent } from './components/pages/internship/internship.component';


const routes: Routes = [

{path: '', component: Homepage1Component},
{path: 'homepage2', component: Homepage2Component},
{path: 'i-bank-info', component:IBankInfoComponent},
{path: 'i-bank-price', component:IBankPriceComponent},
{path: 'finance-uitility', component:FinanceUitilityComponent},
{path: 'server-vps', component:ServerVpsComponent},
{path: 'server-dedicated', component:ServerDedicatedComponent},
{path: 'credit-cooperative-society', component:CreditCooperativeSocietyComponent},
{path: 'multistate-society', component:MultistateSocietyComponent},
{path: 'micro-finance-nidhi', component:MicroFinanceNidhiComponent},
{path: 'mobile-banking', component:MobileBankingComponent},
{path: 'mobile-app',component:MobileAppComponent},
{path: 'website-design',component:WebsiteDesignComponent},
{path: 'website-hosting', component:WebsiteHostingComponent},
{path: 'finance', component: FinanceComponent},
{path: 'blog', component: BlogComponent},
{path: 'career', component: CareerComponent},
{path: 'vacancies', component: VacanciesComponent},
{path: 'blog-left-sidebar', component: BlogLeftSidebarComponent},
{path: 'blog-detail', component: BlogDetailComponent},
{path: '404', component: Error404Component},
{path: 'coming-soon', component: ComingSoonComponent},
{path: 'our-team', component: OurTeamComponent},
{path: 'loan-calculator', component: LoanCalculatorComponent},
{path: 'about-us', component: AboutUsComponent},
{path: 'Conatctocean', component:ContactoceanComponent},
{path: 'service', component: ServiceComponent},
{path: 'service-detail', component: ServiceDetailComponent},
{path: 'loan-dashboard', component: UserDashboardComponent},
{path: 'loan-steps', component: LoanStepsComponent},
{path: 'covid19', component: Covid19Component},
{path: 'news', component: NewsComponent},
{path: 'login', component: LoginComponent},
{path: 'testing', component: TestingComponent},
{path: 'legal-dis', component: LegalDisclaimerComponent},
{path: 'data-protection', component: DATAPROTECTIONPOLICYComponent},
{path: 'news', component:NewsComponent},
{path: 'internship', component:InternshipComponent},
{path: '**', component: Error404Component}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{anchorScrolling:"enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
