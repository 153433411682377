<h1>Enter Form</h1><br><br>
<form [formGroup]="addTest">
    Name <input type="text" formControlName="name" /><br><br>
    Mobno <input type="text" formControlName="mobno" /><br><br>
    Email <input type="text" formControlName="Email" /><br><br>
    DOB <input type="date" formControlName="DOB" ><br><br>
    Photo <input type="file" formControlName="FileUP"><br><br>
    <button (click)="Insert()">Submit</button><br>
    <button (click)="Select()">Submit</button>
</form>  

<div class="container">    
    <div class="table-responsive">   
    
    </div>
  </div>