<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 class="text-custom-white lh-default fw-600">CORE BANKING SYSTEM SOFTWARE FOR</h2>
                        <h4 style="color:white">Credit Cooperative Society, Employee Cooperative Society & Credit Unions</h4>
                        <h4 style="color:white">High Performance, Low Cost, Starting @ ₹19,900/Year/Branch</h4>
                        <h4 style="color:white">I-Bank 4.0 CBS SACCOS @ 25 $ Per Month Per Branch For African Countries</h4>
                        <h6 style="color:white">"I-Bank 4.0" CBS is free for New Copoperative Society till <b style="color: red;">"first 999 Accounts or first 9999 Transactions or 6<sup>st</sup> Months"</b></h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">I-Bank 4.0 CBS SaaS provider with 99% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Json, Javascript, HTML, ASP.NET 4.5 & SqlServer 2012 R2</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Security & Authentication</h6>
                                            <p class="text-custom-white">Static IP & Two-Step Authentication</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Credit Cooperative Society</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
 <!-- Start About Us Intro -->
 <section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta">
                    <h3><a routerLink="#" class="text-custom-black fw-600">Credit Cooperative Society, Employee Cooperative Society & Credit Unions</a></h3>
                    <p class="text-light-white">A Credit Cooperative Society is a member-owned financial cooperative, controlled by its members and operated on the principle of people helping people, providing its member's credit at competitive rates as well as other financial services. Cooperative banking is retail and commercial banking organized on a cooperative basis.</p>
                    <p class="text-light-white">"I-Bank 4.0" Web-Based Core Banking System is the best software for Cooperative Society, Employee Cooperative society & Credit Unions to manage their single & Multiple branches account, transactions & reports.</p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<!-- End About Us Intro -->
<!-- Start Main Services -->
<section class="main-services p-relative section-padding" >
    <div class="side-lines"> <span class="box"></span>
        <span class="text">I-Bank</span>
        <span class="line"></span>
    </div>
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">Some of the Most Important Features</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20" style="text-align: justify;">
                        <div class="icon-box"><i class="bi bi-bank"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Share Accounts</a></h5>
                        <p class="text-light-white no-margin">In the <b>Share account section</b>, we have three types Regular Share, Nominal Share, & Associates Share. Share accounts are permanent members of society and it is used for loan guarantee & director creation. Cooperative society is a financial entity that belongs to its Share members, who are at the same time the owners and the customers of their Society.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-bank2"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Deposit Accounts</a></h5>
                        <p style="text-align: justify;" class="text-light-white no-margin">In <b>Deposit account section</b> of the software we have, all types of deposit account create & manage (Saving, Current, Fixed Deposit FD, Recurring Deposit RD, Pigmy/Daily Deposit, Locker & ETC). A single form is used for creating & editing deposit account. In a deposit account, you can add Agent & director reference when an account is created.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Loan Accounts</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">In the <b>Loan account section</b> of the software we have, all types of loan account create & manage (Regular Loan, Emergency Loan, Housing Loan, Vehicle Loan, CC Loan, deposit Loan & Etc). A single form is used for creating & editing a loan account. You can add photos of all loan documents in the loan account, link the deposit when the account is created.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-lines-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">General Accounts</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            In <b>General Accounts section</b> of the software we have, all types of general accounts create & manage (Agent Suspense, TDS, Bank Account, Employees Salary Account, & other suspense accounts). A single form is used for creating & editing a general account. The general account section is used for creating an account which is not managed in other section.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-currency-exchange"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Transactions</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                         In "I-Bank 4.0" we manage 15 Types of different transactions, Like Voucher, Transfer, CBS Entry, RTGS/NEFT. A mobile App & Collection Machine for Pigmy & Loan Collection for Agents. "I-Bank 4.0" easily connects with all leading company ATM/CDM. "I-Bank 4.0" comes with an Online Banking feature for the customer through a mobile App & Website. Separate section for standing instruction, Day Begin, Cash Allocation, & Day End.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-ui-checks"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Reports</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            "I-Bank 4.0" has all standard reports like Cash-book I & II, Day-book 5 Types, Cut-book, Subdaybook, Account Statement, & G/L Statement. Audit Reports like MIS Report, Trial Balance, Receipt Payment, Balance Sheet, Profit Loss, GL & Cutbook, CBS Transaction, ATM Transaction, App Transaction, and RTGS/NEFT Transaction. All Agent Reports, H/O Reports, Dynamic Report & Graphical Reports. In "I-Bank 4.0" CBS we have 250+ reports.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xs-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-file-spreadsheet"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Uitilities</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            "I-Bank 4.0" CBS provides a wide range of utilities Like SMS (Account. Opening & Transaction), Transaction Delete utility for same day, Transaction Correction, Backdate Transaction entry, Standing Instructions Execution, Stop Transaction, Previous / Current Transaction Voucher printing, passbook printing, FD / Share certificate printing, Branch / Society year-end Remote Account Opening.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-graph-up-arrow"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/credit-cooperative-society" class="text-custom-black">Interest Posting</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            In "I-Bank 4.0 CBS" Interest Posting for Saving Account is very simple. Interest provisions for Fixed Deposit FD & Recurring Deposit RD is done in one click. Interest provision on all types of loans is done in this section. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Main Services -->
	<!-- About One section-->
    <section class="section-padding about-us-sec p-relative">
        <div class="side-lines right-side"> <span class="box"></span>
            <span class="text">Clients</span>
            <span class="line"></span>
        </div>
        <h3 class="text-custom-black fw-700" style="text-align:center;">Types Co-operative Society used our software</h3>
        <div class="container">
            <div class="row no-gutters">
                <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                    <div class="about-left-side-1">
                        <div class="section-header">
                            <div class="section-heading">
                                <h4 class=" fw-700 text-dark">Credit Cooperative Society & SACCOS 40+</h4>
                            </div>
                        </div>
                        <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                            Utthan Credit Cooperative Society. Nagpur<br>
                            Geeta Krishan Cooperative Society. Nagpur<br>
                            Pragti Credit Cooperative Society. Nagpur<br>
                            New Vidharbha Cooperative Society. Nagpur<br>
                            Electirc Bord Credit Cooperative Society<br>
                            Swarna Mahila Credit Cooperative Society. Nagpur<br>
                            Twinkle Credit Cooperative Society. Nagpur<br>
                            
                            Shri-Hari Credit Cooperative Society. Khaperkheda<br>
                            Adarsh mahila Cooperative Society. Khaperkheda<br>
                            Sarthak Credit Cooperative Society. Godhani<br>
                            Om Urban Cooperative Society. Nagpur<br>
                            Shri Miranand Nagari Co-Op Society. Parshivni<br>
                            "Saidharsh Urban Co-op Society” Koradi<br>
                            <br>
                            The BBN NATC Baddi, Himachal Pradesh<br><br>

                            Shree Credit Cooperative Society. Adilabad<br>
                            "AZAD ALPASANKHYATAR PATTINA SAHAKARI SANGHA NIYAMITA" Adihudi, Karnataka.<br>
                            <br>
                            "ADM Financal Freedom” SACCOS in Dar es salaam, Tanzania.<br>
                            "SMEAZ Savings & Credit Cooperative Society Limited” Harare Zimbabwe, Africa.<br>

                        </p>
                        <div class="section-heading">
                            <h4 class=" fw-700 text-dark">Employees Cooperative Society</h4>
                        </div>
                        <p class="text-light-white mb-xl-20" style="text-align: justify;">
                            Electric Board Credit Cooperative Society<br>
                            Organization For Rights Of Human Employee Unions 36 Branches<br>
                        </p>
                        <a routerLink="/i-bank-price" class="btn-first btn-submit-fill fw-600 mb-xl-20"><i class="fas fa-rupee-sign mr-2 fs-16"></i>I-Bank 4.0 CBS Price</a>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="full-height">
                        <img src="assets/images/i-Bank-4.0-Logo.jpg" style="display: block; margin: auto; width: 90%;" alt="about us">
                    </div>
                </div>
            </div>
        </div>
    </section>

   <!-- <section class="section-padding doctors-team  style-2 p-relative">
        <div class="side-lines"> <span class="box"></span>
            <span class="text">Clients</span>
            <span class="line"></span>
        </div>
        <div class="container">
            <div class="section-header">
                <div class="section-heading">
                    <h3 class="text-custom-black fw-700">Some of our financial clients across the world</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12 no-padding">
                    <div class="doctors-slider">
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Tech.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://www.tech-emergingengg.com" target="_blank" class="text-custom-black fw-700">Tech-Emerging Engineering</a></h6>
                                    <p class="text-light-white">2009</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Pench.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://www.mahapenchtiger.com" target="_blank" class="text-custom-black fw-700">Pench Tiger Reserve</a></h6>
                                    <p class="text-light-white">2011</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Kend.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://kendriyamanavadhikar.com/" target="_blank" class="text-custom-black fw-700">Kendriya Manavadhikar</a></h6>
                                    <p class="text-light-white">2013</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/AdityaAnagha.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://www.adityaanagha.com/" target="_blank" class="text-custom-black fw-700">Aditya-Anagha</a></h6>
                                    <p class="text-light-white">2014</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Aeit.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="http://www.aeit.co.in/" target="_blank" class="text-custom-black fw-700">AEIT</a></h6>
                                    <p class="text-light-white">2015</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/sonekarlogo.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="http://sonekarcop.org.in/" target="_blank" class="text-custom-black fw-700">Sonekar College of Pharmacy</a></h6>
                                    <p class="text-light-white">2016</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/NNTR.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://www.mahanntr.com" target="_blank" class="text-custom-black fw-700">Nagzira Tiger Reserve</a></h6>
                                    <p class="text-light-white">2017</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Ggeneralavari.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://www.generalavari.com/" target="_blank" class="text-custom-black fw-700">Mancharsha Rustomji Avari</a></h6>
                                    <p class="text-light-white">2018</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Omsatya.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="http://omsatyasai.com/" target="_blank" class="text-custom-black fw-700">Om Satyasai College</a></h6>
                                    <p class="text-light-white">2019</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/Bs2.png" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://bs2solutions.com" target="_blank" class="text-custom-black fw-700">BS2 Solution's</a></h6>
                                    <p class="text-light-white">2020</p>
                                </div>
                            </div>
                        </div>
                        <div class="slide-item col-12">
                            <div class="doctor-item">
                                <div class="doctor-img mb-xl-20">
                                    <img src="assets/images/clients/preLogo.jpg" class="full-width" alt="image">
                                </div>
                                <div class="doctor-content">
                                    <h6><a href="https://prestylengineering.com/" target="_blank" class="text-custom-black fw-700">Prestyl Engineering Services</a></h6>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>-->

    <section class="section-padding doctors-team  style-2 p-relative">
        <div class="side-lines"> <span class="box"></span>
            <span class="text">Clients</span>
            <span class="line"></span>
        </div>
        <div class="container" >
            <div class="section-header">
                <div class="section-heading">
                    <h3 class="text-custom-black fw-700">Some of our financial clients across the world</h3>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <!-- <article class="col-lg-6 col-md-6 post mb-xl-30">
                        <div class="post-wrapper">
                            <div class="blog-img animate-img">
                                <a routerLink="/blog-detail">
                                    <img src="assets/images/blog/blog7.jpg" class="full-width" alt="blog">
                                </a>
                            </div>
                            <div class="blog-meta padding-20">
                                <div class="post-meta">
                                    <div class="author-img">
                                        <img src="assets/images/doctors/doctor12.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="author-meta">
                                        <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                        <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                    </div>
                                </div>
                                <div class="post-content">
                                    <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                    <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                </div>
                                <div class="blog-links">
                                    <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                        <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                    </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                </div>
                            </div>
                        </div>
                    </article> -->
                    <!-- <article class="col-lg-6 col-md-6 post mb-xl-30">
                        <div class="post-wrapper">
                            <div class="blog-img animate-img">
                                <a routerLink="/blog-detail">
                                    <img src="assets/images/blog/blog8.jpg" class="full-width" alt="blog">
                                </a>
                            </div>
                            <div class="blog-meta padding-20">
                                <div class="post-meta">
                                    <div class="author-img">
                                        <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                    </div>
                                    <div class="author-meta">
                                        <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                        <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                    </div>
                                </div>
                                <div class="post-content">
                                    <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                    <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                </div>
                                <div class="blog-links">
                                    <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                        <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                    </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                </div>
                            </div>
                        </div>
                    </article> -->
    
                    <article class="col-lg-4 col-md-4 post mb-xl-30" *ngFor="let item of eventList">
                        <div class="post-wrapper">
                            <div class="blog-meta padding-20">
                                <div class="post-content" >
                                    <img src={{item.i_path}} class="full-width"/>
                                    <div class="doctor-content" style="text-align: center;">
                                        <h6 innerHTML="{{item.name}}"></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>   
        </div>

        
    </section>
<app-footer></app-footer>