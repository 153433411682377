<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Blog</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Blog</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Blog -->
        <section class="section-padding bg-gray our-articles">
            <div class="container">
                <div class="row">
                    <aside class="col-lg-4">
                        <div class="sidebar_wrap mb-md-80">
                            <div class="sidebar">
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Search</h5>
                                    </div>
                                    <form>
                                        <div class="form-group mb-0">
                                            <div class="search p-relative">
                                                <input type="text" name="#" class="form-control form-control-custom" placeholder="Title or keywords">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Categories</h5>
                                    </div>
                                    <ul class="categories custom">
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">All <span class="text-light-white">(19)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Personal Loan <span class="text-light-white">(1)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Educational LOANLY <span class="text-light-white">(3)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Business Loan <span class="text-light-white">(4)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">Car Loan <span class="text-light-white">(2)</span></a>
                                        </li>
                                        <li> <a routerLink="/blog-detail" class="text-custom-black fs-14">CORPORATE LOAN <span class="text-light-white">(1)</span></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Popular Post</h5>
                                    </div>
                                    <ul class="custom popular_post">
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog2.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog3.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Opening Hours</h5>
                                    </div>
                                    <table class="table text-custom-black">
                                        <tbody>
                                            <tr>
                                                <td>Mon – Wed</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 7:00 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Thursday</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 6:30 PM</td>
                                            </tr>
                                            <tr>
                                                <td>Friday</td>
                                                <td>-</td>
                                                <td class="text-right">9:00 AM - 6:00 PM</td>
                                            </tr>
                                            <tr class="last-tr">
                                                <td>Sun - Sun</td>
                                                <td>-</td>
                                                <td class="text-right">CLOSED</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="sidebar_widgets mb-xl-30">
                                    <div class="widget_title bg-light-blue">
                                        <h5 class="no-margin text-custom-white fw-600">Recent Post</h5>
                                    </div>
                                    <ul class="custom popular_post">
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="post">
                                                <div class="post-wrapper">
                                                    <div class="popular_post_img animate-img">
                                                        <a routerLink="/blog-detail">
                                                            <img src="assets/images/blog/sblog1.jpg" class="img-fluid image-fit" alt="#">
                                                        </a>
                                                    </div>
                                                    <div class="popular_post_title">
                                                        <div class="post-date">
                                                            <p class="text-custom-black no-margin">March 20 2020</p>
                                                        </div>
                                                        <h6><a routerLink="/blog-detail" class="text-custom-black fs-16 fw-600">FAST APPROVAL  May For You If You Are Innocent</a></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <div class="col-lg-8">
                        <div class="row">
                            <article class="col-lg-6 col-md-6 post mb-xl-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog7.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor12.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="col-lg-6 col-md-6 post mb-xl-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog8.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="col-lg-6 col-md-6 post mb-xl-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog9.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor13.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="col-lg-6 col-md-6 post mb-xl-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog10.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="col-lg-6 col-md-6 post mb-sm-30">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog11.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <article class="col-lg-6 col-md-6 post">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/blog12.jpg" class="full-width" alt="blog">
                                        </a>
                                    </div>
                                    <div class="blog-meta padding-20">
                                        <div class="post-meta">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-white">Charles C. Pruitt</a></h6>
                                                <p class="no-margin text-custom-white"><a routerLink="/blog-detail" class="text-custom-white">Admin</a> | 25 January , 2020</p>
                                            </div>
                                        </div>
                                        <div class="post-content">
                                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Choosing right education loan</a></h2>
                                            <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's...</p>
                                        </div>
                                        <div class="blog-links">
                                            <div class="post-metas"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div> <a routerLink="/blog-detail" class="text-light-blue fs-14">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <nav class="section-padding-top">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">Previous</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">1</a>
                                        </li>
                                        <li class="page-item active"><a class="page-link" routerLink="/blog-left-sidebar">2</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">3</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-left-sidebar">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog -->
        <app-footer></app-footer>