<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Blog Detail</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li> <a routerLink="/blog" class="text-custom-white">Blog</a>
                                    </li>
                                    <li class="text-custom-white active">Blog Detail</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start Blog -->
        <section class="section-padding bg-gray">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="blog-detail padding-20">
                            <!-- article -->
                            <article class="post">
                                <div class="post-wrapper">
                                    <div class="blog-img animate-img mb-xl-20">
                                        <a routerLink="/blog-detail">
                                            <img src="assets/images/blog/mainblog.jpg" class="image-fit" alt="img">
                                        </a>
                                    </div>
                                    <div class="blog-meta">
                                        <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Success People Ask Better Question</a></h2>
                                        <div class="post-meta-box">
                                            <div class="post-meta mb-xl-20">
                                                <div class="author-img">
                                                    <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                                </div>
                                                <div class="author-meta">
                                                    <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-black">Charles C. Pruitt</a></h6>
                                                    <p class="no-margin text-light-grey"><a routerLink="/blog-detail" class="text-light-grey">Admin</a> | 25 January , 2020</p>
                                                </div>
                                            </div>
                                            <div class="post-metas mb-xl-20"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                                <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                            </div>
                                        </div>
                                        <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <h5 class="text-custom-black fw-600">Why do we choose it?</h5>
                                        <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <h5 class="text-custom-black fw-600">Where does it come from?</h5>
                                        <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                        <blockquote class="mb-xl-20">
                                            <p class="text-custom-black fw-600">@Sometimes by accident, sometimes on purpose with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page maker including versions of lorem ipsum. Sometimes by accident, sometimes on purpose."</p>
                                        </blockquote>
                                        <h5 class="text-custom-black fw-600">Where does it come from?</h5>
                                        <p class="text-light-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                        <p class="text-light-white no-margin">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                                    </div>
                                </div>
                            </article>
                            <!-- article -->
                            <hr>
                            <!-- tags & social -->
                            <div class="post-details-tags-social">
                                <div class="row">
                                    <div class="col-lg-8 col-md-8">
                                        <div class="tags-box mb-xl-20"> <span class="fs-18 text-dark-white"><i class="fas fa-tags"></i></span>
                                            <div class="tags"> <a routerLink="/blog-detail">Car Loan</a>
                                                <a routerLink="/blog-detail">Business Loan</a>
                                                <a routerLink="/blog-detail">Home Loan</a>
                                                <a routerLink="/blog-detail">CORPORATE LOAN</a>
                                                <a routerLink="/blog-detail">PERSONAL LOAN</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="social-media-box mb-xl-20">
                                            <ul>
                                                <li><a routerLink="/blog-detail" class="fb"><i class="fab fa-facebook-f"></i></a>
                                                </li>
                                                <li><a routerLink="/blog-detail" class="tw"><i class="fab fa-twitter"></i></a>
                                                </li>
                                                <li><a routerLink="/blog-detail" class="gg"><i class="fab fa-google-plus-g"></i></a>
                                                </li>
                                                <li><a routerLink="/blog-detail" class="ln"><i class="fab fa-linkedin-in"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- tags & social -->
                            <hr class="mt-0">
                            <!-- post pagination -->
                            <div class="pagination-btn">
                                <nav>
                                    <ul class="pagination">
                                        <li class="page-item"><a class="page-link" routerLink="/blog-detail">Previous</a>
                                        </li>
                                        <li class="page-item"><a class="page-link" routerLink="/blog-detail">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <!-- post pagination -->
                            <!-- comments -->
                            <div class="comment-box section-padding-top p-relative">
                                <div id="comment-box">
                                    <h4 class="text-custom-black fw-600">Reviews [4]</h4>
                                    <ul class="comments">
                                        <li class="comment">
                                            <article>
                                                <div class="comment-avatar">
                                                    <img src="assets/images/doctors/doctor13.jpg" class="rounded-circle" alt="comment">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="comment-meta">
                                                        <div class="comment-meta-header">
                                                            <h5 class="text-custom-black fw-600 author mb-3">Rosalina Pong</h5>
                                                            <div class="post-date"> <a routerLink="/blog-detail" class="date text-custom-white">25 Dec 2020</a>
                                                            </div>
                                                        </div>
                                                        <div class="comment-meta-reply"> <a routerLink="/blog-detail" class="comment-reply-link btn-first btn-submit text-light-blue"><i class="fas fa-reply"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="comment">
                                                        <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    </div>
                                                </div>
                                            </article>
                                        </li>
                                        <li class="comment">
                                            <article>
                                                <div class="comment-avatar">
                                                    <img src="assets/images/doctors/doctor14.jpg" class="rounded-circle" alt="comment">
                                                </div>
                                                <div class="comment-content">
                                                    <div class="comment-meta">
                                                        <div class="comment-meta-header">
                                                            <h5 class="text-custom-black fw-600 author mb-3">Brian Wright</h5>
                                                            <div class="post-date"> <a routerLink="/blog-detail" class="date text-custom-white">30 Nov 2020</a>
                                                            </div>
                                                        </div>
                                                        <div class="comment-meta-reply"> <a routerLink="/blog-detail" class="comment-reply-link btn-first btn-submit text-light-blue"><i class="fas fa-reply"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="comment">
                                                        <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                    </div>
                                                </div>
                                            </article>
                                            <ul class="children">
                                                <li class="comment">
                                                    <article>
                                                        <div class="comment-avatar">
                                                            <img src="assets/images/doctors/doctor15.jpg" class="rounded-circle" alt="comment">
                                                        </div>
                                                        <div class="comment-content">
                                                            <div class="comment-meta">
                                                                <div class="comment-meta-header">
                                                                    <h5 class="text-custom-black fw-600 author mb-3">Sarah Wright</h5>
                                                                    <div class="post-date"> <a routerLink="/blog-detail" class="date text-custom-white">30 Nov 2020</a>
                                                                    </div>
                                                                </div>
                                                                <div class="comment-meta-reply"> <a routerLink="/blog-detail" class="comment-reply-link btn-first btn-submit text-light-blue"><i class="fas fa-reply"></i></a>
                                                                </div>
                                                            </div>
                                                            <div class="comment">
                                                                <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div class="comment-respond" id="respond">
                                        <h4 class="text-custom-black fw-600">Leave Comment</h4>
                                        <form>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-custom-black fw-600 fs-14">Full Name</label>
                                                        <input type="text" name="#" class="form-control form-control-custom" placeholder="Full Name">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="text-custom-black fw-600 fs-14">Email I'd</label>
                                                        <input type="email" name="#" class="form-control form-control-custom" placeholder="Email I'd">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="text-custom-black fw-600 fs-14">Comment</label>
                                                        <textarea rows="4" name="#" class="form-control form-control-custom" placeholder="Comment"></textarea>
                                                    </div>
                                                    <button type="submit" class="btn-first btn-submit text-light-blue">Leave Comment</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- comments -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog -->
        <app-footer></app-footer>