import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogg',
  templateUrl: './blogg.component.html',
  styleUrls: ['./blogg.component.css']
})
export class BloggComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
