<div class="extra-page coming-soon">
	<div class="header">
            <a routerLink="/" class="logo">
                <img src="assets/images/logo.png" alt="logo">
            </a>
            <a routerLink="/coming-soon"> <i class="fab fa-facebook-f"></i>
            </a>
            <div class="divider"></div>
            <a routerLink="/coming-soon"> <i class="fab fa-twitter"></i>
            </a>
            <a routerLink="/"> <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <div class="ghost"> <span class="text">Coming Soon</span>
            <div class="eye"></div>
            <div class="eye two"></div>
            <div class="mouth"></div>
            <div class="corner"></div>
            <div class="corner two"></div>
            <div class="corner three"></div>
            <div class="corner four"></div>
            <div class="over"></div>
            <div class="over two"></div>
            <div class="over three"></div>
            <div class="over four"></div>
            <div class="shadow"></div>
        </div>
        <div class="main">
            <div class="error">Coming Soon</div>
            <h2>Our Website is Under Development</h2>
            <h6 class="text-custom-white">We are Coming Soon...</h6>
            <div class="counter">
                <div class="counter-box">
                    <div class="inner-box"><span class="text-custom-white" id="cvdays"></span>
                    </div>
                    <p class="text-custom-white">Days</p>
                </div>
                <div class="counter-box">
                    <div class="inner-box"><span class="text-custom-white" id="cvhours"></span>
                    </div>
                    <p class="text-custom-white">Hours</p>
                </div>
                <div class="counter-box">
                    <div class="inner-box"> <span class="text-custom-white" id="cvminutes"></span>
                    </div>
                    <p class="text-custom-white">Minutes</p>
                </div>
                <div class="counter-box">
                    <div class="inner-box"> <span class="text-custom-white" id="cvseconds"></span>
                    </div>
                    <p class="text-custom-white">Seconds</p>
                </div>
            </div>
        </div>
</div>