
<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Covid-19</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Covid-19</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container" style="text-align: justify;">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h2><a routerLink="/blog-detail" class="text-custom-black fw-600">Covid-19 Hard Opportunity</a></h2>
                            <p class="text-light-white">
                                Covid-19 is given a new opportunity for ocean software technologies to expand online business all over India and abroad. Some clients who use our offline banking software fastly move on I-Bank 4.0 and other Online software provided by OceanSoftTech. Some clients from other companies use offline software for their baking & financial use move on I-Bank 4.0 platform. Before Covid-19 Transfer rate is 2-3 clients per month, now it comes to 5-6 clients per month. The trend of offline software is almost finished in the services & finance sector and it also changed the mindset of the customer to use more powerfully and fully support online software. It gives a good opportunity to that company who is always inventing in online & new technologies.<br><br>
                                I-Bank 4.0 comes with new build-in help content & help video features, which are newly added in Feb 2020 for our Nigerian clients to better understand and train their employees.<br><br>
                                Covid-19 eliminates all offline services from Ocean Software Technologies and now sets full online support to our all clients and it will also reduce our carbon footprints.
                            </p>
                            <p class="text-light-white"><b>Hybrid working models 20% - 80% (Office & Home)</b><br>
                                During the Covid-19 our all employees and support staff was working from home due to our previous online support system is ready.
                            </p>
                        </div>
                    </div>
                </article>
                <!-- article -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <app-footer></app-footer>