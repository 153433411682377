<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Create the future you want</h1><br>
                               <b>to Become an OCEANER no degree or diploma is required.</b>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Careers</li>
                                </ul>
                            </div><br>
                        </div><br>
                    </div><br>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        	<!-- Start Intro -->
		<section class="genmed-intro">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Angular Developer</h6>
								<p class="text-custom-white">Delivering a complete front end application. Writing tested, idiomatic, and documented JavaScript, HTML and CSS. Coordinating the workflow between the graphic designer, the HTML coder, and yourself. Cooperating with the back-end developer in the process of building the RESTful API.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.6s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Ionic Developer</h6>
								<p class="text-custom-white">Monitoring the performance of live apps and work on optimizing them at the code level. Identifying and resolving bottlenecks, rectifying bugs and enhancing application performance. Collaborating with cross-functional teams to define and design new features. Staying up to date with new technology.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-xs-20 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Node.js/.Net Core Developer</h6>
								<p class="text-custom-white">Work as part of a team developing applications and services using Agile development methods. Contribute to team and organizational improvements in process and infrastructure. Build customer-facing UI and back-end services for payment processing & management.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="intro-box bg-dark-brown wow fadeInUp" data-wow-duration="2.5s" data-wow-delay="1.3s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">PL-SQL Developer</h6>
								<p class="text-custom-white">Designing databases and ensuring their stability, reliability, and performance. Design, create, and implement database systems based on the end user's requirements. Create complex functions, scripts, stored procedures and triggers to support application development.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Intro -->
        <section class="section-padding about-us-sec p-relative">
            <div class="side-lines"> <span class="box"></span>
                <span class="text">Careers</span>
                <span class="line"></span>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 align-self-center">
                        <div class="about-left-side mb-md-40 ">
                            <h3 class="text-custom-black fw-700">Inside Ocean</h3>
                            <p class="text-light-white fs-14 mb-xl-20"><b>Life @ OceanSoftTech</b></p>
                            <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                            a Soft-Tech company having 25+ professional employees. <br>
                            Modern Technologies (Angular 10, Node.js, & SQL 2019 R2)<br>
                            Always learn new tools & Techniques<br>
                            Flexible working time models and work@home<br>
                            Openness, Teamwork, an Independent Initiative, & creativity.<br>
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class=" p-relative">
                            <div class="first-img p-relative">
                                <img src="assets/images/oceanteam.jpeg" class="img-fluid full-width" alt="team ocean">
                                <div class="transform-center">
                                    <div class="text">
                                        <h2 class="text-custom-white no-margin">12+</h2>
                                        <div class="side-text text-custom-white">
                                            <p>Years of
                                                <br>OceanSoftTech</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-light-white-skew-2 bg-custom-black"></div>
        </section>
        <app-footer></app-footer>