
<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">Data Protection Policy</h1>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Data Protection Policy</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container" style="text-align: justify;">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h2><a routerLink="/data-protection-policy" class="text-custom-black fw-600">Data Protection Policy (Privacy Policy)</a></h2>
                            <br>
                            <p>We welcome your visit to our website and your interest in our company, our products and our services. Transparency in relation to data protection and data security of visitors to our website, customers and contractual partners is a matter of great importance to us. We take the protection of your personal data very seriously in all of our business processes. On the occasion of your visit to our website we would like to tell you which data we store, when it is stored and how such data is used.</p>
                            <p><strong>1. Who is responsible for the protection of your personal data?</strong></p>
                            <p> <em>OCEAN Software Technologies, Nagpur, India (we, us) is responsible, under The Indian Penal Code on Data Protection, for the processing and security of the data collected on this website. All (mobile) applications that refer to this Data Protection Policy are also subject to data processing by us.</em></p>
                            <p>Generally you will not have to provide any personal data in order to use our website. For some services this may be necessary, however, to allow us to provide our services. In these cases we collect, use and process your personal data. This will, of course, only occur within the statutory framework, only insofar as is necessary and only to the extent that you have consented to. We take great care to adhere to the principles of data reduction and data economy.</p>
                            <p>
                                <strong>2. Which of your data do we store?<br>
                                    2.1 Anonymous data collection</strong>
                            </p>
                            <p>If you access our website, our web server automatically gathers information of a general nature. This includes:</p>
                            <ul>
                                <li>the type of web browser and operating system used,</li>
                                <li>the domain name of the Internet service provider,/li&gt;</li>
                                <li>the IP-address of the computer used,</li>
                                <li>the website from which you are visiting us,</li>
                                <li>the pages that you visit on our site as well as the date and duration of your visit.</li>
                            </ul>
                            <p>None of the above information can be used to identify who you are. We, or third parties instructed by us, evaluate this data purely for statistical purposes and only in an anonymised form, in order to optimize our website and increase user-friendliness, efficiency and safety.</p>
                            <p><strong>2.2 Cookies</strong></p>
                            <p>We use so-called cookies at various points on our website. Cookies are small text files that are stored on your computer or mobile device by the web browser. Cookies do not cause any damage to your computer, do not contain viruses and are automatically deleted after their expiry. Some cookies expire when you terminate your Internet session; others are stored for a certain time.</p>
                            <p>The cookies on our websites do not collect any personal data. We use cookies to facilitate your visit to our website and to adjust it to your needs. You can, of course, also view our website without cookies. You can prevent cookies from being stored on your computer via the browser settings. Cookies already in place can also be deleted via the browser settings. If this is done, however, the functionality of our website might be limited.</p>
                            
                            
                            <p><strong>2.3. Where and how do we collect and process your data?</strong></p>
                            <p>In addition to the data collected automatically we also process data that you make available to us voluntarily. We currently collect the following data with the tools listed:</p>
                            <ul>
                                <li>Requests for information, advice and demonstrations or support request forms regarding products and services; event registration; account registration: last name, first names, company name, position, email address (mandatory fields). Other mandatory fields, as required: request and advice modules, possible date, subject, message, street, postal code, town, country. Optional fields: telephone number as well as other possible dates, customer number.</li>
                                <li>Investor service, customer magazine order form, press inquiries: last name, first name, street, postal code, town, country, email address (mandatory fields). Optional fields: company name, function, telephone number, fax number, message.</li>
                                <li>Various newsletters about products and services: title, first name, last name and email address (mandatory fields).</li>
                                <li>CLX.Shop for the sale of software and hardware: first name, last name, email address, street, postal code, town, country, telephone number, password (mandatory fields). Optional fields: company name, fax number. Additional mandatory fields for payment by credit card: card provider, card number, expiry date, card verification number, name of the cardholder.</li>
                                <li>Blog: Your name, email address and comment. This information is required to counteract spambots. Your email address will not be published, but it is used to Contact you to answer any questions.</li>
                                <li>Online application: name, email address, telephone number, CV, references, diplomas/certificates, motivation, open job advertisement applied to (mandatory fields).</li>
                            </ul>
                            <p>You disclose all of this data to us on a voluntary basis. We use this data only for the purposes mentioned in this data protection policy. Your data is forwarded by us to the relevant department within the company and deleted in accordance with the applicable statutory retention periods. Item 8 below contains information on your rights: for example, how to object to the processing or how to correct your personal data.</p>
                            <p><strong>3. For what purposes do we use your data?</strong></p>
                            <ul>
                                <li>We process your personal data in connection with your request for information, advice, demo or support in order to inform you of the status of your request. We require your mandatory details to properly process your request and to be able to respond to your satisfaction.</li>
                                <li>We also require certain personal data from you in relation to the investor service, for orders of the customer magazine and for press inquiries. We need such data to be able to send you the requested documents as a one-off or regularly in the future. The data is stored in special distribution lists for this purpose for the entire time during which the requested documents are being received.</li>
                                <li>We also require personal data from you for subscriptions to our newsletter. This data is only stored for the purpose of sending you the newsletter.</li>
                                <li>If you wish to purchase software or hardware products in our CLX.Shop, you need a customer account to complete the purchase. We require personal data so that we can send you the items ordered. If you pay by credit card, additional information is required for the purposes of completing the payment. This information is also only processed for the purposes of completing the payment and/or delivering the product.</li>
                                <li>If you wish to leave a comment on our blog, we require your name and email address to allow us to get in touch with you in case of any queries.</li>
                                <li>If you have given us your personal data when purchasing a product or service, we will inform you of related products and services via email. You can opt out of these notifications at any time and at no extra charge by clicking on a link in the email or message in question (for more information please see point 8 below).</li>
                                <li>Provided this is required in order to respond to your requests regarding investor service, customer magazine orders and press inquiries, your personal data will be stored in our customer and interested parties databases. Other than for the purposes listed above, your data will only be used in our customer and interested parties databases with your consent. The use of this data is restricted to purposes of advertising as well as market research and opinion polling. You are entitled to revoke your consent at any time with effect for the future.</li>
                                <li>With your express consent and using the Contact details provided by you (postal address, email, SMS, telephone, fax) we can get in touch with you for marketing or advertising purposes or to carry out an opinion poll in relation to our company. This may include, for example, information about our products. Item 8 below contains information on your rights; for example, how to revoke your consent or correct your personal data.</li>
                            </ul>
                            <p><strong>4. With whom do we share this data? </strong></p>
                            <p>The protection of your personal data is of foremost concern to us. We therefore store all the information you enter on our website on our servers in Switzerland. Only the relevant departments and/or people within our company have access to the data in order to process your requests and wishes. We will not sell, lease or otherwise grant access to your data to third parties. Personal data is only transferred to government institutions and agencies to the extent mandatory under national laws. Provided the applicable laws allow this, we are entitled to share the data within the OCEAN Group. Our employees are obliged to maintain strict secrecy and confidentiality.</p>
                            <p>
                                <strong>5. Which tools do we use to measure audience reach?</strong><br>
                                <strong>5.1 Google Analytics</strong>
                            </p>
                            <p>
                                This website uses Google Analytics and Google Remarketing. These are services provided by Google, Inc. (“Google”). Google uses “cookies”, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. In case of activation of the IP anonymization, Google will truncate/anonymize the last octet of the IP address for Member States of the European Union as well as for other parties to the Agreement on the European Economic Area. Only in exceptional cases, the full IP address is sent to and shortened by Google servers in the USA. 
On behalf of the website provider Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage to the website provider. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this, you may not be able to use the full functionality of this website. 
                            </p>
                            
                            <p><strong>5.2. Facebook social plugins</strong></p>
                            <p>On our website we use so-called social plugins belonging to the social networking site facebook.com, which is run by Facebook Inc., 1601 South California Avenue, Palo Alto, CA 94304, USA. These plugins are recognisable by their Facebook logo (white “f” on blue square or a “thumbs up” sign) or they are marked “Facebook Social Plugin”. The list and the appearance of Facebook social plugins can be found here:</p>
                            
                            <p>If you access one of our web pages that contains such a plugin your browser establishes a direct connection with the servers of Facebook. The content of the plugin is sent directly to your browser by Facebook and integrated from there into the website.</p>
                            <p>Through the integration of the plugin Facebook receives the information that you have accessed the respective page on our website. If you are logged onto Facebook, Facebook is able to match the visit with your Facebook account. Please note that such exchange of information takes place as soon as you visit our website, regardless of whether you interact with the plugins. If you interact with the plugins, for example by clicking on the “I like” button or posting a comment, the corresponding information is sent to Facebook directly from your browser and stored there.</p>
                            <p>For details regarding the purpose and scope of the collection of data and its further processing and use by Facebook as well as your rights and settings options to protect your privacy, please refer to Facebook’s Data Use Policy at <a title="http://de-de.facebook.com/policy.php" href="http://de-de.facebook.com/policy.php" target="_blank">http://de-de.facebook.com/policy.php</a>.</p>
                            <p>If you do not wish Facebook to collect data about you via our website you need to ensure that you log out of Facebook before visiting our website.</p>
                            <p><strong>6. How do we protect your data? </strong></p>
                            <p>To ensure the safety of your data on our website and our systems we use appropriate technical and organisational measures to protect your data from loss, destruction, unauthorized access and manipulation. The measures we use are subject to continuous development in accordance with technological progress.</p>
                            <p>If you visit this website your data will be transported via an open, publicly accessible network. The data might therefore be transmitted across national borders even if you and OCEAN are located in Switzerland. This involves notably the following risks: The data may be intercepted and read by third parties which, in turn, may allow such third parties to infer an already existing or future business relationship between you and OCEAN.</p>
                            <p>Furthermore, you are put notified that information you transmit or allow to be transmitted to you by OCEAN via an electronic medium, in particular via email, SMS, Contact forms etc., are usually unencrypted. Even in the event of an encrypted transmission, sender and recipient remain unencrypted in each case. Third parties may therefore be able to infer an already existing or future business relationship between you and OCEAN.</p>
                            <p>Please note that, for technical reasons, email communication is neither confidential nor secure. Please do not send us any confidential information via email.</p>
                            <p>In addition, OCEAN explicitly points out the risk of viruses and the possibility of targeted attacks by hackers. To combat viruses, the use of up-to-date browser versions as well as the installation of continually updated anti-virus software is recommended. As a basic rule, you should refrain from opening emails of unknown origin and unexpected email attachments of an unknown origin.</p>
                            
                            <p><strong>7. What rights of information and appeal do you have? </strong></p>
                            <p>On request, we are happy to notify you in writing whether and which personal data we have stored about you. If, despite our continuing efforts to ensure that your data is up-to-date and correct, incorrect information should have been stored we are happy to correct your data. Under certain conditions you also have the right to have your personal data that is stored by us blocked and deleted.</p>
                            <p>If you no longer wish to receive newsletters from us you can unsubscribe at any time by clicking the unsubscribe link included in every newsletter or by sending us an email to <a title="E-Mail" href="#">#</a>.</p>
                            <p>If you do not wish us to collect and analyse information about your movements on our website or (mobile) applications by way of tools to measure audience reach, you can proceed as described under item 5 above.</p>
                            <p>If you wish to delete your account, please send an email to <a title="E-Mail" href="#">#</a></p>
                            <p>If you generally do not wish to receive advertisements and promotions, either by post, email, SMS, fax or telephone, or if you want to repeal your consent, you can write to us at any time or send us an email. Should we have any doubts as to your identity we may ask you to send information confirming this (e.g. blacked-out copy of identity card/passport showing first and last name, address and date of birth). For all questions concerning the processing of your personal data within our company, any suggestions or complaints you are welcome to Contact our Data Protection Officer. Please send an email or letter to the following address: <a title="E-Mail" href="#">#</a></p>
                            <p>Latest update: 02.08.2016</p>
                        </div>
                    </div>
                </article>
                <!-- article -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <app-footer></app-footer>