<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 class="text-custom-white lh-default fw-600">Digitalization in Banking Sector & Other Utilities</h2>
                        <h6 style="color: white;">we are providing BANK API, that working with leading Nidhi and Cooperative Society for Payment and collection solutions.</h6>
                        <h6 style="color:white"> Our objective is to give the seamless banking experience to Nidhi and Cooperative Society.</h6>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">API & Finance Utilities/li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
    <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h5 class="text-custom-black" style="text-align:center;">We focus on your needs and plan and develop your Society / Nidhi on total digitalization with our endless solutions to position you as a key player in the marketplace.</h5>
                            <p class="text-light-white" style="text-align:center;">Our customers value a stable and long-lasting collaboration. We support you as a partner to drive your digitization. As a proven and long-standing provider of online banking solutions, we are experienced in collaboration with financial institutions. Our customers consider us to be a reliable, flexible, and innovative partner.</p>
							<h3 class="text-custom-black fw-700" style="text-align:center;">CHECK THE STACK</h3>
                        </div>
                    </div>
                </article>
			</div>
		</section>
   	<!-- End About Us Intro -->
	<!-- About One section-->
		<section class="section-padding about-us-sec p-relative">
            <div class="side-lines right-side"> <span class="box"></span>
                <span class="text">Features</span>
                <span class="line"></span>
            </div>
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                        <div class="about-left-side-1">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h4 class=" fw-700 text-dark">#1 eKYC & Video KYC</h4>
                                </div>
                            </div>
                            <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
								Ocean SoftTech offers digital EKYC & Video KYC software solution with UIDAI integrated Aadhaar-based KYC verification process. It is a paperless offline KYC solution that reduces 50% of compliance operation cost for Society, Nidhi and NBFC service providers. Our KYC compliance platform is specially designed to cut the KYC verification times for opening Loan Accounts.
							</p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height">
                            <img src="assets/images/I-Bank-DashBoard.jpg" class="img-fluid" alt="I-Bank DashBoard" style="padding-top: 90px;">
                            <p>
                            <br>
							Find all of our modules here, select the ones that your want, and compose your individual basket.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container about-sec-second">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height">
                            <img src="assets/images/I-Bank Architecture.png" class="img-fluid" alt="I-Bank Architecture" style="padding-top: 90px;">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg">
                        <div class="about-left-side-1 p-relative">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h4 class=" fw-700 text-dark">#2 Credit Verification and Evaluation</h4>
                                </div>
                            </div>
                            <div class="about-desc">
                                <p class="text-light-white mb-xl-20" style="text-align: justify;">
                                    All credit bureaus are integrated with us.<br><br>
                                    CIBIL<br>
                                    EquiFax<br>
                                    Experian
                                </p>
                            </div>
                            <div class="company-progress">
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                        <div class="about-left-side-1">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h4 class=" fw-700 text-dark">#3 Bank API</h4>
                                </div>
                            </div>
                            <p class="text-light-white mb-xl-20">
                                Transferring funds through RTGS, NEFT, or IMPS.<br>
                                Balance enquiries.<br>
                                Check account balance.<br>
                                Show transaction history.<br>
                                Instant Banking transactions 24/7.<br>
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height">
                            <img src="assets/images/Banking.gif" class="image-fit" alt="about us">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container about-sec-second">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height ">
                            <img src="assets/images/LoginQR.jpg" style="padding-top: 90px;" alt="about">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg">
                        <div class="about-left-side-1 p-relative">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h4 class=" fw-700 text-dark">#4 UPI or QR Code Payment</h4>
                                </div>
                            </div>
                            <div class="about-desc">
                                <p class="text-light-white mb-xl-20" style="text-align: justify;">
                                    UPI/QR: A Powerful Way to Digitize Payments with UPI or QR Code
                                </p>
                            </div>
                            <div class="company-progress">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    <!--About-two-section-end-->
     <!-- Start Blog -->
     <section class="section-padding bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="blog-detail padding-20">
                        <!-- article -->
                        <article class="post">
                            <div class="post-wrapper">
                                <div class="blog-img animate-img mb-xl-20">
                                    <video controls playsinline="playsinline" class="jarallax" autoplay="autoplay" width="78%" muted="muted" loop="loop">
                                        <source src="https://adityaanagha.com/I-Bank.mp4" type="video/mp4">
                                      </video>
                                </div>
                                <div id="Journey" class="blog-meta">
                                    <h2><a class="text-custom-black fw-600">Journey of I-Bank 4.0 (CBS)</a></h2>
                                    <div class="post-meta-box">
                                        <div class="post-meta mb-xl-20">
                                            <div class="author-img">
                                                <img src="assets/images/doctors/doctor11.jpg" class="rounded-circle" alt="image">
                                            </div>
                                            <div class="author-meta">
                                                <h6 class="no-margin"><a routerLink="/blog-detail" class="text-custom-black">Sachin S. Bawane</a></h6>
                                                <p class="no-margin text-light-grey"><a routerLink="/blog-detail" class="text-light-grey">Director</a> | 25 October , 2015</p>
                                            </div>
                                        </div>
                                        <!--<div class="post-metas mb-xl-20"> <span class="mr-2 fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="fas fa-eye text-light-blue"></i> 33</a></span>
                                            <span class="fs-14"><a routerLink="/blog-detail" class="text-custom-black"><i class="far fa-comment text-light-blue"></i> 33</a></span>
                                        </div>-->
                                    </div>
                                    <p class="text-light-white" style="text-align: justify;">
                                        We start this dream project in Dec 2012 as "SocietyOnline" for Credit Cooperative Society from converting 8 year old offline software with other company we call now "X". Project Timeline is 1 Year, But due to some miss management & Uncleared Knowledge of banking project is stop on Aug 2015. After that break up event with "X" Company, "Ocean Software Technologies" start new project "I-Bank 1.0" For Cooperative Society, Multi-State Society, Employee Cooperative Society, Nidhi Company & Cooperative Bank with 2<sup>1/2</sup> Year experience & 6 current projects of "SocietyOnline". On 2 May 2016 project "I-Bank 1.0" is completed with all new features & functionality. In Aug 2016 we convert all our existing 6 clients From "SocietyOnline" To "I-Bank 1.0" to give a new version to our project i-e "I-Bank 4.0". On 10 Oct 2016 we online our new client (Geeta Krishna Credit Cooperative Society, Nagpur) on "I-Bank 4.0" with data conversion of all (Members, Accounts, GL & All Transactions) of 10-year-old Tapi software on their 4 branches within 3 weeks.<br><br>
                                        <b>OCEAN I-Bank 4.0 CBS</b> offers tools that help gain more control over every critical area of Banking & Finance (Cooperative Society, Multi-State Society, Employees Cooperative Society, Nidhi Company, Cooperative Banks, Micro Finance Company, NBFC). It integrates all business processes of banking and information into a single database and enables all users across the organization to the interface on one uniform platform.
                                        Winning the hearts and minds of today’s consumers is a never-ending quest. Our clients have different needs, goals, and challenges in the finance sector, and our solutions are tailored to fit each and every one of them. No project begins before a complete understanding of the client’s business and then customizing the entire solution to his needs so we work with you to meet your objective on time and budge because if our solutions don’t fit within your business objectives, they’re not going to do you any good. So our flexible process keeps everything on track to meet your goals.
                                    </p>
                                    <h5 class="text-custom-black fw-600">Why Ocean I-Bank 4.0?</h5>
                                    <p class="text-light-white" style="text-align: justify;">
                                        1. Technological platform Our choice of technological platform – MSSQL database and ASP Developer - is the most rugged and ever-updating technology the world has to offer.<br>
                                        2. OCEAN I-Bank 4.0 Core Banking System is a universal banking system that provides comprehensive instrument and product coverage for retail deposits and lending, commercial banking, treasury management, and balance sheet management.<br>
                                        3. OCEAN I-Bank 4.0 Core Banking System provides the traditional capabilities that banks require from their universal banking system: (Deposits, Lending, Payments, Treasury, Customer, Limits, General ledger)<br>
                                        4. Industry-specific solutions Ocean "I-Bank 4.0" comes in industry-specific solutions (with a distinct design and a separate set of programs to meet critical success factor of that segment) for each of more than 25 Type of Different Banking Transactions. This is unlike another standard "I-Bank 4.0" who give one set of generic software programs for all banking segments.<br>
                                        5. Additional customization, The icing on the cake is achieved by a readiness to further customize to adopt organization-specific business practices and critical needs. This is possible by Data first programming approach and virtue of Ocean being the software developer of more trust and fountainhead of vast no. of proven solutions and business domain knowledge.<br>
                                        6. Flexible & scalable Design We, at Ocean, passionately believe and take untiring efforts in designing the database schema, business logic, and user interface to achieve - Highly flexible configuration so as to customize the most complex business logic and numerous types of transactions. Scalability (adding of a Branch(Micro Br., Small Br., Medium Br., Regnaol Br. Office, ATM & COD), Agent App, Locker & Netbanking is effortless)<br>
                                        7. Taxation Ocean "I-Bank 4.0" has consciously focused to incorporate all taxation and statutory compliances. We claim full compliance of TDS, GST, Service Tax, VAT, FBT, and Works Contract Tax. Taxation is native to the design unlike a localization top layer patch
                                    </p>
                                    <h5 class="text-custom-black fw-600">Some More Information</h5>
                                    <p class="text-light-white" style="text-align: justify;">
                                        <b>Accounting</b> has always been our key strength. We have now equally focused in the areas of Blockchain & Data Encryption and Decryption over these years delivering professional and cost-effective solutions to our clients globally. Ocean Software Technologies is a web-oriented application development company based in India providing web-based banking & Finance system.
                                    </p>
                                    <p class="text-light-white">
                                        <b>Experience Counts When Providing Services 24*7</b><br>
                                        We come with years of expertise combined with technical know-how in the areas of Cooperative Society, MultiState Cooperative Society, Micro Finance, Cooperative Bank, Payment Gateways & e-commerce, and many more. You can rest assured Ocean Software Technologies, a premier website development company in India, which provides you with everything needed for the successful completion of a project. Our web team is ever-ready to build customized and personalized web designs suiting your web requirements.
                                    </p>
                                    <!--<blockquote class="mb-xl-20">
                                        <p class="text-custom-black fw-600">@Sometimes by accident, sometimes on purpose with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page maker including versions of lorem ipsum. Sometimes by accident, sometimes on purpose."</p>
                                    </blockquote>-->
                                    <h5 class="text-custom-black fw-600">Phase of "I-Bank 4.0" Implementation</h5>
                                    <p class="text-light-white">
                                        We implement your website in 8 phases.<br>
                                        1) Analysis of your current working situation.<br>
                                        2) Specification.<br>
                                        3) Set Demo as per requirement.<br>
                                        4) Design & Development in the product as a requirement.<br>
                                        5) Data Transfer for Previous Software.<br>
                                        6) Testing all Reports with the Previous System<br>
                                        7) Make "I-Bank 4.0" Live Working.<br>
                                        8) Maintennance & Upgrades.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Blog -->
<app-footer></app-footer>