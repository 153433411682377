<!-- Start Header -->
<header class="header-style-1">
  <!-- Start Topbar -->
  <div class="topbar-style-1">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="left-side">
            <div class="language-box">
              <div class="language p-relative">
               <!-- <img src="assets/images/flag.png" alt="flag">-->
                <select>
                  <option>I-Bank 4.0</option>
                  <option>Nidhi & NBFC</option>
                  <option>Server & Hosting</option>
                </select>
              </div>
            </div>
            <p class="text-custom-white no-margin"><b>Ocean Software Technologies</b></p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="right-side">
            <ul class="custom">
              <li><a href="https://www.facebook.com/pages/Ocean-Software-Technologies/151762164858893" target="_blank" class="text-custom-white fs-14"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li><a href="https://twitter.com/yogeshkedar" target="_blank" class="text-custom-white fs-14"><i class="fab fa-twitter"></i></a>
              </li>
              <li><a href="https://www.linkedin.com/company/ocean-software-technologies" target="_blank" class="text-custom-white fs-14"><i class="fab fa-linkedin"></i></a>
              </li>
              <li><a href="https://www.youtube.com/watch?v=sI3_ElyIZ3M&t=5s&ab_channel=yogeshkedar" target="_blank" class="text-custom-white fs-14"><i class="fab fa-youtube"></i></a>
              </li>
              <li><a href="https://www.google.com/maps/place/Ocean+Software+Technologies/@21.132276,79.1284605,17z/data=!3m1!4b1!4m5!3m4!1s0x3bd4b8a174000003:0xa891933da8584330!8m2!3d21.132276!4d79.1306492" target="_blank" class="text-custom-white fs-14"><i class="fab fa-google-plus-g"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Topbar -->
  <!-- Start Navigation -->
  <div class="main-navigation-style-1">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="navigation">
            <div class="logo">
              <a routerLink="/">
             <!-- <img src="assets/images/logo.png" class="img-fluid image-fit" alt="Logo"> -->
             <h3 style="color: white;">Ocean</h3>
              </a>
            </div>
            <div class="main-menu">
              <div class="mobile-logo">
                <a routerLink="/">
                  <h4 style="color:black;">Ocean SoftTech</h4>
                <!-- <img src="assets/images/logo.png" class="img-fluid image-fit" alt="Logo">-->
                </a>
              </div>
              <nav>
                <ul class="custom">
                  <li class="menu-item "> <a routerLink="/" class="text-custom-white">Home</a>
                  </li>
                 <!-- <li class="menu-item menu-item-has-children">
                    <a href="#" class="text-custom-white">Homepages</a>
                    <ul class="custom sub-menu">
                      <li class="menu-item"> <a routerLink="/" class="text-light-grey">Homepage 1</a>
                      </li>
                      <li class="menu-item"> <a routerLink="/homepage2" class="text-light-grey">Homepage 2</a>
                      </li>
                    </ul>
                  </li>-->
                  <li class="menu-item menu-item-has-children">
                    <a href="#" class="text-custom-white">I-Bank 4.0</a>
                    <ul class="custom sub-menu">
                      <li class="menu-item"><a routerLink="/i-bank-info" class="text-light-grey">I-Bank 4.0 Product Info</a>
                      </li>
                      <li class="menu-item"><a routerLink="/i-bank-price" class="text-light-grey">I-Bank 4.0 Price Plans</a>
                      </li>
                      <li class="menu-item"><a routerLink="/mobile-app" class="text-light-grey">I-Bank 4.0 App's</a>
                      </li>
                      <li class="menu-item"><a href="/credit-cooperative-society" class="text-light-grey">Cooperative & Employees<br> Society (SACCOS)</a>
                      </li>
                      <li class="menu-item"><a routerLink="/multistate-society" class="text-light-grey">Multistate Cooperative<br> Society</a>
                      </li>
                      <li class="menu-item"><a href="/micro-finance-nidhi" class="text-light-grey">Micro Finance & Nidhi,<br>NBFC</a>
                      </li>
                      <li class="menu-item"><a href="/finance-uitility" class="text-light-grey">Finance Uitility (API)</a>
                      </li>
                    </ul>
                  </li>
                  <li class="menu-item menu-item-has-children">
                    <a href="#" class="text-custom-white">Server & Hosting</a>
                    <ul class="custom sub-menu">
                      <li class="menu-item"> <a routerLink="/server-vps" class="text-light-grey">VPS Server</a>
                      </li>
                      <li class="menu-item"> <a routerLink="/server-dedicated" class="text-light-grey">Dedicated Server</a>
                      </li>
                      <!-- <li class="menu-item"> <a routerLink="/" class="text-light-grey">Homepage 1</a></li> -->
                      <!-- <li class="menu-item"> <a routerLink="/homepage2" class="text-light-grey">Homepage 2</a></li> -->
                    </ul>
                  </li>
                  <li class="menu-item  menu-item-has-children">
                    <a href="#" class="text-custom-white">website</a>
                    <ul class="custom sub-menu">
                      <li class="menu-item"> <a routerLink="/website-design" class="text-light-grey">Website Design</a>
                      </li>
                      <li class="menu-item"> <a routerLink="/website-hosting" class="text-light-grey">Website Hosting</a>
                      </li>
                      <!-- <li class="menu-item"> <a routerLink="/blog" class="text-light-grey">Blog</a></li> -->
                      <!-- <li class="menu-item"> <a routerLink="/blog-left-sidebar" class="text-light-grey">Blog Left Sidebar</a></li> -->
                      <!-- <li class="menu-item"> <a routerLink="/blog-detail" class="text-light-grey">Blog Detail</a></li> -->
                    </ul>
                  </li>
                  <li class="menu-item   menu-item-has-children">
                    <a href="#" class="text-custom-white">Careers</a>
                    <ul class="custom sub-menu">
                      <li class="menu-item"> <a routerLink="/career" class="text-light-grey">Career In OceanSoftTech</a>
                      </li>
                      <li class="menu-item"> <a routerLink="/vacancies" class="text-light-grey">Vacancies</a>
                      </li>
                      <li class="menu-item"> <a routerLink="/internship" class="text-light-grey">Internship</a>
                      </li>
                      <!-- <li class="menu-item"> <a routerLink="/404" class="text-light-grey">404</a>
                      </li> -->
                      <!-- <li class="menu-item"> <a routerLink="/coming-soon" class="text-light-grey">Coming Soon</a>
                      </li> -->
                      <!-- <li class="menu-item"> <a routerLink="/our-team" class="text-light-grey">Our Team</a>
                      </li> -->
                      <!-- <li class="menu-item"> <a routerLink="/loan-calculator" class="text-light-grey">Loan Calculator</a>
                      </li> -->
                      <!-- <li class="menu-item"> <a routerLink="/about-us" class="text-light-grey">About Us</a>
                      </li> -->
                      <!-- <li class="menu-item  "> <a routerLink="/contactus" class="text-light-grey">Contact Us</a>
                      </li> -->
                    </ul>
                  </li>
                  
                  <li class="menu-item menu-item-has-children"> 
                    <a href="#" class="text-custom-white">About</a>
                    <ul class="custom sub-menu">
                      <li class="menu-item"> <a routerLink="/about-us" class="text-light-grey">About Us</a></li>
                      <li class="menu-item"> <a routerLink="/news" class="text-light-grey">News</a></li>
                      <li class="menu-item"> <a routerLink="/blog" class="text-light-grey">Blog</a></li>
                      <!-- <li class="menu-item"> <a routerLink="/news" class="text-light-grey">News</a></li> -->
                      <!-- <li class="menu-item"> <a routerLink="/testing" class="text-light-grey">testting</a></li> -->
                      <!-- <li class="manu-item"><a routerLink="/conatct" class="text-light-grey">testconatc</a></li> -->
                    </ul>
                    
                  </li>
                <!--<li class="menu-item"> <a routerLink="/loan-dashboard" class="text-custom-white">Contact Us</a>
                  </li>-->
                  <li class="menu-item"> <a routerLink="/Conatctocean" class="text-custom-white">Contact Us</a>
                  </li>
                </ul>
              </nav>
              <!--<div class="right-side">
                <div class="cta-btn"> <a routerLink="/finance" class="btn-first btn-submit">
                  Apply Today
                  </a>
                </div>
              </div>-->
            </div>
            <div class="hamburger-menu">
              <div class="menu-btn"> <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Navigation -->
</header>
<!-- End Header -->