<app-navbar></app-navbar>
<!-- Start Subheader -->
        <div class="sub-header p-relative">
            <div class="overlay overlay-bg-black"></div>
            <div class="pattern"></div>
            <div class="section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="sub-header-content p-relative">
                                <h1 class="text-custom-white lh-default fw-600">12 Months Free Internship in Ocean Soft-Tech for students</h1><br>
                               <b>Create the future you want</b>
                                <ul class="custom">
                                    <li> <a routerLink="/" class="text-custom-white">Home</a>
                                    </li>
                                    <li class="text-custom-white active">Internship</li>
                                </ul>
                            </div><br>
                        </div><br>
                    </div><br>
                </div>
            </div>
        </div>
        <!-- End Subheader -->
        	<!-- Start Intro -->
		<section class="genmed-intro">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Angular Developer</h6>
								<p class="text-custom-white">Delivering a complete front end application. Writing tested, idiomatic, and documented JavaScript, HTML and CSS. Coordinating the workflow between the graphic designer, the HTML coder, and yourself. Cooperating with the back-end developer in the process of building the RESTful API.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-md-20 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="0.6s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Ionic Developer</h6>
								<p class="text-custom-white">Monitoring the performance of live apps and work on optimizing them at the code level. Identifying and resolving bottlenecks, rectifying bugs and enhancing application performance. Collaborating with cross-functional teams to define and design new features. Staying up to date with new technology.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3  col-sm-6">
						<div class="intro-box bg-dark-brown mb-xs-20 wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">Python /.Net Core Developer</h6>
								<p class="text-custom-white">Work as part of a team developing applications and services using Agile development methods. Contribute to team and organizational improvements in process and infrastructure. Build customer-facing UI and back-end services for payment processing & management.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-sm-6">
						<div class="intro-box bg-dark-brown wow fadeInUp" data-wow-duration="2.5s" data-wow-delay="1.3s">
							<div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
								<h6 class="text-custom-white fw-700">SQL Developer / PostgreSql</h6>
								<p class="text-custom-white">Designing databases and ensuring their stability, reliability, and performance. Design, create, and implement database systems based on the end user's requirements. Create complex functions, scripts, stored procedures and triggers to support application development.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Intro -->
        <!-- Start About Us Intro -->
		<section class="section-padding our_articles">
			<div class="container">
                <article class="post">
                    <div class="post-wrapper">
                        <div class="blog-meta">
                            <h4><a routerLink="" class="text-custom-black fw-600">Freshers (Diploma, Graduate, Post-Graduate)</a></h4>
                            <p class="text-light-white">
                                Location:- Nagpur<br>
                                Diploma in Computer Science / Diploma in Information Technology, Diploma in Electronics or equivalent, BE, B. Sc., BCA, MCA & MCM.<br>
                                <b>Internship Course</b><br>
                                Python Developer / Angular Developer<br>
                                PostgreSql<br>
                                Ionic Developer<br>
                                Full Stack :-  Python , Django , PostgreSql
                            </p>
                            <hr>
                            <h4><a routerLink="" class="text-custom-black fw-600">Freshers (Diploma, Graduate, Post-Graduate)</a></h4>
                            <p class="text-light-white">
                                Location:- Nagpur<br>
                                Diploma in Computer Science / Diploma in Information Technology, Diploma in Electronics or equivalent, BE, B. Sc., BCA, MCA & MCM.<br>
                                <b>Internship Course</b><br>
                                Django / .Net Core Developer<br>
                                Full Stack :-  Python , Django , PostgreSql <br>
                            </p>
                        </div>
                    </div>
                </article>
                <!-- comments -->
                <div class="comment-box section-padding-top p-relative">
                    <div id="comment-box">
                        <div class="comment-respond" id="respond">
                            <h4 class="text-custom-black fw-600">For Internship Contact </h4>
                            <form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Email Us</label><br>
                                           <b class="text-custom-black fw-600 fs-14"> help@oceansofttech.net <br>
                                            yogeshk_123@yahoo.co.in
                                        </b>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="text-custom-black fw-600 fs-14">Call Us</label><br>
                                           <b class="text-custom-black fw-600 fs-14">9766916371 <br> 9423685852 <br> 07122715852</b>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- comments -->
			</div>
		</section>
        <!-- End About Us Intro -->
        <app-footer></app-footer>