<app-navbar></app-navbar>
<!-- Start Subheader -->
<div class="sub-header-I-Bank p-relative">
    <div class="overlay overlay-bg-black"></div>
    <div class="pattern"></div>
    <div class="section-padding">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="sub-header-content p-relative">
                        <h2 style="color:white">MOBILE BANKING APP SOLUTIONS</h2>
                        <h4 style="color:white">Agent App, Customer App, & Employee App</h4>
                        <h6 style="color:white">"I-Bank 4.0" Come with Agent Mobile App for Pigmy/Daily collection App, Customer can do their online transactions with Customer App.</h6>
                        <h6 style="color:white">"I-Bank 4.0" Come with Employee Mobile App for on field operations like Customer Transaction history, Acc. Overdues, Acc. Balance, Account Statement & Etc.</h6>
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Most Reliable</h6>
                                            <p class="text-custom-white">I-Bank 4.0 CBS SaaS provider with 99% uptime.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-md-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">24*7 Support</h6>
                                            <p class="text-custom-white">From friendly OceanSoftTech specialists.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3  col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Latest Techonology</h6>
                                            <p class="text-custom-white">Ionic & Maui used In App Development</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-sm-6">
                                    <div class="mb-xs-20 wow">
                                        <div class="intro-wrapper text-center"> <i class="flaticon-flag"></i>
                                            <h6 class="text-custom-white fw-700">Security & Authentication</h6>
                                            <p class="text-custom-white">Mobile Device Identification & Authentication</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="custom">
                            <li> <a routerLink="/" class="text-custom-white">Home</a>
                            </li>
                            <li class="text-custom-white active">Mobile Banking</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
 <!-- Start About Us Intro -->
<section class="section-padding our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta">
                    <h4><a routerLink="#" class="text-custom-black fw-600">Mobile First – an innovative approach</a></h4>
                    <p class="text-light-white" style="text-align: justify;">Mobile First (In 2018) is an innovative approach that OCEAN Softtech is currently using to drive its innovations forwards. The optimization of the user experience, taking the user context into account, and the standardization of the different screen formats and devices is a must today. The following figures give a good overview of how banking customers are performing their banking operations today.</p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<section class="our_articles">
    <div class="container">
        <article class="post">
            <div class="post-wrapper">
                <div class="blog-meta" style="text-align: center;">
                    <h4><a routerLink="#" class="text-custom-black fw-600">Successfully implemented Mobile App in 2017 for Agent Collection (Pigmy / RD)</a></h4>
                    <p class="text-light-white" style="text-align: justify;">The traditional way for daily collection is manual entry on paper or by using cash collection machine, this process is very not so good for society because the collection information collect by society is always depening on Agent. There my be some incidents where agent is misusing of collected cash for their personal use, some time customer & society no knowledge of such things happening in day today transaction. To over come this issue Ocean Software Technologies work on Mobile App for live cash collection in (cash / bank Transfer) Modes. By using Mobile App Cash collection is increased by 20 to 25%, and trust of customer is also gain by society. By using SMS facility and live current balance in mobile app customer now trust on agents.</p>
                </div>
            </div>
        </article>
        <!-- article -->
       </div>
</section>
<!-- End About Us Intro -->
<!-- Start Main Services -->
<section class="main-services p-relative section-padding" >
    <div class="side-lines"> <span class="box"></span>
        <span class="text">App's</span>
        <span class="line"></span>
    </div>
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h3 class="text-custom-black fw-700">A Complete Mobile Banking Software Solution</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xl-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"> <i class="bi bi-person-bounding-box"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/mobile-app" class="text-custom-black">Agent App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Agent collection App is useful for daily collection, App is directly connected to bank server so all transactions & active is done in Real-Time. Agent can see balances of accounts of there Customer.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"> <i class="bi bi-person-gear"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/mobile-app" class="text-custom-black">Customer App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Customers of banks / society / NBFC can make online transactions like RTGS/NEFT using this App. Customers also check their account balances, buy online insurance, & Etc.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-md-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"> <i class="bi bi-qr-code-scan"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/mobile-app" class="text-custom-black">App as login device</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Three different login methods are used in "I-Bank 4.0", Mobile App uses for login into software to activate the highest level of security, it uses a QR code for login.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="main-services-box p-relative mb-xs-30">
                    <div class="main-service-wrapper padding-20">
                        <div class="icon-box"><i class="bi bi-person-vcard-fill"></i>
                        </div>
                        <h5 class="fw-700"><a routerLink="/mobile-app" class="text-custom-black">Employee App</a></h5>
                        <p class="text-light-white no-margin" style="text-align: justify;">
                            Employee mobile App is use for day to day working of field offer / Employee which work in remote area. it use to create new account of deposit like RD, FD, & Daily.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Main Services -->
	<!-- About One section-->
    <section class="section-padding about-us-sec p-relative">
        <div class="side-lines right-side"> <span class="box"></span>
            <span class="text">Clients</span>
            <span class="line"></span>
        </div>
        <h3 class="text-custom-black fw-700" style="text-align:center;">Ocean Software Technologies App used In I-Bank 4.0</h3>
        <div class="container">
            <div class="row no-gutters">
                <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg-1">
                    <div class="about-left-side-1">
                        <div class="section-header">
                            <div class="section-heading">
                                <h4 class=" fw-700 text-dark">Agent Multi GL (Loan / Deposit) Collection Android App</h4>
                            </div>
                        </div>
                        <p class="text-light-white fs-14 mb-xl-20" style="text-align: justify;">
                        <b>Daily / RD Collection<br>
                           <small>Online Real-Time collection </small><br>
                           Loan Installment Collection <br>
                           <small>for fast Loan Recovery </small><br>
                           Bachat Gat Installment Collection <br>
                           <small>for fast Loan Recovery </small><br>
                           Customer Balance Checking<br>
                           <small>Real-Time Closeing Balance</small><br>
                           Today Total Collection Report<br>
                           <small>Accountwise collection list</small><br>
                           Cash collection is increased by 20 to 25%<br>
                           Cash Collection in Cash & Transfer both mode<br>
                           
                        </b>
                        </p>
                        <a routerLink="/i-bank-price" class="btn-first btn-submit-fill fw-600 mb-xl-20"><i class="fas fa-rupee-sign mr-2 fs-16"></i>Agent App Price</a>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="blog-img animate-img mb-xl-20">
                        <video controls autoplay="autoplay" height="50%" muted="muted" loop="loop">
                            <source src="assets/Video/AgtApp.mp4" type="video/mp4">
                          </video>
                    </div>
                </div>
            </div>
            <div class="container about-sec-second">
                <div class="row no-gutters">
                    <div class="col-xl-6 col-lg-6 col-md-6">
                        <div class="full-height ">
                            <img src="assets/images/QR-Code.png" class="img-fluid image-fit" alt="about">
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 about-sec-bg">
                        <div class="about-left-side-1 p-relative">
                            <div class="section-header">
                                <div class="section-heading">
                                    <h4 class=" fw-700 text-dark">QR Code - Authentication</h4>
                                </div>
                            </div>
                            <div class="about-desc">
                                <p class="text-light-white mb-xl-20" style="text-align: justify;">
                                    Operator in bank use mobile App for system login it is two-way authentication use in I-Bank 4.0<br><br>
                                    <b><i>Secure, simple and convenient authentication!</i></b>
                                </p>
                            </div>
                            <div class="company-progress">
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--About-two-section-end-->
<app-footer></app-footer>